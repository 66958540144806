import React, { useState } from "react";
import { RemoteDataTable } from "@oddadigitalsystem/datatable2";
import queryStringSerialize from "@/utils/queryStringSerialize";
import authHeaders from "@/lib/authHeaders";
import ProjectHighLight from "@/components/HighLight/ProjectHighLight";
import ItemNumberHighLight from "@/components/HighLight/ItemNumberHighLight";

import { useNavigate, useLocation } from "react-router";
import { pickBy } from "lodash";
import queryString from "query-string";
import prepareFilter from "@/lib/prepareFilter";
import searchNewSelect from "@/lib/searchNewSelect";
import FullpageTableScrollContainer from "@/components/FullpageTableScrollContainer";
import ItemsToBuyButtons from "@/pages/purchase/items-to-buy/ItemsToBuyButtons";

const ItemsToBuy = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const initialFilters = prepareFilter(queryString.parse(location.search));

  return (
    <>
      <ItemsToBuyButtons
        loading={loading}
        filters={queryString.parse(location.search)}
      />
      <FullpageTableScrollContainer>
        <RemoteDataTable
          initialFilters={initialFilters}
          size="small"
          dataFetchCallback={(params) => {
            navigate(
              `${location.pathname}?${queryStringSerialize(
                pickBy(params.filter),
              )}`,
            );
            return fetch(
              `${
                import.meta.env.VITE_VTS_API
              }/v1/purchase/items_to_buy?${queryStringSerialize(params)}`,
              {
                headers: authHeaders(),
              },
            )
              .then((r) => {
                setLoading(false);
                return r.json();
              })
              .catch((e) => setLoading(false));
          }}
          enableFiltering
          enableSorting
          requestOptions={{
            rowsPerPage: 30,
          }}
          initialColumns={[
            {
              name: "Project",
              key: "project_number",
              format: function Highlight(v) {
                return <ProjectHighLight>{v}</ProjectHighLight>;
              },
              sort: "asc",
              filterOptionsCallback: searchNewSelect("project_number"),
            },
            {
              name: "Item",
              key: "item_number",
              format: function Highlight(_i, v) {
                return (
                  <ItemNumberHighLight project_no={v.project_number}>
                    {v.item_number}
                  </ItemNumberHighLight>
                );
              },
              filterOptionsCallback: searchNewSelect("item_number"),
            },
            {
              name: "Description",
              key: "description",
              filterType: "text",
            },
            {
              name: "Due Date",
              key: "last_date",
              format: (_v, row) =>
                row.last_date
                  ? new Date(row.last_date).toLocaleDateString("no")
                  : null,
              filterType: "date",
            },
            {
              name: "Part Number",
              key: "customer_item",
              filterOptionsCallback: searchNewSelect("customer_item"),
            },
            { name: "Qty.", key: "sum_component", filterType: "number" },
            { name: "Purch.", key: "sum_purchase", filterType: "number" },
            { name: "Stock", key: "sum_stock", filterType: "number" },
          ]}
        />
      </FullpageTableScrollContainer>
    </>
  );
};

ItemsToBuy.defaultProps = {
  filters: {},
};

export default ItemsToBuy;
