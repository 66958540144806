import React, { ReactNode, useCallback } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
  Popover,
  Box,
  DialogProps,
  PopoverProps,
} from "@mui/material";
import { useState } from "react";
import FilterField from "./FilterField";
import CloseIcon from "@mui/icons-material/Close";

import {
  ColumnDefinition,
  DateSelectOptionDefinition,
  FiltersDefinition,
  RowDefinition,
  SelectOptionDefinition,
} from "@/types";

type FormValue =
  | SelectOptionDefinition
  | SelectOptionDefinition[]
  | DateSelectOptionDefinition
  | null;

interface MaterialFilterPanelProps {
  column: ColumnDefinition;
  filter: FiltersDefinition;
  onApplyFilter: React.Dispatch<FiltersDefinition>;
  onClose: React.Dispatch<void>;
  onRemoveFilter: React.Dispatch<string>;
  originalRows?: RowDefinition[];
  open: boolean;
  anchorEl: HTMLElement | null;
}

const MaterialFilterPanel = ({
  column,
  filter,
  open,
  onClose,
  onApplyFilter,
  onRemoveFilter,
  anchorEl,
  originalRows,
}: MaterialFilterPanelProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const popoverIsOpen = Boolean(anchorEl);

  const columnKey = column.key;

  const currentFilterValue = filter[columnKey];

  const [formValue, setFormValue] = useState<FormValue>(
    currentFilterValue || null,
  );

  const disabled = Array.isArray(formValue)
    ? !formValue[0]?.value?.toString()
    : !formValue?.value.toString();

  const canSubmit = columnKey && !disabled;

  const handleFieldChange = useCallback((value: FormValue) => {
    setFormValue(value);
  }, []);

  const handleSubmit = () => {
    const newFilter = { [columnKey]: formValue } as FiltersDefinition;
    canSubmit && onApplyFilter?.(newFilter);
    setFormValue(null);
    onClose?.();
  };

  const dialogProps = {
    fullScreen: true,
    onClose: () => onClose(),
    open: open,
  } as DialogProps;

  const popoverProps = {
    onClose: () => onClose(),
    open: popoverIsOpen,
    anchorEl: anchorEl,
    anchorOrigin: {
      vertical: "center",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "center",
      horizontal: "center",
    },
  } as PopoverProps;

  if (column)
    return (
      <ResponsiveDialogWrapper
        renderInDialog={isMobile}
        popoverProps={popoverProps}
        dialogProps={dialogProps}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Box display="flex">
            <DialogTitle style={{ flex: 1 }}>
              Filter by {column.name}
            </DialogTitle>

            <Box m={2}>
              <IconButton
                aria-label="close"
                onClick={() => onClose?.()}
                size="small"
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Box>

          <DialogContent sx={{ minWidth: isMobile ? undefined : 350, py: 1 }}>
            <FilterField
              column={column}
              filter={filter}
              onChange={handleFieldChange}
              originalRows={originalRows}
            />
          </DialogContent>
          <DialogActions>
            <Button
              type="button"
              color="inherit"
              onClick={() => {
                onRemoveFilter(columnKey);
                onClose();
              }}
            >
              Clear Filter
            </Button>
            <Button
              type="submit"
              onClick={() => {
                handleSubmit();
              }}
              color="primary"
              disabled={Boolean(disabled)}
            >
              Apply Filter
            </Button>
          </DialogActions>
        </form>
      </ResponsiveDialogWrapper>
    );
  return null;
};

export default MaterialFilterPanel;

const ResponsiveDialogWrapper = ({
  renderInDialog,
  dialogProps,
  popoverProps,
  children,
}: {
  renderInDialog: boolean;
  dialogProps: DialogProps;
  popoverProps: PopoverProps;
  children: ReactNode;
}) => {
  if (renderInDialog) return <Dialog {...dialogProps}> {children}</Dialog>;
  return <Popover {...popoverProps}>{children}</Popover>;
};
