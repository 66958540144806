import PropTypes from "prop-types";
import { useState } from "react";
import { Box, Button, TextField, Collapse, Typography } from "@mui/material";

import { FieldArray, useFormikContext } from "formik";

import { TransitionGroup } from "react-transition-group";
import CommentCard from "./CommentCard";

const CommentComponent = ({ name, label, disableInput }) => {
  const { values } = useFormikContext();
  const userData = JSON.parse(localStorage.getItem("msToken"));
  const currentUser = userData?.account?.name ?? "";
  const [text, setText] = useState("");

  const someCommentsAreVisible =
    values[name]?.length > 0 &&
    values[name].filter(({ _destroy }) => !_destroy)?.length > 0;

  const comments = values[name] ?? [];

  return (
    <FieldArray name={name}>
      {({ remove, unshift, replace }) => (
        <div>
          {!disableInput && (
            <Box my={2}>
              <TextField
                variant="outlined"
                label={label}
                size="small"
                fullWidth
                multiline
                minRows={4}
                maxRows={10}
                value={text}
                onChange={(e) => setText(e.target.value)}
                inputProps={{ sx: { resize: "vertical" } }}
              />
              <Box mt={1} display="flex">
                <Button
                  style={{ marginLeft: "auto" }}
                  type="button"
                  variant="outlined"
                  color="secondary"
                  disabled={!text}
                  size="small"
                  onClick={() => {
                    text &&
                      unshift({
                        text: text,
                        user: currentUser,
                        tempId: "newComment-" + new Date().toISOString(),
                      });
                    setText("");
                  }}
                >
                  Add
                </Button>
              </Box>
            </Box>
          )}
          {someCommentsAreVisible && (
            <Box mt={2} mb={1}>
              <Typography> Comments</Typography>
            </Box>
          )}
          <TransitionGroup>
            {comments?.map((comment, i) => (
              <Collapse
                key={
                  comment?.tempId
                    ? comment.tempId
                    : `${comment?.id} ${comment?._destroy}`
                }
              >
                <CommentCard
                  hidden={comment?._destroy}
                  isNew={!comment?.id}
                  user={comment.user}
                  timestamp={comment.created_at || new Date().toISOString()}
                  text={comment.text}
                  parentIsEditing={Boolean(text)}
                  onDelete={() => {
                    if (!comment.id) {
                      remove(i);
                    } else {
                      replace(i, {
                        ...comment,
                        _destroy: true,
                      });
                    }
                  }}
                  onUpdate={(updatedText) =>
                    replace(i, {
                      ...comment,
                      text: updatedText,
                    })
                  }
                />
              </Collapse>
            ))}
          </TransitionGroup>
        </div>
      )}
    </FieldArray>
  );
};

CommentComponent.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  disableInput: PropTypes.bool,
};

CommentComponent.defaultProps = {
  label: "Add comment",
};

export default CommentComponent;
