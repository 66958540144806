const initializeOneD = (data) => {
  const company = data?.one_d?.company;
  const item = data?.one_d?.item;
  const customer = data?.one_d?.customer;
  const vendor = data?.one_d?.vendor;
  return {
    company: {
      production_number: company?.production_number ?? undefined,
      operation_details: company?.operation_details ?? undefined,
      job_number: company?.job_number ?? undefined,
      job_description: company?.job_description ?? undefined,
      purchase_number: company?.purchase_number ?? undefined,
      contact: company?.contact ?? undefined,
    },
    item: {
      item_number: item?.item_number ?? undefined,
      description: item?.description ?? undefined,
      serial_number: item?.serial_number ?? undefined,
      drawing_number: item?.drawing_number ?? undefined,
      item_number_assy: item?.item_number_assy ?? undefined,
      description_assy: item?.description_assy ?? undefined,
      serial_number_assy: item?.serial_number_assy ?? undefined,
      drawing_number_assy: item?.drawing_number_assy ?? undefined,
    },
    customer: {
      customer_number: customer?.customer_number ?? undefined,
      name: customer?.name ?? undefined,
      job_purchase_number: customer?.job_purchase_number ?? undefined,
      job_name: customer?.job_name ?? undefined,
      customer_item_number: customer?.customer_item_number ?? undefined,
      customer_item_number_assy:
        customer?.customer_item_number_assy ?? undefined,
      job_contact: customer?.job_contact ?? undefined,
      job_contact_position: customer?.job_contact_position ?? undefined,
      reference: customer?.reference ?? undefined,
    },
    vendor: {
      vendor_number: vendor?.vendor_number ?? undefined,
      vendor_name: vendor?.vendor_name ?? undefined,
    },
  };
};

const initializeTwoD = (data) => {
  const two_d = data?.two_d;
  return {
    machine_number: two_d?.machine_number ?? undefined,
    specification: two_d?.specification ?? undefined,
    wps_number: two_d?.wps_number ?? undefined,
    tolerance: two_d?.tolerance ?? undefined,
    measured_value: two_d?.measured_value ?? undefined,
    severity: two_d?.severity ?? undefined,
    temporary_action_required: two_d?.temporary_action_required ?? undefined,
    event_reason: two_d?.event_reason ?? undefined,
    event_reason2: two_d?.event_reason2 ?? undefined,
    event_reason3: two_d?.event_reason3 ?? undefined,
    event_location: two_d?.event_location ?? undefined,
  };
};

const initialValues = (data) => {
  const userData = JSON.parse(localStorage.getItem("msToken"));
  const currentUser = userData?.account?.name ?? "";
  return {
    id: data?.id ?? undefined,
    title: data?.title ?? undefined,
    ncr_type: data?.ncr_type ?? "hms",
    level: data?.level ?? "registration",
    registered_by: data?.registered_by ?? currentUser,
    owner: data?.owner ?? "",
    comments: data?.comments ?? undefined,
    attachments: data?.attachments ?? [],
    delete_attachments: [],
    one_d: initializeOneD(data),
    two_d: initializeTwoD(data),
  };
};

export default initialValues;
