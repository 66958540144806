import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Fade,
} from "@mui/material";
import { Skeleton } from "@mui/material";

const CellValue = ({ loading, value }) =>
  loading ? (
    <Box mx={4}>
      <Skeleton />
    </Box>
  ) : (
    <Fade in timeout={500}>
      <span>{`${value} %`}</span>
    </Fade>
  );

export const WeldNdtScope = ({ ndtScope, loading }) => {
  const header = ["vt", "pmi", "mt", "ut", "pt", "rt"];

  return (
    <TableContainer component={Paper} variant="outlined">
      <Table aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            {header?.map((key) => (
              <TableCell key={key} align="center">
                {key.toUpperCase()}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {header?.map((key) => {
              const value = ndtScope ? ndtScope[key] : "";
              return (
                <TableCell
                  key={key + "-value"}
                  align="center"
                  style={{ borderBottom: 0 }}
                >
                  <CellValue value={value} loading={loading} />
                </TableCell>
              );
            })}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
