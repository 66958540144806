import { TableCell, TableRow, TableRowProps } from "@mui/material";
import React, { useCallback, useEffect, useRef } from "react";

interface RefreshAtEndProps extends TableRowProps {
  onVisible: React.Dispatch<void>;
  rootElem: HTMLElement;
}

function RefreshAtEnd({ rootElem, onVisible, ...props }: RefreshAtEndProps) {
  const rowRef = useRef(null);

  const interSectionCallback = useCallback(
    (entries: { isIntersecting: any }[], _o: unknown) => {
      if (entries && entries[0].isIntersecting) {
        onVisible();
      }
    },
    [onVisible],
  );

  useEffect(() => {
    const options = { root: rootElem, rootMargin: "0px", threshold: 0.1 };
    if (!rowRef.current) return;
    new IntersectionObserver(interSectionCallback, options).observe(
      rowRef.current,
    );
    return;
  }, [interSectionCallback, rootElem]);

  return (
    <TableRow ref={rowRef} {...props}>
      <TableCell colSpan={99} align="center">
        Loading more data
      </TableCell>
    </TableRow>
  );
}

RefreshAtEnd.defaultProps = {
  onVisible: () => null,
  rootElem: null,
};

export default RefreshAtEnd;
