import React from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import HighLight from "./HighLight";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
const PurchaseHighLight = (props) => <HighLight {...props} />;

PurchaseHighLight.defaultProps = {
  choices: [
    {
      name: "Purchase Order",
      url: "/purchase/orders",
      parameter: "order",
      icon: <ShoppingCartIcon />,
    },
    {
      name: "Documents",
      url: "/documents/documents",
      parameter: "purchase_order",
      icon: <DescriptionIcon />,
    },
  ],
};

export default PurchaseHighLight;
