import { useLocation } from "react-router";
import { FunctionComponent, useEffect, useState } from "react";
import authHeaders from "@/lib/authHeaders";
import Skeleton from "@mui/material/Skeleton";
// @ts-ignore
import queryStringSerialize from "@/utils/queryStringSerialize";
import { useSnackbar } from "notistack";
import QRReceiptLabel from "@/pages/purchase/create-receival-orders/QRReceiptLabel";

interface WarehouseReceipt {
  id: number | string;
  prod_order_number: string;
  operation_number: string;
  project: {
    number: string;
    description: string;
    task_number: string;
  };
  item: {
    number: string;
    description: string;
    lot_number: string;
    serial_number: string;
    customer_item_number: string;
    customer_drawing_number: string;
  };
  purchase: {
    order_number: string;
    line_number: string;
    quantity: string;
  };
  error: string;
}

const genErrString = (receipts: WarehouseReceipt[]): string | undefined => {
  const messages = receipts
    .filter((e: WarehouseReceipt) => Boolean(e.error))
    .reduce((acc: any, receipt: WarehouseReceipt) => {
      if (receipt.error in acc) acc[receipt.error].push(receipt.id);
      else acc[receipt.error] = [receipt.id];
      return acc;
    }, {});
  return Object.keys(messages).length > 0
    ? Object.keys(messages).reduce(
        (errorMessage: string, message: string) =>
          `${errorMessage} ${message} ids: ${messages[message].join(" ")}.`,
        "",
      )
    : undefined;
};

const PrintableReceiptLabel: FunctionComponent = () => {
  const { search } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [warehouseReceipts, setWarehouseReceipts] =
    useState<WarehouseReceipt[]>();

  useEffect(() => {
    const ids = new URLSearchParams(search).getAll("id");

    fetch(
      `${
        import.meta.env.VITE_VTS_API
      }/v1/warehouse_receipts/generate_qrcode?${queryStringSerialize(
        ids,
        "id",
      )}`,
      {
        headers: authHeaders(),
      },
    )
      .then((r) => r.json())
      .then(({ rows }) => {
        const error = genErrString(rows);
        if (error) enqueueSnackbar(error, { variant: "error" });
        setWarehouseReceipts(
          rows.filter((e: WarehouseReceipt) => !e.error) as WarehouseReceipt[],
        );
      });
  }, [enqueueSnackbar, search]);

  return (
    <>
      {warehouseReceipts ? (
        warehouseReceipts.map((warehouseReceipt) => (
          <QRReceiptLabel
            itemNo={warehouseReceipt.item?.number}
            itemDesc={warehouseReceipt.item?.description || ""}
            customerItemNumber={
              warehouseReceipt?.item.customer_item_number || ""
            }
            customerDrawingNumber={
              warehouseReceipt?.item.customer_drawing_number
            }
            projectNumber={warehouseReceipt.project.number}
            projectDescription={warehouseReceipt.project.description}
            projectTaskNumber={warehouseReceipt.project.task_number}
            operationNumber={warehouseReceipt.operation_number}
            purchaseOrder={warehouseReceipt.purchase.order_number}
            purchaseLine={warehouseReceipt.purchase.line_number}
            quantity={warehouseReceipt.purchase.quantity}
            serialNumber={warehouseReceipt.item.serial_number}
            lotNumber={warehouseReceipt.item.lot_number}
            url={
              `${window.location.origin}/items/lessons-learned?item_number=${warehouseReceipt.item.number}` ||
              ""
            }
          />
        ))
      ) : (
        <Skeleton variant="rectangular" width={"4in"} height={"2.5in"} />
      )}
    </>
  );
};

export default PrintableReceiptLabel;
