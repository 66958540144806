import authHeaders from "@/lib/authHeaders";
import queryStringSerialize from "@/utils/queryStringSerialize";
import ApiWeekColumns from "@/models/production/queue-list/ApiWeekColumns";

const loadWeekColumns = async (filters: any): Promise<ApiWeekColumns> => {
  return fetch(
    `${
      import.meta.env.VITE_VTS_API
    }/v1/production/queue_list/week_columns?${queryStringSerialize(filters)}`,
    {
      headers: authHeaders(),
    },
  ).then((response) => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error(`Error ${response.status}. Failed to load the filters.`);
    }
  });
};

export default loadWeekColumns;
