import { useCallback, useEffect } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import routes from "@/config/routes";
import footerRoutes from "@/config/footerRoutes";

import NotFound from "@/components/NotFound";
import RailsVTSiframe from "@/components/RailsVTSiframe";
import PageLayout from "@/components/PageLayout";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { onChangeTitle } from "@/redux/features/app-common-slice";
import GlobalLoading from "@/pages/main/GlobalLoading";
import Navigation from "@/pages/main/Navigation";
import EnvironmentMessage from "@/pages/main/EnvironmentMessage";

const currentRoute = (appRoutes, location) =>
  appRoutes.find((route) => route.path === location.pathname);

const App = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const title = useAppSelector((state) => state["appCommon"].title);
  const appRoutes = routes();
  const footerMenu = footerRoutes();

  const currentTitle = currentRoute(appRoutes, location)?.title || "";
  const currentPath = location.pathname;

  const updateTitle = useCallback(() => {
    if (currentTitle) {
      dispatch(onChangeTitle(currentTitle));
      document.title = `${currentTitle} - Visual Tracking`;
    }
  }, [currentTitle, dispatch]);

  useEffect(() => {
    updateTitle();
  }, [updateTitle]);

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <>
      <Navigation
        onNavigate={handleNavigate}
        currentPath={currentPath}
        routes={appRoutes}
        footerRoutes={footerMenu}
        title={title}
        buttons={currentRoute(appRoutes, location)?.buttons || null}
      />
      <Routes>
        {appRoutes.map((page, i) => {
          if (!page?.path) return null;
          if (typeof page?.component === "function")
            return (
              <Route
                key={i + "app"}
                exact
                path={`${page?.path}`}
                element={
                  <PageLayout
                    fullPage={page?.fullPage}
                    componentType={typeof page?.component}
                    maxWidth={page?.maxWidth}
                  >
                    <>
                      <GlobalLoading />
                      <page.component />
                    </>
                  </PageLayout>
                }
              />
            );
          if (typeof page?.component === "string")
            return (
              <Route
                exact
                path={`${page?.path}`}
                element={
                  <RailsVTSiframe
                    url={page?.component}
                    title={page?.title}
                    key={page?.path + i}
                  />
                }
              />
            );
          else return null;
        })}
        <Route path="*" element={<NotFound />} />
        <Route exact path="/" element={<Navigate to="/home" replace />} />
      </Routes>
      <EnvironmentMessage />
    </>
  );
};

export default App;
