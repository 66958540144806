import PropTypes from "prop-types";
import { Fade, Box, ListItemText } from "@mui/material";

const ConditionalMessage = ({
  primary,
  secondary,
  condition,
  children,
  ...props
}) => {
  if (condition) {
    return (
      <Fade in>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "70vh",
          }}
        >
          <ListItemText
            style={{ textAlign: "center" }}
            primary={primary}
            secondary={secondary}
            {...props}
          />
        </Box>
      </Fade>
    );
  } else return children;
};

ConditionalMessage.propTypes = {
  children: PropTypes.node,
  condition: PropTypes.bool,
  primary: PropTypes.string,
  secondary: PropTypes.string,
};

export default ConditionalMessage;
