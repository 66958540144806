import { useState } from "react";
import { RemoteDataTable } from "@oddadigitalsystem/datatable2";
import queryStringSerialize from "@/utils/queryStringSerialize";
import { Box, Fab } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import { useNavigate, useLocation } from "react-router";
import queryString from "query-string";
import searchNewSelect from "@/lib/searchNewSelect";

import { pickBy } from "lodash";
import ProjectHighLight from "@/components/HighLight/ProjectHighLight";
import prepareFilter from "@/lib/prepareFilter";
import authHeaders from "@/lib/authHeaders";
import FullpageTableScrollContainer from "@/components/FullpageTableScrollContainer";
import ImageButtonBadge from "@/components/ImageButtonBadge";
import { useTheme } from "@mui/styles";
import DownloadFileButton from "@/components/DownloadFileButton";

import { useSnackbar } from "notistack";
import CommentsForm from "@/pages/project/comments/form/CommentsForm";

const generateKey = () => "commentTable-" + new Date().getTime();

const url = `${import.meta.env.VITE_VTS_API}/v1/comments`;

const DateDisplay = ({ date }) => {
  return (
    <>
      <div>{new Date(date).toLocaleDateString("nb")}</div>
      <div>{new Date(date).toLocaleTimeString("nb")}</div>
    </>
  );
};

const Comments = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  let location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const initialFilters = prepareFilter(queryString.parse(location.search));
  const [currentTableParams, setCurrentTableParams] = useState();
  const [formOpen, setFormOpen] = useState(false);
  const [formData, setFormData] = useState(null);
  const [tableKey, reloadTable] = useState("commentTable");

  const handleReloadTable = () => reloadTable(generateKey());

  const handleCloseForm = () => {
    setFormOpen(false);
    setFormData(null);
  };

  const handleCreateComment = (form) => {
    return fetch(`${import.meta.env.VITE_VTS_API}/v1/comments`, {
      method: "POST",
      headers: authHeaders({ defaultContentType: true }),
      body: form,
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          handleCloseForm();
          handleReloadTable();
          return response.json();
        }
      })
      .catch((error) => {
        error.json().then((body) => {
          enqueueSnackbar(`${body.status}: ${body.message}`, {
            variant: "error",
          });
        });
      });
  };

  const handleUpdateComment = (body, id) => {
    return fetch(`${import.meta.env.VITE_VTS_API}/v1/comments/${id}`, {
      method: "PUT",
      headers: authHeaders({ defaultContentType: true }),
      body: body,
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          handleCloseForm();
          handleReloadTable();
          return response.json();
        }
      })
      .catch((error) => {
        error.json().then((body) => {
          enqueueSnackbar(`${body.status}: ${body.message}`, {
            variant: "error",
          });
        });
      });
  };

  const handleDelete = (id) => {
    fetch(`${import.meta.env.VITE_VTS_API}/v1/comments/${id}`, {
      method: "DELETE",
      headers: authHeaders(),
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        } else {
          handleCloseForm();
          handleReloadTable();
          return response.json();
        }
      })
      .catch((error) => {
        error.json().then((body) => {
          enqueueSnackbar(`${body.status}: ${body?.message}`, {
            variant: "error",
          });
        });
      });
  };

  const handleSubmit = (form) => {
    const body = new FormData();
    body.append("created_at", form.createdAt);
    body.append("source", form.source);
    body.append("comment", form.comment);
    body.append("project_number", form.projectNumber.value);
    for (let i = 0; i < form.uploadedImages.length; i++) {
      body.append("images[]", form.uploadedImages[i]);
    }

    if (form.id) {
      handleUpdateComment(body, form.id);
    } else {
      handleCreateComment(body);
    }
  };

  const handleOpenExistingForm = (rowData) => {
    setFormData({
      id: rowData.id,
      user: rowData.user,
      source: rowData.source,
      createdAt: rowData.created_at.props.date,
      projectNumber: rowData.project_number.props.children,
      comment: rowData.comment,
      images: rowData.images?.props?.images,
      reference: rowData?.reference || "",
      reference2: rowData?.reference2 || "",
    });
    setFormOpen(true);
  };

  const handleLoadComments = (params) => {
    setCurrentTableParams(params);
    navigate(
      `${location.pathname}?${queryStringSerialize(pickBy(params.filter))}`,
    );
    return fetch(
      `${url}?${queryStringSerialize(params)}
  `,
      {
        headers: authHeaders(),
      },
    )
      .then((r) => {
        return r.json();
      })
      .catch((e) => e);
  };

  return (
    <>
      <FullpageTableScrollContainer>
        <RemoteDataTable
          key={tableKey}
          initialFilters={initialFilters}
          onRowClick={handleOpenExistingForm}
          dataFetchCallback={handleLoadComments}
          initialColumns={[
            {
              name: "#",
              key: "id",
              disableFilter: true,
            },
            {
              name: "User",
              key: "user",
              filterOptionsCallback: searchNewSelect("user"),
            },
            {
              name: "Date",
              key: "created_at",
              format: (v) => <DateDisplay date={v} />,
              sort: "desc",
              filterType: "date",
            },
            {
              name: "Project",
              key: "project_number",
              filterOptionsCallback: searchNewSelect("project_number"),
              format: function Highlight(v) {
                return <ProjectHighLight>{v}</ProjectHighLight>;
              },
            },
            {
              name: "Reference",
              key: "reference",
              filterOptionsCallback: searchNewSelect("comment_reference"),
            },
            {
              name: "Reference 2",
              key: "reference2",
              filterOptionsCallback: searchNewSelect("comment_reference2"),
            },
            {
              name: "Source",
              key: "source",
              filterOptionsCallback: () =>
                Promise.resolve({
                  results: [
                    { value: "internal", text: "Internal" },
                    { value: "external", text: "External" },
                  ],
                }),
            },
            {
              name: "Comment",
              key: "comment",
              cellStyle: { whiteSpace: "pre-line" },
              filterType: "text",
            },
            {
              name: "Images",
              key: "images",
              filterOptionsCallback: () =>
                Promise.resolve({
                  results: [{ value: "1", text: "With Images" }],
                }),
              format: function Highlight(v) {
                return (
                  <ImageButtonBadge
                    images={v.map((e) => ({ src: e.src, name: e.name }))}
                  />
                );
              },
            },
          ]}
          enableSorting
          enableFiltering
          enableSearch
          requestOptions={{
            rowsPerPage: 30,
          }}
          extraToolbarComponent={() => {
            return (
              <Box ml={1}>
                <DownloadFileButton
                  urlEndpoint={url}
                  filename="Comments"
                  fileExtension="csv"
                  params={{ ...currentTableParams, limit: 500, page: 1 }}
                  loading={!currentTableParams}
                />
              </Box>
            );
          }}
          size="small"
        />
      </FullpageTableScrollContainer>
      <Fab
        onClick={() => setFormOpen(!formOpen)}
        color="secondary"
        style={{
          position: "fixed",
          bottom: theme.spacing(2),
          right: theme.spacing(2),
          zIndex: 999,
        }}
      >
        <AddIcon />
      </Fab>
      <CommentsForm
        key={formData?.id || generateKey()}
        open={formOpen}
        onClose={handleCloseForm}
        data={formData}
        onSubmit={(form) => handleSubmit(form)}
        onDelete={(id) => handleDelete(id)}
      />
    </>
  );
};

export default Comments;
