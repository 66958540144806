export const productionDepartments = [
  {
    id: 0,
    text: "QC",
    pattern: "QC",
    workCenter: [],
    username: "hj@oddatechnology.no",
  },
  {
    id: 1,
    text: "NDE",
    pattern: "NDE",
    workCenter: [],
    username: "kevinb@oddatechnology.no",
  },
  {
    id: 2,
    text: "Machining",
    pattern: "MA",
    workCenter: [],
    username: "jmm@oddatechnology.no",
  },
  {
    id: 3,
    text: "Coating",
    pattern: "COA",
    workCenter: [],
    username: "jl@oddatechnology.no",
  },
  {
    id: 4,
    text: "Welding",
    pattern: "WE",
    workCenter: [],
    username: "kb@oddatechnology.no",
  },
  {
    id: 5,
    text: "Assembly / Testing",
    pattern: "AS",
    workCenter: [],
    username: "lk@oddatechnology.no",
  },
  {
    id: 6,
    text: "Warehouse",
    pattern: "WH",
    workCenter: [],
    username: "tg@oddatechnology.no",
  },
];
