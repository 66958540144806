import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
  Card,
  Grow,
  Container,
} from "@mui/material";

import vtsLogo from "@/images/vts.png";

const ErrorPage = ({ logo = vtsLogo, onClick }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80vh",
      }}
    >
      <Grow in>
        <Container maxWidth="md">
          <Card variant="outlined" sx={{ pt: 4 }}>
            <img
              width={180}
              style={{ display: "block", margin: "0 auto" }}
              alt="Visual Tracking System"
              src={logo}
            />
            <DialogTitle>Sorry for the bug...</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Something unforeseen happened. The issue was reported
                automatically and we are working on a fix.
              </DialogContentText>
              <DialogContentText>
                Meanwhile, click the button below to clear cache and reload the
                page. This usually fixes the problem.
              </DialogContentText>
              <DialogContentText>
                If the problem persists, please leave a detailed description in
                the feedback portal of what you did before the issue appeared,
                so we can resolve the issue faster.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={onClick} size="large">
                Reload
              </Button>
            </DialogActions>
          </Card>
        </Container>
      </Grow>
    </div>
  );
};

export default ErrorPage;
