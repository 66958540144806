export const levelOptions = (type) => {
  switch (type) {
    case "complaint":
      return ["action", "critical"];
    case "deviation_permit":
      return ["registration"];
    case "technical_clarification":
      return ["registration"];
    default:
      return ["registration", "action", "critical"];
  }
};
