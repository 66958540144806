import PropTypes from "prop-types";
import React from "react";
import {
  Container,
  Grid,
  TextField,
  Grow,
  FormHelperText,
} from "@mui/material";
import { Formik, Form, getIn, FieldArray } from "formik";
import * as Yup from "yup";

import withStyles from "@mui/styles/withStyles";

import {
  Timeline,
  TimelineItem as MuiTimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent as MuiTimelineContent,
  TimelineDot,
} from "@mui/lab";

import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

const TimelineItem = withStyles({
  missingOppositeContent: {
    "&:before": {
      padding: 0,
      margin: "auto 0",
      content: "none",
    },
  },
})(MuiTimelineItem);

const TimelineContent = withStyles((theme) => ({
  root: {
    flex: 10,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
  },
}))(MuiTimelineContent);

Yup.addMethod(Yup.object, "unique", function (propertyName, message) {
  return this.test("unique", message, function (value) {
    if (!value || !value[propertyName]) {
      return true;
    }
    const { path } = this;
    const options = [...this.parent];
    const currentIndex = options.indexOf(value);
    const subOptions = options.slice(0, currentIndex);
    if (
      subOptions.some((option) => option[propertyName] === value[propertyName])
    ) {
      throw this.createError({
        path: `${path}.${propertyName}`,
        message,
      });
    }
    return true;
  });
});

Yup.addMethod(
  Yup.object,
  "existingSerial",
  function (propertyName, message, array) {
    return this.test("existingSerial", message, function (value) {
      if (!value || !value[propertyName]) {
        return true;
      }
      const { path } = this;
      if (array.includes(value[propertyName])) {
        throw this.createError({
          path: `${path}.${propertyName}`,
          message,
        });
      }
      return true;
    });
  },
);

Yup.addMethod(Yup.array, "totalQuantity", function (message, maxSize) {
  return this.test("totalQuantity", message + " " + maxSize, function (values) {
    const { path } = this;
    if (
      values.map(({ quantity }) => quantity).reduce((a, b) => a + b, 0) >
      maxSize
    ) {
      throw this.createError({
        path: `${path}.totalQuantity`,
        message,
      });
    }
    return true;
  });
});

const extraValidation = {
  purchase_order: Yup.string().required("Failed to load purchase order number"),
  purchase_line: Yup.string().required("Failed to load purchase line number"),
  item: Yup.string().required("Failed to load purchase item number"),
};

const serialNumberValidation = (quantity, serialNumbers) =>
  Yup.object().shape({
    receipt: Yup.array()
      .of(
        Yup.object()
          .shape({
            serial_number: Yup.string().required("Serial number is required"),
            quantity: Yup.number()
              .min(0.000001, "Quantity can not be less than 0.000001")
              .max(1, "Quantity for serial numbers can not be greater than 1")
              .required("Quantity is required"),
            ...extraValidation,
          })
          .unique("serial_number", "Serial numbers must be unique")
          .existingSerial(
            "serial_number",
            "This serial number already exists",
            serialNumbers,
          ),
      )
      .totalQuantity(
        `Sum of quantities can not be greater than ${quantity}`,
        quantity,
      ),
  });

const lotNumberValidation = (quantity) =>
  Yup.object().shape({
    receipt: Yup.array()
      .of(
        Yup.object().shape({
          lot_number: Yup.string().required("Lot number is required"),
          quantity: Yup.number()
            .min(0.000001, "Quantity can not be less than 0.000001")
            .required("Quantity is required"),
          ...extraValidation,
        }),
      )
      .totalQuantity(
        `Sum of quantities can not be greater than ${quantity}`,
        quantity,
      ),
  });

const ItemReceivalForm = ({
  data,
  trackingData,
  onSubmit,
  debug,
  textFieldProps,
}) => {
  const trackingKey = trackingData?.tracking;

  const serialNumbers =
    trackingKey === "serial_number" ? trackingData.tracking_numbers : [] || [];

  const existingQuantity = data?.outstanding_quantity;

  return (
    <Formik
      initialValues={{
        receipt: [
          {
            [trackingKey]: "",
            quantity: 1,
            purchase_order: data?.document_no.toString() || "",
            purchase_line: data?.line_no.toString() || "",
            item: data?.item_no.toString() || "",
          },
        ],
      }}
      validationSchema={
        trackingKey === "serial_number"
          ? serialNumberValidation(existingQuantity, serialNumbers)
          : trackingKey === "lot_number"
            ? lotNumberValidation(existingQuantity)
            : null
      }
      onSubmit={(values, { setSubmitting }) => {
        onSubmit && onSubmit(values);
        setSubmitting(false);
      }}
    >
      {({ values, touched, errors, handleChange, handleBlur }) => (
        <Container>
          <Form id="item-receival-form" noValidate>
            <Timeline align="left" style={{ padding: 0 }}>
              <FieldArray name="receipt">
                {({ push, remove }) => (
                  <>
                    {values.receipt.map((p, index) => {
                      const trackingNumber = `receipt[${index}][${trackingKey}]`;
                      const touchedTrackingNumber = getIn(
                        touched,
                        trackingNumber,
                      );
                      const errorTrackingNumber = getIn(errors, trackingNumber);

                      const quantity = `receipt[${index}].quantity`;
                      const touchedQuantity = getIn(touched, quantity);
                      const errorQuantity = getIn(errors, quantity);
                      const errorTotalQuantity = errors?.receipt?.totalQuantity;

                      const errorPurchaseLine = getIn(
                        errors,
                        `receipt[${index}].purchase_line`,
                      );

                      const errorPurchaseOrder = getIn(
                        errors,
                        `receipt[${index}].purchase_order`,
                      );

                      const errorItemNumber = getIn(
                        errors,
                        `receipt[${index}].item`,
                      );

                      if (
                        errorPurchaseLine ||
                        errorPurchaseOrder ||
                        errorItemNumber
                      )
                        return (
                          <>
                            <FormHelperText error>
                              {errorPurchaseLine}
                            </FormHelperText>
                            <FormHelperText error>
                              {errorPurchaseOrder}
                            </FormHelperText>
                            <FormHelperText error>
                              {errorItemNumber}
                            </FormHelperText>
                            <FormHelperText>
                              Please reload and try again
                            </FormHelperText>
                          </>
                        );

                      return (
                        <div key={"item-" + index}>
                          <TimelineItem>
                            <TimelineSeparator>
                              <TimelineConnector
                                style={{ opacity: index === 0 ? 0 : 100 }}
                              />
                              <Grow in timeout={500}>
                                <TimelineDot
                                  color="secondary"
                                  style={
                                    values.receipt?.length > 1
                                      ? {
                                          boxShadow: "none",
                                          backgroundColor: "transparent",
                                        }
                                      : {}
                                  }
                                >
                                  {values?.receipt?.length === 1 ? (
                                    <AddIcon
                                      onClick={() =>
                                        push({
                                          [trackingKey]: "",
                                          quantity: "",
                                          purchase_order: data.document_no,
                                          purchase_line: data.line_no,
                                          item: data.item_no,
                                        })
                                      }
                                    />
                                  ) : (
                                    <DeleteIcon
                                      color="action"
                                      onClick={() => remove(index)}
                                    />
                                  )}
                                </TimelineDot>
                              </Grow>

                              <TimelineConnector
                                style={{
                                  opacity:
                                    index === 0 && values.receipt.length === 1
                                      ? 0
                                      : 100,
                                }}
                              />
                            </TimelineSeparator>

                            <Grow in>
                              <TimelineContent>
                                <Grid container spacing={2}>
                                  <Grid item xs={12} lg={6}>
                                    <TextField
                                      fullWidth
                                      variant="outlined"
                                      label={
                                        (trackingKey === "serial_number" &&
                                          "Serial number") ||
                                        (trackingKey === "lot_number" &&
                                          "Lot number")
                                      }
                                      {...textFieldProps}
                                      name={trackingNumber}
                                      value={p[trackingKey]}
                                      required
                                      helperText={
                                        touchedTrackingNumber &&
                                        errorTrackingNumber
                                          ? errorTrackingNumber
                                          : ""
                                      }
                                      error={Boolean(
                                        touchedTrackingNumber &&
                                          errorTrackingNumber,
                                      )}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </Grid>
                                  <Grid item xs={12} lg={6}>
                                    <TextField
                                      fullWidth
                                      variant="outlined"
                                      label="Quantity"
                                      {...textFieldProps}
                                      name={quantity}
                                      value={p.quantity}
                                      type="number"
                                      required
                                      helperText={
                                        touchedQuantity && errorQuantity
                                          ? errorQuantity
                                          : errorTotalQuantity
                                            ? errorTotalQuantity
                                            : ""
                                      }
                                      error={
                                        Boolean(
                                          touchedQuantity && errorQuantity,
                                        ) || Boolean(errorTotalQuantity)
                                      }
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />
                                  </Grid>
                                </Grid>
                              </TimelineContent>
                            </Grow>
                          </TimelineItem>

                          {index > 0 && index === values.receipt.length - 1 && (
                            <TimelineItem>
                              <TimelineSeparator>
                                <TimelineConnector />
                                <Grow in timeout={500}>
                                  <TimelineDot color="secondary">
                                    <AddIcon
                                      onClick={() =>
                                        push({
                                          [trackingKey]: "",
                                          quantity: "",
                                          purchase_order: data.document_no,
                                          purchase_line: data.line_no,
                                          item: data.item_no,
                                        })
                                      }
                                    />
                                  </TimelineDot>
                                </Grow>
                              </TimelineSeparator>
                            </TimelineItem>
                          )}
                        </div>
                      );
                    })}
                  </>
                )}
              </FieldArray>
            </Timeline>
            {debug && (
              <>
                <pre style={{ textAlign: "left" }}>
                  <strong>Values</strong>
                  <br />
                  {JSON.stringify(values, null, 2)}
                </pre>
                <pre style={{ textAlign: "left" }}>
                  <strong>Errors</strong>
                  <br />
                  {JSON.stringify(errors, null, 2)}
                </pre>
                <pre>
                  <strong>Tracking</strong>
                  <code>{JSON.stringify(trackingData, null, "\t")}</code>
                </pre>
                <pre>
                  <strong>Data</strong>
                  <code>{JSON.stringify(data, null, "\t")}</code>
                </pre>
              </>
            )}
          </Form>
        </Container>
      )}
    </Formik>
  );
};

ItemReceivalForm.propTypes = {
  data: PropTypes.shape({
    // job_no: PropTypes.string,
    document_no: PropTypes.string.isRequired,
    line_no: PropTypes.number.isRequired,
    item_no: PropTypes.string.isRequired,
    // customer_item: PropTypes.string,
    // description: PropTypes.string,
    // vendor_name: PropTypes.string,
    // outstanding_quantity: PropTypes.string,
    // purchaser_code: PropTypes.string,
    // requested_receipt_date: PropTypes.string,
    // promised_receipt_date: PropTypes.string,
  }),
  /**
   * Any other props will be sent to MUI `TextField`
   */
  debug: PropTypes.bool,
  onSubmit: PropTypes.func,
  /**
   * Any other props will be sent to MUI `TextField`
   * Can be used to change size / variant etc
   */
  textFieldProps: PropTypes.object,
  trackingData: PropTypes.shape({
    // item_number: PropTypes.string,
    // description: PropTypes.string,
    tracking: PropTypes.oneOf(["serial_number", "lot_number"]).isRequired,
    tracking_numbers: PropTypes.arrayOf(PropTypes.string),
    // customer_item_number: PropTypes.string,
    // customer_drawing_number: PropTypes.string,
  }),
};

export default ItemReceivalForm;
