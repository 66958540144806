import {
  TableCell,
  TableCellProps,
  IconButton,
  Badge,
  Tooltip,
  Box,
  TableSortLabel,
} from "@mui/material";
import FilterIcon from "@mui/icons-material/FilterList";
import React, { useState } from "react";
import {
  ColumnDefinition,
  ToggleSortCallback,
  ToggleFilterCallback,
} from "@/types";

const checkFilterAndSort = (
  isDisabled: boolean | undefined,
  array: string[],
  key: string,
) => {
  if (isDisabled) return false;
  if (array?.length > 0) {
    return array.includes(key);
  }
  return true;
};

interface MaterialTableHeaderCellProps {
  column: ColumnDefinition;
  filterApplied: boolean;
  onToggleSort?: ToggleSortCallback;
  onOpenFilterDialog?: ToggleFilterCallback;
  sortDirection: "asc" | "desc" | "";
  allowedSortKeys?: string[];
  allowedFilterKeys?: string[];
}

function MaterialTableHeaderCell({
  column,
  filterApplied,
  onToggleSort,
  onOpenFilterDialog,
  sortDirection,
  allowedSortKeys = [],
  allowedFilterKeys = [],
}: MaterialTableHeaderCellProps) {
  const { renderTooltipFn, disableSort, name, disableFilter, key } = column;
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const isSortable = checkFilterAndSort(disableSort, allowedSortKeys, key);
  const isFilterable = checkFilterAndSort(
    disableFilter,
    allowedFilterKeys,
    key,
  );

  const handleSort = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isSortable) onToggleSort?.(e, column);
  };

  const tooltip = () => {
    if (renderTooltipFn) return renderTooltipFn(sortDirection, column);
    if (!isSortable) {
      return "";
    } else {
      if (isSortable && sortDirection)
        return `Sorted by: ${sortDirection?.toUpperCase()}`;
      if (isSortable && !sortDirection) return `Click to sort`;
    }
    return "";
  };

  const cellProps: TableCellProps = column?.headCellPropsFn?.(column) ?? {};
  const displayValue = column?.renderHeaderCellFn?.(column) ?? name;

  const inheritToggleIconColorFromCell = () => {
    if (Object.values(cellProps).length)
      return {
        ml: 0.5,
        "&.MuiTableSortLabel-root": {
          color: "inherit",
        },
        "&.MuiTableSortLabel-root:hover": {
          color: "inherit",
        },
        "&.Mui-active": {
          color: "inherit",
        },
        "& .MuiTableSortLabel-icon": {
          color: "inherit !important",
        },
      };
    return {};
  };

  return (
    <TableCell
      {...cellProps}
      onMouseEnter={() => setShowFilter(true)}
      onMouseLeave={() => setShowFilter(false)}
    >
      <Box
        onClick={handleSort}
        sx={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          userSelect: "none",
        }}
      >
        <Tooltip title={tooltip?.() ?? ""}>
          <div style={{ display: "flex", width: "100%", flex: 0 }}>
            <div
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {displayValue}
            </div>
            {isSortable && sortDirection && (
              <TableSortLabel
                active={["asc", "desc"].includes(sortDirection ?? "")}
                direction={sortDirection || undefined}
                onClick={handleSort}
                sx={{
                  ...inheritToggleIconColorFromCell(),
                }}
              />
            )}
          </div>
        </Tooltip>

        {isFilterable && (
          <Box
            sx={{
              opacity: showFilter || filterApplied ? 1 : 0,
              transition: "opacity 0.3s",
              "&:hover": {
                opacity: 1,
              },
            }}
          >
            <Badge color="primary" variant="dot" invisible={!filterApplied}>
              <IconButton
                color={Object.values(cellProps).length ? "inherit" : "default"}
                aria-label="filter"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onOpenFilterDialog?.(e, column);
                }}
              >
                <FilterIcon fontSize="inherit" />
              </IconButton>
            </Badge>
          </Box>
        )}
      </Box>
    </TableCell>
  );
}

export default MaterialTableHeaderCell;
