import { useLayoutEffect, useState } from "react";
import { Paper } from "@mui/material";

const FullpageTableScrollContainer = ({ children }) => {
  const [offset, setOffset] = useState(64);

  useLayoutEffect(() => {
    setOffset(document?.getElementById("app-bar")?.clientHeight);
  }, []);

  return (
    <Paper
      style={{
        maxHeight: `calc(100vh - ${offset}px)`,
        minHeight: `calc(100vh - ${offset}px)`,
        overflow: "auto",
      }}
    >
      {children}
    </Paper>
  );
};

export default FullpageTableScrollContainer;
