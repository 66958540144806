import React, { useState } from "react";
import PropTypes from "prop-types";
import { CircularProgress, IconButton } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const CombinedPDFButton = ({ name, list, ...props }) => {
  const [processing, setProcessing] = useState(false);

  const userAuthData = JSON.parse(localStorage.getItem("msToken"));

  const generatePdf = () => {
    setProcessing(true);
    const requestOptions = {
      method: "POST",
      headers: new Headers({
        Authorization: "Bearer " + userAuthData?.accessToken,
        Accept: "application/pdf",
        "Content-Type": "application/json",
      }),
      body: JSON.stringify({ name, list }),
    };

    fetch(
      `${import.meta.env.VITE_VTS_API}/v1/pdf_generator/combined`,
      requestOptions,
    )
      .then((response) => {
        setProcessing(false);
        if (response.status === 200) {
          response.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${name}.pdf`);
            document.body.appendChild(link);
            link.click();
          });
        } else {
          alert(`Status: ${response.status} - ${response.statusText}`);
        }
      })
      .catch((error) => {
        setProcessing(false);
        alert(`Error: ${error}`);
      });
  };

  return processing ? (
    <CircularProgress size={28} {...props} />
  ) : (
    <IconButton onClick={generatePdf} title="Generate PDF" {...props}>
      <PictureAsPdfIcon />
    </IconButton>
  );
};

CombinedPDFButton.defaultProps = {
  list: [],
};

CombinedPDFButton.propTypes = {
  name: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
      type: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default CombinedPDFButton;
