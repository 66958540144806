import { useEffect, useState } from "react";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import debounce from "debounce-promise";
import searchNewSelect from "@/lib/searchNewSelect";

const ProjectFilter = ({ onProjectChange }) => {
  //local states
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState(null);
  //debounce hook
  const applySearch = debounce((value) => setSearchTerm(value), 250);

  useEffect(() => {
    const searchFunction = searchNewSelect("project_number");
    setLoading(() => true);
    setOptions([]);
    searchFunction(searchTerm).then((r) => {
      setOptions(r.results);
      setLoading(false);
    });
  }, [searchTerm]);

  const handleFilter = (_e, value) => {
    applySearch(value);
  };

  const handleChange = (_e, data) => {
    setValue(data);
    onProjectChange(data);
  };

  return (
    <Autocomplete
      size="small"
      onInputChange={handleFilter}
      onChange={handleChange}
      value={value}
      id="components-filter"
      loading={loading}
      //Remove local filter functionality
      filterOptions={(opts) => opts}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      options={options}
      getOptionLabel={(option) => option.text}
      // fullWidth
      renderInput={(params) => (
        <TextField
          {...params}
          label={value ? "Project " : "Select Project"}
          variant="outlined"
        />
      )}
    />
  );
};

export default ProjectFilter;
