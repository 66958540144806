import { useMemo } from "react";
import NewSelectAutocomplete from "@/components/NewSelectAutocomplete";
import searchNewSelect from "@/lib/searchNewSelect";
import fetchUrl from "@/lib/fetchUrl";
import JSGanttComponent from "react-jsgantt";
import moment from "moment";
import authHeaders from "@/lib/authHeaders";
import { useQuery } from "@tanstack/react-query";
import { useSearchParamsState } from "@/hooks/useSearchParamsState";
import { CircularProgress } from "@mui/material";

const filtersDefaults = {
  project: { type: "string", default: "" },
  task: { type: "string", default: "" },
};

const Planning = () => {
  const [filterParams, setFilterParams] = useSearchParamsState(filtersDefaults);

  const fetchProjects = useMemo(() => {
    return searchNewSelect("project_number");
  }, []);

  const fetchTasks = useMemo(() => {
    return searchNewSelect("project_task_number", {
      extra: filterParams.project,
      strict: 1,
    });
  }, [filterParams.project]);

  const handleProjectChange = (project, reason) => {
    switch (reason) {
      case "clear":
        setFilterParams({ project: "", task: "" });
        break;
      case "selectOption":
        project && setFilterParams({ project: project?.value, task: "" });
        break;
      default:
    }
  };

  const handleTaskChange = (task, reason) => {
    switch (reason) {
      case "clear":
        setFilterParams({ task: "" });
        break;
      case "selectOption":
        if (task) {
          if (task.value === "") {
            setFilterParams({ task: "" });
          } else {
            setFilterParams({ task: task.value.split("-")[1] });
          }
        }
        break;
      default:
    }
  };

  const param = {
    project: filterParams.project,
    task: filterParams.task,
  };
  const uri = `${import.meta.env.VITE_VTS_API}/v1/project/planning?project=${param.project}&task=${param.task}`;

  const { data, isLoading, isSuccess } = useQuery({
    enabled: filterParams.project ? true : false, // disable fetch if no params is selceted.
    queryKey: [`project_planning-${param.project}-${param.task}`],
    queryFn: () =>
      fetch(uri, {
        headers: authHeaders(),
      }).then((response) => response.json()),
  });

  return (
    <>
      <div style={{ display: "flex", gap: "1rem" }}>
        <NewSelectAutocomplete
          label="Project"
          optionsCallBack={fetchProjects}
          onChange={handleProjectChange}
          value={{
            value: filterParams.project,
            text: filterParams.project,
          }}
          variant="outlined"
          size="small"
        />
        <NewSelectAutocomplete
          label="Task"
          optionsCallBack={fetchTasks}
          extraOptions={[{ value: "", text: "All tasks" }]}
          onChange={handleTaskChange}
          value={{
            value: filterParams.task,
            text: filterParams.task ?? "All tasks",
          }}
          variant="outlined"
          disabled={!filterParams.project}
          helperText={!filterParams.project ? "Select a project" : ""}
          size="small"
        />
      </div>
      {isLoading && filterParams.project && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "2rem 0 0",
          }}
        >
          <CircularProgress size={20} />
        </div>
      )}
      {isSuccess && data?.tasks?.length > 0 && (
        <div style={{ margin: "2rem 0 0" }}>
          <JSGanttComponent
            data={data.tasks.map((e) => ({
              pID: e.id,
              pName: e.name,
              pStart: new Date(e.start) || "",
              pEnd: new Date(e.end) || "",
              pPlanStart: new Date(e.start) || "",
              pPlanEnd: new Date(e.end) || "",
              pClass: defineClass(isMilestone(e) ? "milestone" : e.type),
              pLink: defineLink(e.type, e.number),
              pMile: isMilestone(e) ? 1 : 0,
              pRes: e.resource,
              pComp: e.completion || 0,
              pGroup: defineGroup(e.type),
              pParent: e.parent,
              pOpen: e.type === "production" ? 0 : 1,
              pDepend: e.dependency,
              pCaption: "",
            }))}
            options={{
              vShowComp: false,
              vTotalHeight: `${window.innerHeight - 192}px`,
              vLang: "en",
              vScrollTo: "today",
              vTooltipTemplate: (task) => `
                <span class="gTtTitle">${task.getName()}</span>
                <div class="gTILine gTIsd">
                  <span class="gTaskLabel">{{Lang:pStart}}: </span>
                  <span class="gTaskText">
                    ${moment(task.getStart()).format("L")}
                  </span>
                </div>
                <div class="gTILine gTIed">
                  <span class="gTaskLabel">{{Lang:pEnd}}: </span>
                  <span class="gTaskText">
                    ${moment(task.getEnd()).format("L")}
                  </span>
                </div>
                <div class="gTILine gTId">
                  <span class="gTaskLabel">
                  Duration:
                  </span>
                  <span class="gTaskText">${task.getDuration()}</span>
                </div>
                <div class="gTILine gTIc">
                  <span class="gTaskLabel">{{Lang:pComp}}: </span>
                  <span class="gTaskText">{{pComp}}%</span>
                </div>
                <div class="gTILine gTIr">
                  <span class="gTaskLabel">{{Lang:pRes}}: </span>
                  <span class="gTaskText">{{pRes}}</span>
                </div>
                <div class="gTILine">
                  <span class="gTaskLabel">
                    Info:
                  </span>
                  <span class="gTaskText">
                    <a href="{{pLink}}" target="_blank">
                      See more info
                    </a>
                  </span>
                </div>
                <div class="gTILine gTIn">
                  <span class="gTaskLabel">Notes: </span>
                  <span class="gTaskNotes">${task.getNotes().outerHTML}</span>
                </div>
            `,
            }}
          />
        </div>
      )}
    </>
  );
};

export default Planning;

const defineClass = (type) => {
  switch (type) {
    case "milestone":
      return "gmilestone";
    case "project":
    case "task":
    case "production":
      return "ggroupblack";
    case "operation":
    case "purchase":
      return "gtaskblue";
    default:
      return "";
  }
};

const defineLink = (type, number) => {
  let split = number.split("-");
  switch (type) {
    case "operation":
      return fetchUrl("production/operations", {
        prod_order: split[0],
        operation: split[2],
      });
    case "project":
      return fetchUrl("project/deliveries", { project: number });
    case "production":
      return fetchUrl("production/production-order", { prod_order: number });
    case "purchase":
      return fetchUrl("purchase/orders", { order: split[0] });
    case "comment":
      return fetchUrl(`comments/${number}`);
    case "task":
      return fetchUrl("production/progress-per-resource/", {
        "filter[job_no]": number.split("-")[0],
      });
    default:
      return "";
  }
};

const defineGroup = (type) => {
  switch (type) {
    case "project":
    case "task":
    case "production":
      return 1;
    default:
      return 0;
  }
};

const isMilestone = (e) =>
  (e.duration === null || e.duration === "0.0") &&
  ["operation", "comment"].includes(e.type);
