import { PropTypes } from "prop-types";

export const commentsArrayType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    ncr_id: PropTypes.number,
    text: PropTypes.string.isRequired,
    user: PropTypes.string.isRequired,
  }),
);

export const ncrDataType = PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  level: PropTypes.oneOf(["registration", "action", "critical"]),
  comments: commentsArrayType,
  ncr_type: PropTypes.oneOf([
    "hms",
    "registration",
    "deviation",
    "deviation_permit",
    "technical_clarification",
  ]),
  owner: PropTypes.string,
  registered_by: PropTypes.string,
  title: PropTypes.string,
  attachments: PropTypes.array,
  delete_attachments: PropTypes.arrayOf(PropTypes.number),
  one_d: PropTypes.shape({
    company: PropTypes.shape({}),
    item: PropTypes.shape({}),
    customer: PropTypes.shape({}),
    vendor: PropTypes.shape({}),
  }),
  two_d: PropTypes.shape({}),
});
