import { ChangeEvent, useEffect, useState } from "react";
import humanizeSnakeCase from "@/lib/humanizeSnakeCase";
// @ts-ignore // v2 does not export types properly
import { RemoteDataTable } from "@oddadigitalsystem/datatable2";
import queryStringSerialize from "@/utils/queryStringSerialize";
import authHeaders from "@/lib/authHeaders";
import { useNavigate, useLocation } from "react-router";
import { pickBy } from "lodash";
import queryString from "query-string";
import prepareFilter from "@/lib/prepareFilter";
import AppBarButtonWrapper from "@/components/AppBarButtonWrapper";
import {
  Fab,
  useTheme,
  IconButton,
  Box,
  Checkbox,
  Tooltip,
} from "@mui/material";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import PrintIcon from "@mui/icons-material/Print";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ReplayIcon from "@mui/icons-material/Replay";
import { useSnackbar } from "notistack";
import FullpageTableScrollContainer from "@/components/FullpageTableScrollContainer";
import ConfirmDialogReceipt from "@/pages/purchase/create-receival-orders/ConfirmDialogReceipt";
import ConfirmDialogTracking from "@/pages/purchase/create-receival-orders/ConfirmDialogTracking";

interface OrderLine {
  id: number;
  error_message?: string;
  image?: string;
  item: string;
  serial_number: string;
  purchase_line: string;
  status:
    | "created"
    | "filling_tracking"
    | "tracking_filled"
    | "creating_receipt"
    | "receipt_created"
    | "receipt_error"
    | "tracking_error";
}

const CreateReceivalOrders = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const initialFilters = prepareFilter(queryString.parse(location.search));
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [confirmOrderOpen, setConfirmOrderOpen] = useState(false);
  const [confirmTrackingOpen, setConfirmTrackingOpen] = useState(false);
  const [orderLines, setOrderLines] = useState([] as OrderLine[]);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [showPrintButton, setShowPrintButton] = useState(false);

  const hasTrackingError = orderLines.some(
    (o) => o.status === "tracking_error",
  );
  const hasReceiptError = orderLines.some((o) => o.status === "receipt_error");

  const handleCreateOrder = (retry?: boolean) => {
    setLoading(true);
    fetch(
      `${import.meta.env.VITE_VTS_API}/v1/warehouse_receipts/create_receipt`,
      {
        headers: authHeaders(),
        method: "POST",
        body: retry ? JSON.stringify({ status: "receipt_error" }) : null,
      },
    )
      .then((r) => {
        if (r.status >= 200 && r.status < 300) return r.json();
        else throw new Error("Failed to create order");
      })
      .then((data) => {
        setLoading(false);
        setConfirmOrderOpen(false);
        enqueueSnackbar(data?.message.toString(), { variant: data?.status });
      })
      .catch((e) => {
        setLoading(false);
        setConfirmOrderOpen(false);
        enqueueSnackbar(e.toString(), { variant: "error" });
      });
  };

  const handleFillTracking = (retry: boolean) => {
    setLoading(true);
    fetch(
      `${import.meta.env.VITE_VTS_API}/v1/warehouse_receipts/create_tracking`,
      {
        headers: authHeaders(),
        method: "POST",
        body: retry ? JSON.stringify({ status: "tracking_error" }) : null,
      },
    )
      .then((r) => {
        if (r.status >= 200 && r.status < 300) return r.json();
        else throw new Error("Failed to fill tracking");
      })
      .then((data) => {
        setLoading(false);
        setConfirmTrackingOpen(false);
        enqueueSnackbar(data?.message.toString(), { variant: data?.status });
      })
      .catch((e) => {
        setLoading(false);
        setConfirmTrackingOpen(false);
        enqueueSnackbar(e.toString(), { variant: "error" });
      });
  };

  const handleStartTracking = (obj: OrderLine) =>
    fetch(
      `${import.meta.env.VITE_VTS_API}/v1/warehouse_receipts/${
        obj.id
      }/start_tracking`,
      {
        method: "PUT",
        headers: authHeaders(),
      },
    )
      .then((resp) => resp.json())
      .then((data) =>
        enqueueSnackbar(`${data.status}: ${data?.message}`, {
          variant: data?.status.toLowerCase() || "error",
        }),
      )
      .catch((e) =>
        enqueueSnackbar(JSON.stringify(e), {
          variant: "error",
        }),
      );

  const handleDeleteOrder = (id: number) => {
    setLoading(true);
    fetch(`${import.meta.env.VITE_VTS_API}/v1/warehouse_receipts/${id}`, {
      headers: authHeaders(),
      method: "DELETE",
    })
      .then((r) => {
        if (r.status !== 200) throw new Error("Failed to delete order");
        return r.json();
      })
      .then((data) => {
        setLoading(false);
        enqueueSnackbar(data?.message.toString(), { variant: data?.status });
        window.location.reload();
      })
      .catch((e) => {
        setLoading(false);
        enqueueSnackbar(e.toString(), { variant: "error" });
      });
  };

  const handleSelectedRow = (
    e: ChangeEvent<HTMLInputElement>,
    obj: OrderLine,
  ) => {
    const add = (id: number) => setSelectedRows((prev) => [id, ...prev]);

    const remove = (id: number) => {
      setSelectedRows((prev) => prev.filter((selected) => selected !== id));
    };

    if (e.target.checked) add(obj.id);
    else remove(obj.id);
  };

  const sendToPrint = () => {
    const queryString = selectedRows.map((row) => `id=${row}`);
    navigate(
      `/purchase/create-receival-orders/print-label?${queryString.join("&")}`,
    );
  };

  useEffect(() => {
    setShowPrintButton(selectedRows.length > 0);
  }, [selectedRows]);

  return (
    <>
      <AppBarButtonWrapper showSpinner={loading} />
      <ConfirmDialogReceipt
        onClose={() => setConfirmOrderOpen(false)}
        open={confirmOrderOpen}
        onConfirm={(retry) => handleCreateOrder(retry)}
        loading={loading}
        error={hasReceiptError}
      />
      <ConfirmDialogTracking
        onClose={() => setConfirmTrackingOpen(false)}
        open={confirmTrackingOpen}
        onConfirm={(retry) => handleFillTracking(retry)}
        loading={loading}
        error={hasTrackingError}
      />
      {showPrintButton && (
        <Fab
          color="secondary"
          variant="extended"
          style={{
            position: "fixed",
            bottom: theme.spacing(2),
            right: theme.spacing(50),
            zIndex: 999,
          }}
          onClick={sendToPrint}
        >
          <PrintIcon />
          Print Labels
        </Fab>
      )}
      <Fab
        color="primary"
        variant="extended"
        disabled={loading}
        style={{
          position: "fixed",
          bottom: theme.spacing(2),
          right: theme.spacing(2),
          zIndex: 999,
        }}
        onClick={() => setConfirmOrderOpen(true)}
      >
        <PostAddOutlinedIcon style={{ marginRight: theme.spacing(2) }} />
        Create orders
      </Fab>
      <Fab
        color="primary"
        variant="extended"
        disabled={loading}
        style={{
          position: "fixed",
          bottom: theme.spacing(2),
          right: theme.spacing(26),
          zIndex: 999,
        }}
        onClick={() => setConfirmTrackingOpen(true)}
      >
        <PostAddOutlinedIcon style={{ marginRight: theme.spacing(2) }} />
        Fill Tracking
      </Fab>

      <FullpageTableScrollContainer>
        <RemoteDataTable
          initialFilters={initialFilters}
          size="small"
          enableSorting
          enableFiltering
          requestOptions={{
            rowsPerPage: 30,
          }}
          dataFetchCallback={(params: { filter: object }) => {
            navigate(
              `${location.pathname}?${queryStringSerialize(
                pickBy(params.filter),
              )}`,
            );
            return fetch(
              `${
                import.meta.env.VITE_VTS_API
              }/v1/warehouse_receipts?${queryStringSerialize(params)}`,
              {
                headers: authHeaders(),
              },
            )
              .then((r) => {
                setLoading(false);
                return r.json();
              })
              .then((data: { rows: OrderLine[]; total: number }) => {
                setOrderLines((prevRows) => [...prevRows, ...data.rows]);
                setLoading(false);
                return data;
              })
              .catch(() => setLoading(false));
          }}
          initialColumns={[
            {
              format: (v: string, obj: OrderLine) => (
                <Checkbox
                  key={obj.id}
                  onChange={(e) => handleSelectedRow(e, obj)}
                />
              ),
            },
            {
              name: "#",
              key: "id",
              sort: "desc",
            },
            {
              name: "Created at",
              key: "created_at",
              format: (v: string) =>
                new Date(v).toLocaleString(navigator.language),
              filterType: "date",
            },
            {
              name: "Item",
              key: "item",
              filterType: "text",
            },

            {
              name: "Line",
              key: "purchase_line",
              filterType: "text",
            },
            {
              name: "Order",
              key: "purchase_order",
              filterType: "text",
            },
            {
              name: "Quantity",
              key: "quantity",
              filterType: "text",
            },
            {
              name: "Serial number",
              key: "serial_number",
              filterType: "text",
            },
            {
              name: "Lot number",
              key: "lot_number",
              filterType: "text",
            },
            {
              name: "Status",
              key: "status",
              format: (v: any, obj: OrderLine) => (
                <Box display="flex" alignItems="center" width="100%">
                  <Tooltip title={obj.error_message || ""} arrow>
                    <Box
                      flexGrow={1}
                      mr={4}
                      color={
                        ["receipt_error", "tracking_error"].includes(v)
                          ? "error.main"
                          : undefined
                      }
                    >
                      {humanizeSnakeCase(v)}
                    </Box>
                  </Tooltip>
                  {["tracking_error", "created"].includes(v) && (
                    <IconButton
                      size="small"
                      style={{ marginRight: theme.spacing(2) }}
                      onClick={() => handleStartTracking(obj)}
                    >
                      {v === "created" ? (
                        <PostAddOutlinedIcon />
                      ) : (
                        <ReplayIcon />
                      )}
                    </IconButton>
                  )}
                  {["tracking_error", "created"].includes(v) && (
                    <IconButton
                      size="small"
                      style={{ marginRight: theme.spacing(2) }}
                      onClick={() => handleDeleteOrder(obj.id)}
                    >
                      <DeleteOutlineIcon />
                    </IconButton>
                  )}
                  {v === "receipt_created" && (
                    <IconButton
                      size="small"
                      style={{ padding: 0, marginRight: theme.spacing(2) }}
                      onClick={() =>
                        navigate(
                          `/purchase/create-receival-orders/print-label/${obj.id}`,
                        )
                      }
                    >
                      <PrintIcon fontSize="inherit" />
                    </IconButton>
                  )}
                </Box>
              ),
              filterOptionsCallback: () =>
                Promise.resolve({
                  results: [
                    { value: "created", text: "Created" },
                    { value: "filling_tracking", text: "Filling tracking" },
                    { value: "tracking_filled", text: "Tracking filled" },
                    { value: "creating_receipt", text: "Creating receipt" },
                    { value: "receipt_created", text: "Receipt created" },
                    { value: "receipt_error", text: "Receipt error" },
                    { value: "tracking_error", text: "Tracking error" },
                  ],
                }),
            },
          ]}
        />
      </FullpageTableScrollContainer>
    </>
  );
};

export default CreateReceivalOrders;
