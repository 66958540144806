import { useEffect, useMemo, useCallback, useState } from "react";
import { Link, IconButton } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

import AppBarButtonWrapper from "@/components/AppBarButtonWrapper";

import MaterialDataTable from "@/components/datatable/MaterialDataTable";
import useTable from "@/components/datatable/useTable";
import MaterialToolbar from "@/components/datatable/MaterialToolbar";
import { useNavigate, useLocation } from "react-router";
import searchNewSelect from "@/lib/searchNewSelect";
import {
  getFiltersFromQueryString,
  getSortingFromQueryString,
} from "@/lib/queryStringOperations";
import queryString from "query-string";
import FullpageTableScrollContainer from "@/components/FullpageTableScrollContainer";
import { ColumnDefinition, RowDefinition, SortParamsDefinition } from "@/types";
import { QueryStringParams } from "@/lib/iframeUtils/types";
import { fetchWithToken } from "@/lib/fetchWithToken";
import ItemCell from "@/pages/production/progress-per-resource/ItemCell";
import ProgressCell from "@/pages/production/progress-per-resource/ProgressCell";
import OperationsCell from "@/pages/production/progress-per-resource/OperationsCell";
import PlannerResponsibleComponent from "@/pages/production/progress-per-resource/PlannerResponsibleComponent";

const STATUSES = {
  0: "Simulated",
  1: "Planned",
  2: "Firm Planned",
  3: "Released",
  4: "Finished",
} as { [key: number]: string };

const ENDPOINT = `${
  import.meta.env.VITE_VTS_API
}/v1/production/progress_per_resource`;

const blockFilterSortTooltip = () => "Select a project filter first";

const ProgressPerResource = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useMemo(
    () => queryString.parse(location.search) as unknown as QueryStringParams,
    [location.search],
  );

  const [responsible, setResponsible] = useState();
  const [planner, setPlanner] = useState();
  const [shouldBlockFilterAndSort, setShouldBlockFilterAndSort] =
    useState(false);

  const initialSearch = params.search;

  const initialSorting = getSortingFromQueryString(params || {});

  const initialFilters = Object.entries(
    getFiltersFromQueryString(params || {}),
  ).reduce((filters, [key, value]) => {
    if (Array.isArray(value) && key === "status")
      return {
        ...filters,
        [key]: value.map((v: number) => ({ value: v, text: STATUSES[v] })),
      };
    else if (Array.isArray(value))
      return { ...filters, [key]: value.map((v) => ({ value: v, text: v })) };
    else return { ...filters, [key]: { value: value, text: value } };
  }, {});

  const fetchProjects = useMemo(() => {
    return searchNewSelect("project_number");
  }, []);

  const getColumns = useMemo(() => {
    return [
      {
        name: "Project",
        key: "job_no",
        filterType: "select",
        filterOptionsCallback: fetchProjects,
      },
      {
        name: "Prod. order",
        key: "production_order_no",
        renderTooltipFn: shouldBlockFilterAndSort
          ? undefined
          : blockFilterSortTooltip,
        renderCellFn: (v: string) => (
          <Link
            color="inherit"
            href={`/production/production-order?prod_order=${v}`}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/production/production-order?prod_order=${v}`);
            }}
          >
            {v}
          </Link>
        ),
      },
      {
        name: "Item",
        key: "item_no",
        filterType: "number",
        renderCellFn: (v: string, row: RowDefinition) => {
          const { ncr, job_no } = row;
          return <ItemCell item={v} ncr={ncr} project_no={job_no} />;
        },
        renderTooltipFn: shouldBlockFilterAndSort
          ? undefined
          : blockFilterSortTooltip,
      },
      {
        name: "Task",
        key: "job_task_no",
        filterType: "number",
        renderTooltipFn: shouldBlockFilterAndSort
          ? undefined
          : blockFilterSortTooltip,
      },
      {
        name: "PO Line",
        key: "po_line",
        filterType: "number",
        renderTooltipFn: shouldBlockFilterAndSort
          ? undefined
          : blockFilterSortTooltip,
      },
      {
        name: "Cust. item no",
        key: "customer_part_no",
        filterType: "text",
        renderTooltipFn: shouldBlockFilterAndSort
          ? undefined
          : blockFilterSortTooltip,
      },
      {
        name: "Drawing no",
        key: "drawing_no",
        filterType: "text",
        renderTooltipFn: shouldBlockFilterAndSort
          ? undefined
          : blockFilterSortTooltip,
      },
      {
        name: "Serial no",
        key: "serial_no",
        filterType: "text",
        renderTooltipFn: shouldBlockFilterAndSort
          ? undefined
          : blockFilterSortTooltip,
        renderCellFn: (v: Array<number>) => (
          <div>
            {v?.map((number, i: number) => (
              <div
                key={i + "progress_per_resource"}
                style={{
                  color: i % 2 ? "rgba(0, 0, 0, 0.5)" : undefined,
                  marginBottom: "3px",
                }}
              >{`${number}${
                v?.length > 1 && v?.length - 1 !== i ? ", " : ""
              }`}</div>
            ))}
          </div>
        ),
      },
      {
        name: "Description",
        key: "description",
        filterType: "text",
        renderTooltipFn: shouldBlockFilterAndSort
          ? undefined
          : blockFilterSortTooltip,
      },
      {
        name: "Status",
        key: "status",
        filterType: "multipleSelect",
        renderTooltipFn: shouldBlockFilterAndSort
          ? undefined
          : blockFilterSortTooltip,
        filterOptionsCallback: () =>
          new Promise((resolve) =>
            resolve({
              results: Object.entries(STATUSES).map(([value, text], i) => ({
                value: value,
                text: text,
              })),
            }),
          ),
      },
      {
        name: "Progress",
        key: "progress",
        filterType: "text",
        renderTooltipFn: shouldBlockFilterAndSort
          ? undefined
          : blockFilterSortTooltip,
        renderCellFn: (_v: any, row: RowDefinition) => (
          <ProgressCell routing={row?.routing} />
        ),
      },
      {
        name: "Operations",
        key: "operations",
        disableSort: true,
        disableFilter: true,
        renderTooltipFn: () => "This column can not be filtered/sorted",
        headCellPropsFn: () => ({ sx: { width: 500, minWidth: 500 } }),
        renderCellFn: (_v: any, row: RowDefinition) => (
          <OperationsCell
            routing={row?.routing}
            productionOrderNumber={row?.production_order_no}
            onClick={(url: string) => navigate(url)}
          />
        ),
      },
    ] as ColumnDefinition[];
  }, [fetchProjects, navigate, shouldBlockFilterAndSort]);

  const { state, handlers, setters } = useTable({
    options: { multiSort: false, mode: "server" },
    columns: getColumns,
    initialFilters: initialFilters,
    initialSort: initialSorting as SortParamsDefinition,
    initialSearch: initialSearch,
  });

  const { setTotalRows, setLoading } = setters;
  const {
    handleApplyFilter,
    handleRemoveFilter,
    handleToggleSort,
    handleAddRows,
    handleRefresh,
    handleSearch,
    handleClearFilters,
    handleClearSort,
  } = handlers;

  const isFilteredByProject = Boolean(state.filters.job_no);

  const columnsToSortAndFilter = !isFilteredByProject
    ? ["job_no"]
    : state.columns.map(({ key }) => key);

  useEffect(() => {
    if (isFilteredByProject) {
      setShouldBlockFilterAndSort(true);
    } else {
      setShouldBlockFilterAndSort(false);
    }
  }, [isFilteredByProject]);

  const handleFilterRemoval = (key: string) => {
    if (key === "job_no") {
      handleClearFilters();
      handleClearSort();
    } else {
      handleRemoveFilter(key);
    }
  };

  const title = isFilteredByProject
    ? /* @ts-expect-error: not all filter types supported */
      `Project: ${state.filters.job_no.value}`
    : "Latest projects";

  const url = useMemo(() => {
    const url = new URL(ENDPOINT);
    url.searchParams.set("start", `${state.page}`);
    url.searchParams.set(
      "limit",
      `${isFilteredByProject ? 600 : state.rowsPerPage}`,
    );

    Object.entries(state.filters).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        /* @ts-expect-error: not all filter types supported */
        value.map((v) => url.searchParams.append(`filter[${key}][]`, v.value));
      }
      /* @ts-expect-error: not all filter types supported */
      value.value && url.searchParams.append(`filter[${key}]`, value.value);
    });

    Object.entries(state.sorting).forEach(([key, value]) => {
      url.searchParams.set(`sort[${key}]`, value);
    });

    state.search && url.searchParams.set("search", state.search);

    return url;
  }, [
    state.filters,
    state.page,
    state.rowsPerPage,
    state.search,
    state.sorting,
    isFilteredByProject,
  ]);

  useEffect(() => {
    setLoading(true);
    fetchWithToken(url.toString())
      .then((r) => {
        handleAddRows(r.rows, true);
        setTotalRows(r?.total);
        setPlanner(r.planner);
        setResponsible(r.responsible);
      })
      .catch((err) => {
        handleAddRows([], true);
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, [url, state.refreshedAt, setLoading, handleAddRows, setTotalRows]);

  const handleLoadMore = useCallback(() => {
    const nextUrl = new URL(url);
    nextUrl.searchParams.set("start", `${state.rows.length}`);
    nextUrl.searchParams.set("limit", `${state.rowsPerPage}`);

    if (!state.loading) {
      setLoading(true);
      fetchWithToken(nextUrl.toString())
        .then((r) => {
          handleAddRows(r.rows, false);
          setTotalRows(r?.total);
          setPlanner(r.planner);
          setResponsible(r.responsible);
        })
        .catch((err) => {
          handleAddRows([], true);
          console.log(err);
        })
        .finally(() => setLoading(false));
    }
  }, [
    handleAddRows,
    state.loading,
    state.rows.length,
    state.rowsPerPage,
    setLoading,
    setTotalRows,
    url,
  ]);

  useEffect(() => {
    navigate({ search: url.search });
  }, [url, navigate]);

  return (
    <>
      <AppBarButtonWrapper showSpinner={state.loading}>
        <IconButton
          disabled={state.loading}
          size="large"
          onClick={handleRefresh}
        >
          <RefreshIcon />
        </IconButton>
      </AppBarButtonWrapper>
      <FullpageTableScrollContainer>
        <MaterialToolbar
          title={title}
          loadedRows={state.loadedRows}
          totalRows={state.totalRows}
          filters={state.filters}
          loading={state.loading}
          columns={state.columns}
          onRemoveFilter={handleFilterRemoval}
          onSearch={handleSearch}
          extraToolbarComponent={
            <PlannerResponsibleComponent
              isFilteredByProject={isFilteredByProject}
              responsible={responsible}
              planner={planner}
            />
          }
        />
        <MaterialDataTable
          TableContainerProps={{ sx: { overflow: "initial" } }} // important to get body scroll working
          TableProps={{ size: "small" }}
          onPagination={handleLoadMore}
          onApplyFilter={handleApplyFilter}
          onRemoveFilter={handleFilterRemoval}
          onToggleSort={(e, column) => handleToggleSort(column.key)}
          enableFiltering
          allowedFilterKeys={columnsToSortAndFilter}
          allowedSortKeys={columnsToSortAndFilter}
          {...state}
        />
      </FullpageTableScrollContainer>
    </>
  );
};

export default ProgressPerResource;
