import MaterialToolbar from "@/components/datatable/MaterialToolbar";
import MaterialDataTable from "@/components/datatable/MaterialDataTable";
import useTable from "@/components/datatable/useTable";
import { Card, TablePagination, Typography, CardContent } from "@mui/material";
import getProjectsReferencesColumns from "@/pages/home/components/getProjectsReferencesColumns";
import Alert from "@mui/material/Alert";
import { useQuery } from "@tanstack/react-query";
import authHeaders from "@/lib/authHeaders";
import { useEffect } from "react";

const columns = getProjectsReferencesColumns();

const ProjectsReferences = () => {
  const { error, data, isLoading } = useQuery({
    queryKey: ["dashboard-projects-references"],
    queryFn: () =>
      fetch(`${import.meta.env.VITE_VTS_API}/v1/dashboard/project_reference`, {
        headers: authHeaders(),
      }).then((response) => response.json()),
  });

  const userData = JSON.parse(localStorage.getItem("msToken"));

  const { handlers, state, setters } = useTable({
    columns: columns,
    options: {
      multiSort: false,
      mode: "client",
    },
    initialSearch: userData?.account.name,
  });

  const {
    handleApplyFilter,
    handleRemoveFilter,
    handleSearch,
    handlePageChange,
    handleChangeRowsPerPage,
    handleToggleSort,
  } = handlers;

  const { page, rows, rowsPerPage, getPaginatedRows } = state;

  const { setRows } = setters;

  useEffect(() => {
    setRows(data?.projects || []);
  }, [data, setRows]);

  if (error) {
    return (
      <Card variant="outlined" sx={{ mb: 3, px: 3, py: 3 }}>
        <Typography variant="p">Project References</Typography>
        <CardContent sx={{ verticalAlign: "top", pt: 2, px: 0, pb: 0 }}>
          <Alert severity="error">{error?.message}</Alert>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card variant="outlined">
      <MaterialToolbar
        columns={state.columns}
        onSearch={handleSearch}
        onRemoveFilter={handleRemoveFilter}
        initialSearch={state.search}
        title={
          <Typography variant="subtitle1" sx={{ px: 0.5 }}>
            Project References
          </Typography>
        }
      />
      <MaterialDataTable
        onApplyFilter={handleApplyFilter}
        onRemoveFilter={handleRemoveFilter}
        onToggleSort={(_e, column) => handleToggleSort(column.key)}
        enableFiltering
        {...state}
        loading={isLoading}
        rows={getPaginatedRows()}
        TableContainerProps={{
          sx: { height: 362 },
        }}
        TableProps={{ size: "small" }}
      />
      <TablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 20, 50, { value: -1, label: "All" }]}
        rowsPerPage={rowsPerPage}
        count={rows.length}
        page={page}
        onPageChange={(e, newIndex) => handlePageChange(newIndex)}
        onRowsPerPageChange={(e) => {
          handleChangeRowsPerPage(parseInt(e.target.value));
        }}
        disabled={rows.length <= 0}
        sx={{ m: 1 }}
      />
    </Card>
  );
};

export default ProjectsReferences;
