import PropTypes from "prop-types";
import React from "react";
import {
  IconButton,
  Dialog,
  AppBar,
  Toolbar,
  Typography,
  useTheme,
  Stepper,
  Step,
  StepLabel,
  Slide,
  DialogContent,
  Container,
  Grid,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ItemReceival = ({
  appBarProps,
  dialogProps,
  extraToolbarButtons: ExtraToolbarButtons,
  showStepper,
  searchComponent,
  formComponent,
  itemNumberListComponent,
  purchaseOrderComponent,
  activeStep,
  steps,
  onStepClick,
  open,
  onClose,
  trackingInfoComponent,
  title,
}) => {
  const theme = useTheme();

  return (
    <Dialog fullScreen open={open} position="relative" {...dialogProps}>
      <AppBar color="inherit" {...appBarProps}>
        <Toolbar>
          <IconButton edge="start" onClick={onClose}>
            <CloseIcon color="action" />
          </IconButton>
          <Typography
            variant="h6"
            style={{ marginLeft: theme.spacing(1), flexGrow: 1 }}
          >
            {title}
          </Typography>
          {ExtraToolbarButtons && <ExtraToolbarButtons />}
        </Toolbar>
      </AppBar>
      <Grid
        container
        direction="column"
        style={{ paddingTop: theme.spacing(10) }}
      >
        {showStepper && (
          <Stepper
            activeStep={activeStep}
            alternativeLabel
            style={{
              paddingTop: theme.spacing(2),
              paddingBottom: 0,
              margin: 0,
            }}
            color="secondary"
          >
            {steps.map((label, i) => (
              <Step
                key={label}
                onClick={() => activeStep > i && onStepClick(i)}
                style={{ cursor: i !== activeStep && "pointer" }}
              >
                <StepLabel StepIconProps={{}}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
        {searchComponent && activeStep === 0 && (
          <Container component={Box} m={2}>
            {searchComponent}
          </Container>
        )}

        {trackingInfoComponent && activeStep >= 1 && trackingInfoComponent}
      </Grid>

      <DialogContent style={{ padding: 0 }}>
        {activeStep === 0 && (
          <Slide in={activeStep === 0} direction="left">
            <div>{itemNumberListComponent}</div>
          </Slide>
        )}

        {activeStep === 1 && (
          <Slide in={activeStep === 1} direction="left">
            <div>{purchaseOrderComponent}</div>
          </Slide>
        )}
        {activeStep === 2 && (
          <Slide in={activeStep === 2} direction="left">
            <div>{formComponent}</div>
          </Slide>
        )}
      </DialogContent>
    </Dialog>
  );
};

ItemReceival.propTypes = {
  activeStep: PropTypes.number,
  appBarProps: PropTypes.any,
  dialogProps: PropTypes.any,
  extraToolbarButtons: PropTypes.any,
  formComponent: PropTypes.any,
  itemNumberListComponent: PropTypes.any,
  onClose: PropTypes.any,
  onStepClick: PropTypes.func,
  open: PropTypes.any,
  purchaseOrderComponent: PropTypes.any,
  searchComponent: PropTypes.any,
  showStepper: PropTypes.bool,
  steps: PropTypes.array,
  trackingInfoComponent: PropTypes.any,
};

ItemReceival.defaultProps = {
  showStepper: true,
  title: "Receive new items",
};

export default ItemReceival;
