import React from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableContainerProps,
  TableProps,
  TableBodyProps,
  TableRowProps,
  TableCellProps,
} from "@mui/material";

import MaterialTableHeader from "./MaterialTableHeader";
import MaterialFilterPanelRemote from "./MaterialFilterPanel";

import {
  ApplyFiltersCallback,
  ColumnDefinition,
  FiltersDefinition,
  RowDefinition,
  SortParamsDefinition,
  ToggleSortCallback,
} from "@/types";
import MaterialTableBody from "./MaterialTableBody";

interface MaterialDataTableRemoteProps {
  enableFiltering?: boolean;
  onRowClick?: React.Dispatch<RowDefinition>;
  onPagination?: () => void;
  totalRows: number;
  columns: ColumnDefinition[];
  filters: FiltersDefinition;
  loading: boolean;
  onRemoveFilter: (key: string) => void;
  rows: RowDefinition[];
  originalRows?: RowDefinition[];
  onToggleSort: ToggleSortCallback;
  error: boolean;
  onApplyFilter: ApplyFiltersCallback;
  sorting: SortParamsDefinition;
  TableBodyProps?: TableBodyProps;
  TableProps: TableProps;
  TableContainerProps: TableContainerProps;
  TableCellProps?: TableCellProps;
  rowPropsFn?: (rowIndex: number, row: RowDefinition) => TableRowProps;
  allowedSortKeys?: string[]; // Alternative method to allow sorting for specific columns.
  allowedFilterKeys?: string[]; // Alternative method to allow filtering for specific columns.
}

function MaterialDataTable({
  rows,
  totalRows,
  columns,
  filters,
  sorting,
  enableFiltering,
  loading,
  originalRows,
  error,
  onRowClick,
  rowPropsFn,
  onPagination,
  onToggleSort,
  onApplyFilter,
  onRemoveFilter,
  TableProps,
  TableBodyProps,
  TableContainerProps,
  TableCellProps,
  allowedSortKeys,
  allowedFilterKeys,
}: MaterialDataTableRemoteProps) {
  const [openFilterKey, setOpenFilterKey] = React.useState("");
  const [filterPanelAnchor, setFilterPanelAnchor] =
    React.useState<HTMLButtonElement | null>(null);

  if (!Array.isArray(columns)) throw new Error("Invalid column shape provided");

  const filterPanelColumn = openFilterKey
    ? (columns?.find(({ key }) => key === openFilterKey) as ColumnDefinition)
    : undefined;

  const handleCloseFilterPanel = () => {
    setOpenFilterKey("");
    setFilterPanelAnchor(null);
  };

  const handleOpenFilterDialog = (
    e: React.SyntheticEvent<HTMLButtonElement>,
    column: ColumnDefinition,
  ) => {
    if (enableFiltering) {
      setOpenFilterKey(column?.key);
      setFilterPanelAnchor(e.currentTarget);
    }
  };

  const filterPanelIsOpen =
    typeof openFilterKey === "string" && openFilterKey !== "";

  return (
    <>
      <TableContainer {...TableContainerProps}>
        <Table stickyHeader {...TableProps}>
          <MaterialTableHeader
            columns={columns}
            onToggleSort={onToggleSort}
            onOpenFilterDialog={handleOpenFilterDialog}
            filters={filters}
            loading={loading}
            sorting={sorting}
            allowedSortKeys={allowedSortKeys}
            allowedFilterKeys={allowedFilterKeys}
          />
          <TableBody {...TableBodyProps}>
            <MaterialTableBody
              rows={rows}
              columns={columns}
              onRowClick={onRowClick}
              rowPropsFn={rowPropsFn}
              loading={loading}
              totalRows={totalRows}
              error={error}
              onScrollEnd={onPagination}
              TableCellProps={TableCellProps}
            />
          </TableBody>
        </Table>
      </TableContainer>
      {enableFiltering && filterPanelColumn && (
        <MaterialFilterPanelRemote
          column={filterPanelColumn}
          filter={filters}
          onApplyFilter={onApplyFilter}
          onRemoveFilter={onRemoveFilter}
          onClose={handleCloseFilterPanel}
          open={filterPanelIsOpen}
          anchorEl={filterPanelAnchor}
          originalRows={originalRows}
        />
      )}
    </>
  );
}

MaterialDataTable.defaultProps = {};

export default MaterialDataTable;
