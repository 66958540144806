import { Box, Container, IconButton, Grow, Slide, Card } from "@mui/material";
import { useState, useEffect, useRef } from "react";
import { Navigate, useParams } from "react-router";
import { useSnackbar } from "notistack";

import PrintIcon from "@mui/icons-material/Print";

import AppBarButtonWrapper from "@/components/AppBarButtonWrapper";
import { useReactToPrint } from "react-to-print";
import { useDispatch } from "react-redux";
import { onChangeTitle } from "@/redux/features/app-common-slice";
import { WeldReport } from "@/pages/reports/weld-log/components/WeldReport";

// Requests
import getWeldLogInfo from "@/services/weldLog/getWeldLogInfo";
import approveWeldLog from "@/services/weldLog/approveWeldLog";
import disapproveWeldLog from "@/services/weldLog/disapproveWeldLog";

const WeldLog = () => {
  const { enqueueSnackbar } = useSnackbar();
  let { id } = useParams();
  const dispatch = useDispatch();
  const printRef = useRef();

  const title = `Weld Log Report: ${id}`;
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    dispatch(onChangeTitle("Weld Log Report"));
    document.title = "Weld Log Report - Visual Tracking";
  }, [dispatch]);

  const handleSign = (approve) => {
    setLoading(true);
    const handleUpdateSign = approve ? approveWeldLog : disapproveWeldLog;

    handleUpdateSign(id)
      .then((response) => {
        setData(response);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        setError("Failed to update signature");
      });
  };

  useEffect(() => {
    setLoading(true);

    if (!id) {
      setLoading(false);
      setError(true);
    } else {
      getWeldLogInfo(id)
        .then((response) => {
          setData(response);
          setLoading(false);
        })
        .catch((e) => {
          if (e.status === 404) setError(true);
          else {
            enqueueSnackbar(
              "Failed to load the data, please try again later or contact the support.",
              { variant: "error" },
            );
          }
          setLoading(false);
        })
        .finally(() => setLoading(false));
    }
  }, [id, enqueueSnackbar]);

  if (!loading && !data && error) return <Navigate to="/not-found" replace />;
  else
    return (
      <>
        <AppBarButtonWrapper showSpinner={loading}>
          <Grow in={!loading} timeout={500}>
            <IconButton onClick={handlePrint} disabled={loading} size="large">
              <PrintIcon />
            </IconButton>
          </Grow>
        </AppBarButtonWrapper>
        <Box mt={3} height={1200} style={{ backgroundColor: "white" }}>
          <Slide in direction="up" timeout={500}>
            <Container maxWidth="md">
              <Card sx={{ p: 3 }}>
                <WeldReport
                  data={data}
                  title={title}
                  loading={loading}
                  printRef={printRef}
                  onSign={handleSign}
                  error={error}
                />
              </Card>
            </Container>
          </Slide>
        </Box>
      </>
    );
};

export default WeldLog;
