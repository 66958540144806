import { useState, useEffect, useMemo, useCallback } from "react";

import { alpha, Badge, Fade, IconButton, Link } from "@mui/material";

import RefreshIcon from "@mui/icons-material/Refresh";
import FilterIcon from "@mui/icons-material/FilterList";
import queryStringSerialize from "@/utils/queryStringSerialize";
import { RemoteDataTable } from "@oddadigitalsystem/datatable2";
import { parse } from "query-string";

import { pickBy } from "lodash";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router";

import AppBarButtonWrapper from "@/components/AppBarButtonWrapper";
import ProjectHighLight from "@/components/HighLight/ProjectHighLight";
import ProductionHighLight from "@/components/HighLight/ProductionHighLight";

import { getFiltersFromQueryString } from "@/pages/production/queue-list/queryStringFilterOperations";
import QueueListFilterDrawer from "@/pages/production/queue-list/filters/QueueListFilterDrawer";
import LoadingColumns from "@/pages/production/queue-list/LoadingColumns";
import RoutingStatusCell from "@/pages/production/queue-list/RoutingStatusCell";

import loadQueueListData from "@/services/production/queue-list/loadQueueListData";
import loadWeekColumns from "@/services/production/queue-list/loadWeekColumns";
import { getISOWeek, parseISO, format, isThisWeek } from "date-fns";
import FullpageTableScrollContainer from "@/components/FullpageTableScrollContainer";
import NcrCell from "@/components/NcrCell";
import useFilterState from "@/hooks/useFilterState";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ConditionalMessage from "@/components/ConditionalMessage";

const DEFAULT_PROD_ORDER_STATUS = ["Released"];

const DEFAULT_FILTERS = {
  start_date: null,
  end_date: null,
  address: null,
  machine_number: null,
  name: null,
  work: null,
  prod_order_status: DEFAULT_PROD_ORDER_STATUS,
};

const generateWeekHeader = (isostring, total) => {
  const parsedDate = parseISO(isostring);
  const weekNumber = getISOWeek(parsedDate);
  const totalDisplay = total ? `${total}` : "";
  const date = format(parsedDate, "dd.MM.yy");

  return `${totalDisplay}
  W: ${weekNumber}
  ${date}`;
};

const wrapHeader = {
  whiteSpace: "pre-line",
  overflowWrap: "break-word",
  lineHeight: 1.2,
  fontSize: 12,
  padding: 6,
};

const cellStyle = { fontSize: 12, padding: 6 };

const OpenInNewWindow = ({ value, href, ...props }) => (
  <Link
    href={`${href}`}
    target="_blank"
    rel="noreferrer noopener"
    color="#000"
    underline="always"
  >
    {value}
  </Link>
);

const QueueList = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const urlParams = useMemo(
    () => parse(location.search, { arrayFormat: "bracket" }),
    [location.search],
  );

  const urlParamsFilters = getFiltersFromQueryString(urlParams);

  const { filters, setFilters, setFilterValue, handleClear } = useFilterState({
    ...DEFAULT_FILTERS,
    ...urlParamsFilters,
  });

  const [tableRefreshed, refreshTable] = useState();
  const tableRefreshDependency = `${tableRefreshed}`;

  const [loading, setLoading] = useState(false);

  const [weekColumns, setWeekColumns] = useState({});
  const [columnsLoading, setColumnsLoading] = useState(false);

  const { prod_order_status, ...filtersToCheck } = filters;

  const missingFilters =
    Object.values(filtersToCheck).filter(Boolean)?.length <= 0;
  const hasWeeks = Object.keys(weekColumns).length > 0;
  const amountOfFiltersApplied = Object.entries(pickBy(filters))?.length ?? 0;
  const isFiltered =
    JSON.stringify(filters) !== JSON.stringify(DEFAULT_FILTERS);

  const [filterDrawerOpen, setFilterDrawerOpen] = useState(
    isFiltered ? false : true,
  );

  const handleUpdateDateFilters = useCallback(
    (start_date, end_date) => {
      setFilterValue("start_date", new Date(start_date).toISOString());
      setFilterValue("end_date", new Date(end_date).toISOString());
    },
    [setFilterValue],
  );

  const handleQueueList = useCallback(
    ({ search, ...parameters }) => {
      const mergedParams = {
        ...parameters,
        filter: {
          ...parameters?.filter,
          ...pickBy(filters),
        },
        sort: parameters.sort || {},
      };

      if (search) mergedParams.filter.search = search;
      const queryString = queryStringSerialize(mergedParams);
      navigate({ search: queryString });
      setLoading(true);
      return loadQueueListData(queryString)
        .catch((e) => {
          enqueueSnackbar(
            `${t(
              "Failed to load production queue list data",
            )}: ${e?.toString()}`,
            { variant: "error" },
          );
          return e;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [enqueueSnackbar, filters, navigate, t],
  );

  const fetchWeekColums = useCallback(
    (newFilters, forceRefreshTable) => {
      setColumnsLoading(true);
      loadWeekColumns({ filter: pickBy(newFilters) })
        .then((columns) => {
          const { start_date, end_date } = columns;
          handleUpdateDateFilters(start_date, end_date);
          setWeekColumns(columns);
        })
        .catch((error) => {
          enqueueSnackbar(
            `${t("Failed to load the table columns")}: ${error}`,
            {
              variant: "error",
            },
          );
        })
        .finally(() => {
          setColumnsLoading(false);
          forceRefreshTable && refreshTable(new Date().getTime());
        });
    },
    [enqueueSnackbar, handleUpdateDateFilters, t],
  );

  const handleUpdateFilters = (newFilters) => {
    const queryString = queryStringSerialize({ filter: pickBy(newFilters) });
    navigate({ search: queryString });
    setFilters(newFilters);
    setFilterDrawerOpen(false);
    fetchWeekColums(newFilters, true);
  };

  useEffect(() => {
    if (isFiltered && !filterDrawerOpen) {
      fetchWeekColums(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AppBarButtonWrapper showSpinner={loading || columnsLoading}>
        <>
          <IconButton
            onClick={() => navigate("/production/queue-list/work-centers")}
          >
            <ListAltIcon />
          </IconButton>
          <IconButton
            onClick={() => setFilterDrawerOpen((oldState) => !oldState)}
            disabled={loading}
            size="large"
          >
            <Badge
              badgeContent={amountOfFiltersApplied}
              color={missingFilters ? "error" : "primary"}
            >
              <FilterIcon />
            </Badge>
          </IconButton>
          <IconButton
            onClick={() => refreshTable(new Date().getTime())}
            disabled={loading}
            size="large"
          >
            <RefreshIcon />
          </IconButton>
        </>
      </AppBarButtonWrapper>
      <QueueListFilterDrawer
        filters={filters}
        open={filterDrawerOpen}
        onSubmit={handleUpdateFilters}
        onClose={() => setFilterDrawerOpen(false)}
        onFilterChange={setFilterValue}
        onClear={() => handleClear(DEFAULT_FILTERS)}
      />
      <FullpageTableScrollContainer>
        {columnsLoading && <LoadingColumns />}

        <ConditionalMessage
          primary="No filters selected"
          secondary="Please select a filter to see the results"
          condition={missingFilters && !filterDrawerOpen}
        >
          <Fade in={!columnsLoading} timeout={200}>
            <div>
              {hasWeeks && (
                <RemoteDataTable
                  key={tableRefreshDependency}
                  size="small"
                  dataFetchCallback={handleQueueList}
                  enableSearch
                  initialColumns={[
                    {
                      disableSorting: true,
                      key: "work_center_number",
                      name: t("Work Center"),
                      columnStyle: { ...wrapHeader },
                      cellStyle: { ...cellStyle },
                    },
                    {
                      disableSorting: true,
                      key: "number",
                      name: t("Machine Number"),
                      columnStyle: { ...wrapHeader },
                      cellStyle: { ...cellStyle },
                    },
                    {
                      disableSorting: true,
                      key: "description",
                      name: t("Description"),
                      columnStyle: { ...wrapHeader },
                      cellStyle: { ...cellStyle },
                    },
                    {
                      disableSorting: true,
                      key: "job_number",
                      name: t("Project Number"),
                      columnStyle: { ...wrapHeader },
                      cellStyle: { ...cellStyle },
                      format: (v) => <ProjectHighLight>{v}</ProjectHighLight>,
                    },
                    {
                      disableSorting: true,
                      key: "project_name",
                      name: t("Project Name"),
                      columnStyle: { ...wrapHeader },
                      cellStyle: { ...cellStyle },
                    },
                    {
                      disableSorting: true,
                      key: "production_order",
                      name: t("Production Order"),
                      columnStyle: { ...wrapHeader },
                      cellStyle: { ...cellStyle },
                      format: (v, { ncr }) => (
                        <NcrCell ncr={ncr}>
                          <ProductionHighLight
                            LinkProps={{ color: "inherit", underline: "none" }}
                          >
                            {v}
                          </ProductionHighLight>
                        </NcrCell>
                      ),
                    },
                    {
                      key: "ncr",
                      hide: true,
                    },
                    {
                      disableSorting: true,
                      key: "days",
                      name: t("Days"),
                      columnStyle: { ...wrapHeader },
                      cellStyle: { ...cellStyle },
                    },
                    {
                      disableSorting: true,
                      key: "item_description",
                      name: t("Item Description"),
                      columnStyle: { ...wrapHeader },
                      cellStyle: { ...cellStyle },
                      format: ({ label, link_reference }) => (
                        <OpenInNewWindow
                          href={`/items/lessons-learned?customer_drawing=${link_reference.drawing_no}`}
                          value={label}
                        />
                      ),
                    },
                    {
                      disableSorting: true,
                      key: "drawing_item_number",
                      name: t("Drawing Number"),
                      columnStyle: { ...wrapHeader },
                      cellStyle: { ...cellStyle },
                      format: ({ label, link_reference }) => (
                        <OpenInNewWindow
                          href={`/documents/documents?item_number=${link_reference.item_no}`}
                          value={label}
                        />
                      ),
                    },
                    {
                      disableSorting: true,
                      key: "operation",
                      name: t("Operation"),
                      columnStyle: { ...wrapHeader },
                      cellStyle: { ...cellStyle },
                      format: ({ label, link_reference }) => (
                        <OpenInNewWindow
                          href={`/production/operations?operation=${link_reference.operation_no}&prod_order=${link_reference.prod_order_no}`}
                          value={label}
                        />
                      ),
                    },
                    {
                      disableSorting: true,
                      key: "status",
                      name: t("Status"),
                      columnStyle: { ...wrapHeader },
                      cellStyle: { ...cellStyle },
                    },
                    ...Object.entries(weekColumns.total_weeks).map(
                      ([key, { date, total }]) => {
                        const isCurrentWeek = isThisWeek(parseISO(date));
                        const highlightColor = "rgba(255, 210, 0)";

                        const highlightBackgroundColor = isCurrentWeek
                          ? alpha(highlightColor, 0.2)
                          : undefined;

                        return {
                          disableSorting: true,
                          key,
                          name: generateWeekHeader(date, total),
                          columnStyle: {
                            ...wrapHeader,
                            fontSize: 12,
                          },
                          cellStyle: {
                            // make the content fit the cell
                            height: "1em",
                            padding: "0px",
                            borderBottom: "none",
                            borderLeft: `1px solid rgba(224, 224, 224, 1)`,
                            backgroundColor: highlightBackgroundColor,
                          },

                          format: (v) => <RoutingStatusCell {...v} />,
                        };
                      },
                    ),
                  ]}
                />
              )}
            </div>
          </Fade>
        </ConditionalMessage>
      </FullpageTableScrollContainer>
    </>
  );
};

export default QueueList;
