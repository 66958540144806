import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Box,
  Drawer,
  TextField,
  Stack,
} from "@mui/material";
import loadFilterOptions from "@/services/production/queue-list/loadFilterOptions";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import SelectAutoComplete from "./SelectAutoComplete";
import moment from "moment";
import { useDateMask } from "@/hooks/useLocaleMask";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import validate from "@/pages/production/queue-list/filters/validate";

const Filters = ({
  filters,
  open,
  onSubmit,
  onClose,
  onFilterChange,
  onClear,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { mask } = useDateMask();

  const anyRequiredFieldSelected = ["address", "machine_number", "name", "work"]
    .map((e) => filters[e])
    .some(Boolean);

  const [filterOptions, setFilterOptions] = useState({});
  const [filterOptionsLoading, setFilterOptionsLoading] = useState(true);
  const [error, setError] = useState({});
  const submitIsDisabled = Object.entries(error ?? {})?.length > 1;

  const handleSubmit = () => {
    typeof onSubmit === "function" && onSubmit(filters);
  };

  const startDateText = filters?.start_date
    ? t("Start date")
    : `${t("Start date")} (${t("automatic")})`;
  const endDateText = filters?.end_date
    ? t("End date")
    : `${t("End date")} (${t("automatic")})`;

  const handleDateChange = (dates) => {
    onFilterChange(
      "start_date",
      dates[0]?.startOf("day").toISOString() || null,
    );
    onFilterChange("end_date", dates[1]?.endOf("day").toISOString() || null);
  };

  useEffect(() => {
    const validationErrors = validate(filters, t);
    if (validationErrors) {
      setError(validationErrors);
      return;
    }
    setError({});
  }, [filters, t]);

  useEffect(() => {
    setFilterOptionsLoading(true);
    loadFilterOptions()
      .then((filterOptions) => {
        setFilterOptions(filterOptions);
      })
      .catch((e) => {
        enqueueSnackbar(t("Failed to load filters"), {
          variant: "error",
        });
      })
      .finally(() => {
        setFilterOptionsLoading(false);
      });
  }, [enqueueSnackbar, t]);

  return (
    <Drawer open={open} anchor="right" onClose={onClose}>
      <Box width={340}>
        <Box display="flex" alignItems="center" m={2}>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            {t("Filters")}
          </Typography>
          <Button
            disabled={filterOptionsLoading}
            onClick={() => onClear()}
            color="inherit"
          >
            {t("Clear")}
          </Button>
          <Button
            disabled={filterOptionsLoading || submitIsDisabled}
            onClick={handleSubmit}
            color="primary"
          >
            {t("Save")}
          </Button>
        </Box>
        <Box m={2} display="flex" flexDirection="column">
          <Stack spacing={2}>
            <DateRangePicker
              calendars={1}
              mask={mask}
              startText={startDateText}
              endText={endDateText}
              clearable
              onChange={handleDateChange}
              value={[
                filters.start_date ? moment(filters.start_date) : null,
                filters.end_date ? moment(filters.end_date) : null,
              ]}
              renderInput={(startProps, endProps) => (
                <Stack spacing={2} sx={{ width: "100%" }}>
                  <TextField {...startProps} fullWidth size="small" />
                  <TextField {...endProps} fullWidth size="small" />
                </Stack>
              )}
            />
            <SelectAutoComplete
              label={t("Address")}
              id="address"
              onChange={(value) => onFilterChange("address", value)}
              options={filterOptions.addresses || []}
              value={filters.address}
              inputProps={{
                error: !!error.address,
                helperText: error.address,
                required: !anyRequiredFieldSelected,
              }}
              disabled={filterOptionsLoading}
              size="small"
            />
            <SelectAutoComplete
              label={t("Manager")}
              id="name"
              onChange={(value) => onFilterChange("name", value)}
              options={filterOptions.manager_names || []}
              value={filters.name}
              inputProps={{
                error: !!error.name,
                helperText: error.name,
                required: !anyRequiredFieldSelected,
              }}
              disabled={filterOptionsLoading}
              size="small"
            />
            <SelectAutoComplete
              label={t("Work center number")}
              id="work"
              onChange={(value) => onFilterChange("work", value)}
              options={filterOptions.work_center_numbers || []}
              value={filters.work}
              inputProps={{
                error: !!error.work,
                helperText: error.work,
                required: !anyRequiredFieldSelected,
              }}
              disabled={filterOptionsLoading}
              size="small"
            />
            <SelectAutoComplete
              label={t("Machine number")}
              id="machine_number"
              onChange={(value) => onFilterChange("machine_number", value)}
              options={filterOptions.machine_numbers || []}
              value={filters.machine_number}
              inputProps={{
                error: !!error.machine_number,
                helperText: error.machine_number,
                required: !anyRequiredFieldSelected,
              }}
              disabled={filterOptionsLoading}
              size="small"
            />

            <SelectAutoComplete
              label={t("Production order status")}
              id="prod_order_status"
              onChange={(value) => {
                onFilterChange("prod_order_status", value);
              }}
              options={filterOptions.prod_order_status || []}
              value={filters.prod_order_status}
              disabled={filterOptionsLoading}
              size="small"
              multiple
            />
          </Stack>
        </Box>
      </Box>
    </Drawer>
  );
};

Filters.propTypes = {
  filters: PropTypes.shape({
    address: PropTypes.string,
    end_date: PropTypes.string,
    machine_number: PropTypes.string,
    name: PropTypes.string,
    prod_order_status: PropTypes.arrayOf(PropTypes.string),
    start_date: PropTypes.string,
    work: PropTypes.string,
  }),
  onClear: PropTypes.func,
  onClose: PropTypes.func,
  onFilterChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

export default Filters;
