import React from "react";
import { Chip } from "@mui/material";
import {
  ColumnDefinition,
  FiltersDefinition,
  SelectOptionDefinition,
} from "@/types";

interface MaterialFilterChipProps {
  filters: FiltersDefinition;
  onRemoveFilter?: React.Dispatch<string>;
  columns: ColumnDefinition[];
}

const generateChipLabel = (
  columns: ColumnDefinition[],
  columnName: string,
  filters: FiltersDefinition,
): string => {
  const column = columns.find((c) => c.key === columnName);
  if (!column) return "";

  const filter = filters[columnName];
  if (!filter) return "";

  return getChipInformation(column.name, filter);
};

const getChipInformation = (
  columnName: string,
  filter: SelectOptionDefinition | SelectOptionDefinition[] | undefined,
): string => {
  let filterText = "";
  if (!filter) return "";
  if (Array.isArray(filter)) {
    filterText = filter.map((f) => f.text).join(", ");
    columnName = `${columnName} (${filter.length})`;
  } else filterText = filter.text;

  return `${columnName}: ${filterText}`;
};

const MaterialFilterChip = ({
  filters,
  onRemoveFilter,
  columns,
}: MaterialFilterChipProps) => {
  return (
    <>
      {Object.keys(filters).map((key, i) => (
        <Chip
          key={i}
          label={generateChipLabel(columns, key, filters)}
          style={{ margin: 5, maxWidth: 200 }}
          onDelete={() => onRemoveFilter?.(key)}
          variant="filled"
        />
      ))}
    </>
  );
};

export default MaterialFilterChip;
