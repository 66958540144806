import React from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";

const AppBarSpinner = ({ loading, spinnerSize, color, ...props }) => {
  if (loading)
    return (
      <IconButton
        color={color}
        onClick={null}
        disableFocusRipple
        disableRipple
        {...props}
        size="large"
      >
        <CircularProgress color="inherit" size={spinnerSize} />
      </IconButton>
    );
  else return null;
};

AppBarSpinner.defaultProps = {
  spinnerSize: 20,
  color: "secondary",
};

AppBarSpinner.propTypes = {
  loading: PropTypes.bool.isRequired,
  color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  spinnerSize: PropTypes.number,
};

export default AppBarSpinner;
