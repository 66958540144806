import { useState, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import AutofillCard from "./AutofillCard";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormLabel,
  Box,
  useTheme,
  useMediaQuery,
  Tooltip,
  MenuItem,
} from "@mui/material";
import { useFormikContext } from "formik";
import StatusChip from "../common/StatusChip";
import NcrField from "../common/NcrField";
import asyncOptionsAdornment from "../common/asyncOptionsAdornment";
import useFetch from "@/hooks/useFetch";
import authHeaders from "@/lib/authHeaders";
import { useSnackbar } from "notistack";

const formRootName = "one_d";
const companyRootName = `${formRootName}.company`;
const itemRootName = `${formRootName}.item`;
const customerRootName = `${formRootName}.customer`;
const vendorRootName = `${formRootName}.vendor`;

const NcrAccordion = ({ children, isFilled }) => {
  const [expanded, setExpanded] = useState(false);

  const handleExpand = () => setExpanded(!expanded);

  useEffect(() => {
    setExpanded(Boolean(isFilled));
  }, [isFilled]);
  return (
    <Accordion
      variant="outlined"
      expanded={expanded}
      onChange={handleExpand}
      sx={{
        borderRadius: 2,
        marginTop: "20px",
        "&:before": {
          display: "none",
        },
        "&:last-of-type": {
          borderRadius: 2,
        },
      }}
    >
      {children}
    </Accordion>
  );
};

const FormOne = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { values, errors, setFieldValue } = useFormikContext();

  const companyIsFilled = Object.values(values.one_d?.company || {}).some(
    Boolean,
  );
  const itemIsFilled = Object.values(values.one_d?.item || {}).some(Boolean);
  const customerIsFilled = Object.values(values.one_d?.customer || {}).some(
    Boolean,
  );
  const vendorIsFilled = Object.values(values.one_d?.vendor || {}).some(
    Boolean,
  );

  const companyHasErrors = Boolean(errors?.one_d?.company);
  const itemHasErrors = Boolean(errors?.one_d?.item);
  const customerHasErrors = Boolean(errors?.one_d?.customer);
  const vendorHasError = Boolean(errors?.one_d?.vendor);

  const accordionStyle = {
    display: "flex",
    flexDirection: isMobile ? "column" : "row",
  };

  const { enqueueSnackbar } = useSnackbar();

  const prodNumberError = Boolean(errors?.one_d?.company?.production_number);
  const selectedProductionNumber = values?.one_d?.company?.production_number;
  const operationFieldIsDisabled =
    !Boolean(selectedProductionNumber) || prodNumberError;

  const operations = useFetch({
    url: `${
      import.meta.env.VITE_VTS_API
    }/v1/new_select/routing_line_number?term=${selectedProductionNumber}`,
    fetchCondition: selectedProductionNumber?.length === 6,
  });

  useEffect(() => {
    if (!selectedProductionNumber || prodNumberError) {
      setFieldValue(`${companyRootName}.operation_details`, undefined);
      setFieldValue(`two_d.machine_number`, undefined);
    }
  }, [selectedProductionNumber, setFieldValue, prodNumberError]);

  const autoFillMachine = (productionNumber, operationNumber) =>
    productionNumber &&
    operationNumber &&
    fetch(
      `${
        import.meta.env.VITE_VTS_API
      }/v1/autofill_machine?production_order=${productionNumber}&operation=${operationNumber}`,
      { headers: authHeaders() },
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        return Promise.reject(res);
      })
      .then((response) => {
        if (response?.value) {
          setFieldValue("two_d.machine_number", response?.value);
          enqueueSnackbar(
            `"2D: Machine number ${response?.value.toString()} automatically filled`,
            { variant: "success" },
          );
        } else {
          setFieldValue("two_d.machine_number", "");
        }
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(`Failed to autofill machine number`, {
          variant: "error",
        });
      });

  return (
    <>
      <Box pb={1}>
        <AutofillCard />
      </Box>
      <NcrAccordion isFilled={companyIsFilled}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ flex: 1 }}>Company references</Typography>
          <StatusChip
            filled={companyIsFilled}
            error={companyHasErrors}
            sx={{ mr: 1 }}
          />
        </AccordionSummary>

        <AccordionDetails style={accordionStyle}>
          <Box flex={1} minWidth={0}>
            <NcrField
              rootName={companyRootName}
              name="production_number"
              label="Production order"
            />
            <NcrField
              rootName={companyRootName}
              name="operation_details"
              label="Operation number"
              select
              fastField={false}
              disabled={
                !selectedProductionNumber ||
                prodNumberError ||
                operations.loading
              }
              InputProps={asyncOptionsAdornment(
                operations?.data?.results,
                operations?.loading,
                operationFieldIsDisabled,
                "Select a production order",
              )}
            >
              {operations?.data?.results?.length > 0 ? (
                operations?.data?.results?.map(({ value, text }, i) => (
                  <MenuItem
                    key={i + "form_one"}
                    value={value}
                    onClick={() =>
                      autoFillMachine(selectedProductionNumber, value)
                    }
                  >
                    {text}
                  </MenuItem>
                ))
              ) : (
                <MenuItem disabled value={undefined}>
                  No options available
                </MenuItem>
              )}
            </NcrField>

            <NcrField
              rootName={companyRootName}
              name="job_number"
              label="Job number"
            />
            <NcrField
              rootName={companyRootName}
              name="job_description"
              label="Job description"
            />
          </Box>
          <Box mx={1} />
          <Box flex={1} minWidth={0}>
            <NcrField
              rootName={companyRootName}
              name="purchase_number"
              label="Purchase order"
            />
            <NcrField
              rootName={companyRootName}
              name="contact"
              label="Contact"
            />
          </Box>
        </AccordionDetails>
      </NcrAccordion>
      <NcrAccordion isFilled={itemIsFilled}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ flex: 1 }}>Item</Typography>
          <StatusChip
            filled={itemIsFilled}
            error={itemHasErrors}
            sx={{ mr: 1 }}
          />
        </AccordionSummary>
        <AccordionDetails style={accordionStyle}>
          <Box flex={1}>
            <Box display="flex" alignItems="center" height={20}>
              <FormLabel>Item</FormLabel>
            </Box>
            <NcrField
              rootName={itemRootName}
              name="item_number"
              label="Item number"
            />
            <NcrField
              rootName={itemRootName}
              name="description"
              label="Description"
            />
            <NcrField
              rootName={itemRootName}
              name="serial_number"
              label="Serial number"
            />
            <NcrField
              rootName={itemRootName}
              name="drawing_number"
              label="Drawing number"
            />
          </Box>
          <Box mx={1} />
          <Box flex={1}>
            <Box display="flex" alignItems="center" height={20}>
              <FormLabel style={{ flex: 1 }}>Assembly</FormLabel>

              <Tooltip
                title="Fill if item is part of an assembly"
                arrow
                placement="left"
              >
                <InfoOutlinedIcon color="action" fontSize="small" />
              </Tooltip>
            </Box>
            <NcrField
              rootName={itemRootName}
              name="item_number_assy"
              label="Item number (Assembly)"
            />
            <NcrField
              rootName={itemRootName}
              name="description_assy"
              label="Description (Assembly)"
            />
            <NcrField
              rootName={itemRootName}
              name="serial_number_assy"
              label="Serial number (Assembly)"
            />
            <NcrField
              rootName={itemRootName}
              name="drawing_number_assy"
              label="Drawing number (Assembly)"
            />
          </Box>
        </AccordionDetails>
      </NcrAccordion>
      <NcrAccordion isFilled={customerIsFilled}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ flex: 1 }}>Customer reference</Typography>

          <StatusChip
            filled={customerIsFilled}
            error={customerHasErrors}
            sx={{ mr: 1 }}
          />
        </AccordionSummary>
        <AccordionDetails style={accordionStyle}>
          <Box flex={1}>
            <NcrField
              rootName={customerRootName}
              name="customer_number"
              label="Customer number"
            />
            <NcrField
              rootName={customerRootName}
              name="name"
              label="Customer name"
            />
            <NcrField
              rootName={customerRootName}
              name="job_purchase_number"
              label="Job purchase number"
            />
            <NcrField
              rootName={customerRootName}
              name="job_name"
              label="Job name"
            />
            <NcrField
              rootName={customerRootName}
              name="customer_item_number"
              label="Customer item number"
            />
          </Box>
          <Box mx={1} />
          <Box flex={1}>
            <NcrField
              rootName={customerRootName}
              name="customer_item_number_assy"
              label="Customer item number (Assembly)"
            />
            <NcrField
              rootName={customerRootName}
              name="job_contact"
              label="Contact"
            />
            <NcrField
              rootName={customerRootName}
              name="job_contact_position"
              label="Contact position"
            />
            <NcrField
              rootName={customerRootName}
              name="reference"
              label="Reference"
            />
          </Box>
        </AccordionDetails>
      </NcrAccordion>
      <NcrAccordion isFilled={vendorIsFilled}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography style={{ flex: 1 }}>Vendor</Typography>
          <StatusChip
            filled={vendorIsFilled}
            error={vendorHasError}
            sx={{ mr: 1 }}
          />
        </AccordionSummary>
        <AccordionDetails style={accordionStyle}>
          <Box flex={1}>
            <NcrField
              rootName={vendorRootName}
              name="vendor_number"
              label="Number"
            />
          </Box>
          <Box mx={1} />
          <Box flex={1}>
            <NcrField
              rootName={vendorRootName}
              name="vendor_name"
              label="Name"
            />
          </Box>
        </AccordionDetails>
      </NcrAccordion>
    </>
  );
};

export default FormOne;
