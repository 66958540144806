import PropTypes from "prop-types";
import {
  Autocomplete,
  CircularProgress,
  ListItemText,
  TextField,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import useDebounce from "../hooks/useDebounce";

const NewSelectAutocomplete = ({
  label,
  value,
  onChange,
  disabled,
  variant,
  size,
  optionsCallBack,
  extraOptions,
  required,
  autoFocus = false,
  error,
  helperText,
}) => {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedTerm = useDebounce(searchTerm, 200);

  const loadData = useCallback(() => {
    setLoading(true);
    optionsCallBack(debouncedTerm)
      .then(({ results }) => {
        setOptions(results);
      })
      .catch((e) => {
        setOptions((previousOptions) => previousOptions);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [optionsCallBack, debouncedTerm]);

  useEffect(() => {
    if (!disabled) loadData();
  }, [loadData, disabled]);

  const selectOptions = extraOptions ? [...extraOptions, ...options] : options;

  return (
    <Autocomplete
      fullWidth
      autoHighlight
      autoSelect
      value={value}
      onChange={(e, value, reason) => {
        onChange(value, reason);
        setSearchTerm(value?.value || "");
      }}
      onInputChange={(e, term) => {
        setSearchTerm(term);
      }}
      getOptionLabel={(option) => option?.text || option?.value || ""}
      isOptionEqualToValue={(option, selected) =>
        option?.value === selected.value
      }
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <ListItemText primary={option?.value} secondary={option?.text} />
        </li>
      )}
      options={selectOptions}
      loading={loading}
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          error={error}
          helperText={helperText}
          label={label}
          size={size}
          autoFocus={autoFocus}
          required={required}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="primary" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

NewSelectAutocomplete.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  optionsCallBack: PropTypes.func,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
  size: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    text: PropTypes.string,
  }),
  variant: PropTypes.string,
};

NewSelectAutocomplete.defaultProps = {
  variant: "outlined",
};

export default NewSelectAutocomplete;
