const momentLocale = (value) => {
  let testingValue = value || navigator.language;
  switch (testingValue) {
    case "no":
    case "nb-NO":
    case "no-NO":
    case "nn-NO":
      return "nb";
    case "pt":
    case "pt-BR":
      return "pt-br";
    default:
      return testingValue;
  }
};
export default momentLocale;
