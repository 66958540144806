import React from "react";
import { CircularProgress, TextField } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import useDebounce from "@/hooks/useDebounce";

import { ColumnDefinition, SelectOptionDefinition } from "@/types";

interface AutocompleteMultipleRemoteProps {
  column: ColumnDefinition;
  initialValue: SelectOptionDefinition[] | null;
  onChange: React.Dispatch<SelectOptionDefinition[] | null>;
  disabled?: boolean;
  variant?: "filled" | "outlined" | "standard";
  size?: "small" | "medium" | undefined;
  initialOptions?: SelectOptionDefinition[];
}

const AutocompleteMultipleRemote = ({
  column,
  initialValue,
  onChange,
  disabled,
  variant,
  size,
  initialOptions = [],
}: AutocompleteMultipleRemoteProps) => {
  const [options, setOptions] = useState<SelectOptionDefinition[]>(
    column.filterOptionsCallback ? [] : initialOptions,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [term, setTerm] = useState<string>("");
  const [value, setValue] = useState<SelectOptionDefinition[]>(
    initialValue ? initialValue : [],
  );
  const debouncedTerm: string = useDebounce(term, 500);

  const loadData = useCallback(() => {
    if (column.filterOptionsCallback) {
      setLoading(true);
      column
        .filterOptionsCallback(debouncedTerm)
        .then(({ results }) => {
          setOptions(results);
          setLoading(false);
        })
        .catch(() => {
          setOptions((previousOptions) => previousOptions);
          setLoading(false);
        });
    }
  }, [column, debouncedTerm]);

  useEffect(() => {
    if (!disabled && debouncedTerm) loadData();
  }, [loadData, disabled, debouncedTerm]);

  return (
    <Autocomplete
      multiple
      fullWidth
      autoHighlight
      autoSelect
      value={value}
      onChange={(_, v) => {
        setValue(v);
        onChange(v);
      }}
      onInputChange={(e, term) => {
        const target = e?.target as HTMLTextAreaElement | undefined;
        if (term === target?.value) setTerm(term);
      }}
      getOptionLabel={(option) =>
        option?.text.toString() || option?.value.toString() || ""
      }
      isOptionEqualToValue={(option, selectedValue) =>
        option?.value === selectedValue.value
      }
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          label={column.name}
          size={size}
          autoFocus
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  params.InputProps.endAdornment
                )}
              </>
            ),
          }}
        />
      )}
      onOpen={loadData}
      disabled={disabled}
    />
  );
};

AutocompleteMultipleRemote.defaultProps = {
  initialValue: null,
  variant: "outlined",
};

export default AutocompleteMultipleRemote;
