import PropTypes from "prop-types";
import { useState, forwardRef, useEffect } from "react";
import TabPanel from "./common/TabPanel";
import FormOne from "./one-d/FormOne";
import FormTwo from "./two-d/FormTwo";
import SaveButton from "./common/SaveButton";
import { Tabs, Dialog, LinearProgress, Slide } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Description from "./description/DescriptionForm";

import { Form, Formik } from "formik";
import validationSchema from "./validationSchema";
import initialValues from "./initialValues";

import { useTheme } from "@mui/styles";
import NcrHeader, { NcrHeaderLoadingTemplate } from "./common/NcrHeader";
import NcrTab from "./common/NcrTab";
import { ncrDataType } from "./common/ncrPropTypes";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  root: {
    justifyContent: "center",
    borderBottom: "1px solid #e8e8e8",
  },
  scroller: {
    flexGrow: "0",
  },
});

const matchErrorsToIndex = (errors, currentIndex) => {
  if (errors.title && currentIndex === 0) return true;
  if (errors.one_d && currentIndex === 1) return true;
  if (errors.two_d && currentIndex === 2) return true;
  else return false;
};

//TODO: Update function as we add more pages
const calculateIndex = (data) => {
  const descriptionFilled = Boolean(data.title);
  const oneDfilled = Boolean(
    Object.values(data?.one_d?.company ?? {}).some(Boolean) ||
      Object.values(data?.one_d?.item ?? {}).some(Boolean) ||
      Object.values(data?.one_d?.customer ?? {}).some(Boolean) ||
      Object.values(data?.one_d?.vendor ?? {}).some(Boolean),
  );

  if (!descriptionFilled && !oneDfilled) return 0;
  if (descriptionFilled && !oneDfilled) return 1;
  if (descriptionFilled && oneDfilled) return 2;
  else return 0;
};

const NcrForm = ({
  open,
  onClose,
  onDelete,
  loading,
  data,
  onSubmit,
  debug,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [index, setIndex] = useState(0);
  const [isDragActive, setIsDragActive] = useState(false);

  const errorColor = theme.palette.error.main;

  const { id, ncr_reference, created_at } = data;

  useEffect(() => {
    if (!open) {
      setIndex(0);
    }
  }, [open]);

  useEffect(() => {
    setIndex(calculateIndex(data));
  }, [data]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen
        TransitionComponent={Transition}
        onDragOver={() => setIsDragActive(true)}
        onDrop={() => setIsDragActive(false)}
        onDragLeave={() => setIsDragActive(false)}
      >
        {loading ? (
          <>
            <NcrHeaderLoadingTemplate />
            <TabPanel loading />
          </>
        ) : (
          <Formik
            validateOnBlur={false}
            validationSchema={validationSchema}
            initialValues={initialValues(data)}
            onSubmit={(values, { setSubmitting }) => {
              const valuesToSubmit = {
                ...values,
                attachments: values?.attachments.map((file) => {
                  if (file instanceof File) return file;
                  else return undefined;
                }),
                //Send comments in chronological order for create / update
                comments: values?.comments?.reverse(),
              };
              onSubmit(valuesToSubmit);
              setTimeout(() => {
                setSubmitting(false);
              }, 1000);
            }}
          >
            {({ handleSubmit, isSubmitting, errors, values, status }) => {
              const formHasErrors = Object.keys(errors).length > 0;

              const descriptionHasErrors = Boolean(
                errors.title ||
                  errors.ncr_type ||
                  errors.level ||
                  errors.registered_by ||
                  errors.owner,
              );

              const descriptionIsFilled = Boolean(
                values.title &&
                  values.ncr_type &&
                  values.level &&
                  values.registered_by &&
                  values.owner,
              );

              const oneDisFilled = Boolean(
                Object.values(values.one_d.company).some(Boolean) ||
                  Object.values(values.one_d.item).some(Boolean) ||
                  Object.values(values.one_d.customer).some(Boolean) ||
                  Object.values(values.one_d.vendor).some(Boolean),
              );

              const oneDHasErrors = Boolean(errors.one_d);

              const twoDHasErrors = Boolean(errors?.two_d);

              const { temporary_action_required, ...twoDValuesToCheck } =
                values?.two_d;

              const twoDisFilled = Object.values(twoDValuesToCheck ?? {}).some(
                Boolean,
              );

              return (
                <Form onSubmit={onSubmit} id="ncr" noValidate>
                  {(loading || isSubmitting) && <LinearProgress />}
                  <SaveButton
                    onClick={handleSubmit}
                    disabled={isSubmitting || formHasErrors}
                  />
                  <NcrHeader
                    createdAt={created_at ?? new Date().toISOString()}
                    ncrNumber={ncr_reference}
                    onClose={onClose}
                    onDelete={() => onDelete(id)}
                    loading={loading}
                    isDragActive={isDragActive}
                  />
                  <div>
                    <Tabs
                      variant="scrollable"
                      value={index}
                      onChange={(e, val) => setIndex(val)}
                      scrollButtons="auto"
                      indicatorColor="primary"
                      textColor="primary"
                      classes={{
                        root: classes.root,
                        scroller: classes.scroller,
                      }}
                      TabIndicatorProps={{
                        style: {
                          background: matchErrorsToIndex(errors, index)
                            ? errorColor
                            : null,
                        },
                      }}
                    >
                      <NcrTab
                        label="Description"
                        index={0}
                        currentIndex={index}
                        error={descriptionHasErrors}
                        filled={descriptionIsFilled}
                      />

                      <NcrTab
                        label="1D"
                        index={1}
                        currentIndex={index}
                        error={oneDHasErrors}
                        filled={oneDisFilled}
                      />

                      <NcrTab
                        label="2D"
                        index={2}
                        currentIndex={index}
                        error={twoDHasErrors}
                        filled={twoDisFilled}
                      />

                      <NcrTab
                        label="3D"
                        index={3}
                        currentIndex={index}
                        disabled
                      />

                      <NcrTab
                        label="5D"
                        index={4}
                        currentIndex={index}
                        disabled
                      />

                      <NcrTab
                        label="6D"
                        index={5}
                        currentIndex={index}
                        disabled
                      />

                      <NcrTab
                        label="7D"
                        index={6}
                        currentIndex={index}
                        disabled
                      />

                      <NcrTab
                        label="8D"
                        index={7}
                        currentIndex={index}
                        disabled
                      />
                    </Tabs>
                  </div>

                  <TabPanel currentIndex={index} index={0} loading={loading}>
                    <Description data={data} />
                  </TabPanel>
                  <TabPanel currentIndex={index} index={1} loading={loading}>
                    <FormOne />
                  </TabPanel>
                  <TabPanel currentIndex={index} index={2} loading={loading}>
                    <FormTwo />
                  </TabPanel>
                  {debug && (
                    <pre>
                      <code>
                        {JSON.stringify(
                          { values, data, errors, status },
                          null,
                          "\t",
                        )}
                      </code>
                    </pre>
                  )}
                </Form>
              );
            }}
          </Formik>
        )}
      </Dialog>
    </>
  );
};

NcrForm.propTypes = {
  data: ncrDataType,
  debug: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
};

export default NcrForm;
