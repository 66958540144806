import PropTypes from "prop-types";
import React from "react";
import { Box, Chip, Grid, Skeleton } from "@mui/material";
import TurnedInNotIcon from "@mui/icons-material/TurnedInNot";
import EditLocationOutlinedIcon from "@mui/icons-material/EditLocationOutlined";
import InfoIcon from "@mui/icons-material/Info";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import ReceiptIcon from "@mui/icons-material/Receipt";

const TrackingInfo = ({ data, loading }) => {
  return (
    <Box m={2} textAlign="center">
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs="auto">
          {loading ? (
            <Skeleton width={180} height={24} />
          ) : (
            <Chip
              size="small"
              icon={<TurnedInNotIcon />}
              label={`Item number: ${data?.item_number}`}
            />
          )}
        </Grid>
        <Grid item xs="auto">
          {loading ? (
            <Skeleton width={180} height={24} />
          ) : (
            <Chip
              size="small"
              icon={<EditLocationOutlinedIcon />}
              label={`Tracking: ${
                data?.tracking === "serial_number"
                  ? "Serial number"
                  : data?.tracking === "lot_number"
                    ? "Lot number"
                    : "Error"
              }`}
            />
          )}
        </Grid>
        {data?.document_no && (
          <Grid item xs="auto">
            <Chip
              size="small"
              icon={<ReceiptIcon />}
              label={`Order number: ${data.document_no}`}
            />
          </Grid>
        )}
        {data?.line_no && (
          <Grid item xs="auto">
            <Chip
              size="small"
              icon={<ViewHeadlineIcon />}
              label={`Line number: ${data.line_no}`}
            />
          </Grid>
        )}
        {data?.outstanding_quantity && (
          <Grid item xs="auto">
            <Chip
              size="small"
              icon={<InfoIcon />}
              label={`Quantity: ${data.outstanding_quantity}`}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

TrackingInfo.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.any,
};
export default TrackingInfo;
