import MaterialDataTable from "@/components/datatable/MaterialDataTable";
import useTable from "@/components/datatable/useTable";
import { ColumnDefinition, RowDefinition } from "@/types";
import { isoStringToMonthYear } from "@/lib/isoStringToMonthYear";

const columns = [
  {
    name: "Month / Year",
    key: "date",
    disableFilter: true,
    renderCellFn: (isoString: string) => (
      <div>{isoStringToMonthYear(isoString)?.toUpperCase()}</div>
    ),
  },
  {
    name: "Weld length",
    key: "weld_length",
    renderCellFn: (value: string) => <div>{value} mm</div>,
  },
  {
    name: "Weld defect",
    key: "weld_defect",
    renderCellFn: (value: string) => <div>{value} mm</div>,
  },
  {
    name: "Weld defect rate",
    key: "rate",
    renderCellFn: (value: string) => <div>{value} %</div>,
  },
] as ColumnDefinition[];

const WeldDefectsTable = ({ rows = [] }: { rows: RowDefinition[] }) => {
  const { state, handlers } = useTable({
    options: { multiSort: false, mode: "client" },
    columns: columns,
    initialRowsPerPage: 100,
    initialRows: rows,
    initialSort: { date: "desc" },
  });

  const { handleApplyFilter, handleRemoveFilter, handleToggleSort } = handlers;

  return (
    <MaterialDataTable
      TableProps={{ size: "small" }}
      TableContainerProps={{}}
      onApplyFilter={handleApplyFilter}
      onRemoveFilter={handleRemoveFilter}
      enableFiltering
      onToggleSort={(e: any, column: ColumnDefinition) =>
        handleToggleSort(column.key)
      }
      {...state}
    />
  );
};

export default WeldDefectsTable;
