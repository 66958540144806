//DUCKS pattern
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface AppCommonState {
  isLoadingData: boolean;
  title: string;
}

const initialState: AppCommonState = {
  isLoadingData: false,
  title: "VTS",
};

const appCommonSlide = createSlice({
  name: "app-common",
  initialState,
  reducers: {
    onChangeLoadingState: (state, action: PayloadAction<boolean>) => {
      state.isLoadingData = action.payload;
      return state;
    },
    onChangeTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
      return state;
    },
  },
});

export const { onChangeLoadingState, onChangeTitle } = appCommonSlide.actions;
export default appCommonSlide.reducer;
