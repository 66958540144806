import * as Yup from "yup";

const commentsSchema = () =>
  Yup.array()
    .of(
      Yup.object()
        .shape({
          text: Yup.string().required("Comment text is missing"),
          user: Yup.string().required("User is missing"),
          created_at: Yup.string(),
          updated_at: Yup.string(),
          id: Yup.string(),
          _destroy: Yup.bool(),
        })
        .nullable(),
    )
    .nullable();

const validationSchema = Yup.object().shape({
  title: Yup.string().required("Field is required"),
  ncr_type: Yup.string()
    .required("Field is required")
    .oneOf(
      [
        "hms",
        "deviation",
        "complaint",
        "deviation_permit",
        "technical_clarification",
      ],
      "Invalid option selected",
    ),
  level: Yup.string()
    .required("Field is required")
    .test(
      "invalidType&LevelCombination",
      "This level cannot be selected with the current type",
      function (level) {
        const ncr_type = this.parent.ncr_type;
        if (ncr_type === "complaint" && level === "registration") {
          return false;
        }
        if (ncr_type === "deviation_permit") {
          if (["action", "critical"].includes(level)) {
            return false;
          }
        }
        if (ncr_type === "technical_clarification") {
          if (["action", "critical"].includes(level)) {
            return false;
          }
        }

        return true;
      },
    ),
  registered_by: Yup.string().required("Field is required"),
  owner: Yup.string().required("Field is required").nullable(),
  comments: commentsSchema(),
  attachments: Yup.array(),
  one_d: Yup.object().shape({
    company: Yup.object().shape({
      production_number: Yup.string()
        .matches(
          /^[Pp Ww][0-9]{5}$/,
          "Must start with letters `W` or `P`, followed by 5 numbers",
        )
        .nullable(),
      operation_details: Yup.string().nullable(),
      job_number: Yup.string().nullable(),
      job_description: Yup.string().nullable(),
      purchase_number: Yup.string().nullable(),
      contact: Yup.string(),
    }),
    item: Yup.object().shape({
      item_number: Yup.string().nullable(),
      description: Yup.string().nullable(),
      serial_number: Yup.string().nullable(),
      drawing_number: Yup.string().nullable(),
      item_number_assy: Yup.string().nullable(),
      description_assy: Yup.string().nullable(),
      serial_number_assy: Yup.string()
        .nullable()
        .max(20, "Must be 20 characters or less"),
      drawing_number_assy: Yup.string().nullable(),
    }),
    customer: Yup.object().shape({
      customer_number: Yup.string().nullable(),
      name: Yup.string().nullable(),
      job_purchase_number: Yup.string().nullable(),
      job_name: Yup.string().nullable(),
      customer_item_number: Yup.string().nullable(),
      customer_item_number_assy: Yup.string().nullable(),
      job_contact: Yup.string().nullable(),
      job_contact_position: Yup.string().nullable(),
      reference: Yup.string().nullable(),
    }),
    vendor: Yup.object().shape({
      vendor_number: Yup.string().nullable(),
      vendor_name: Yup.string().nullable(),
    }),
  }),
  two_d: Yup.object().shape({
    machine_number: Yup.string(),
    specification: Yup.number(),
    wps_number: Yup.string(),
    tolerance: Yup.number(),
    measured_value: Yup.number(),
    severity: Yup.string(),
    temporary_action_required: Yup.boolean(),
    event_reason2: Yup.number().nullable(),
    event_reason3: Yup.number().nullable(),
    event_location: Yup.number().nullable(),
    comments: commentsSchema(),
  }),
});

export default validationSchema;
