import { LinearProgress, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import getRoutingStatusColor from "@/utils/getRoutingStatusColor";

const useProgressStyle = makeStyles(() => ({
  finished: {
    backgroundColor: getRoutingStatusColor("finished")?.bg,
  },
  inProgress: {
    backgroundColor: getRoutingStatusColor("in_progress")?.bg,
  },
  colorPrimary: {
    backgroundColor: "lightgray",
  },
}));

const ProgressCell = ({ routing }) => {
  const classes = useProgressStyle();
  const completed = routing.filter(
    ({ status }) => status === "finished" || status === "out_of_sync",
  )?.length;
  const total = routing?.length;
  const isFinished = completed === total;
  const progress = (completed * 100) / total;

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div style={{ width: "100%" }}>
        <LinearProgress
          variant="determinate"
          value={progress}
          classes={{
            colorPrimary: classes.colorPrimary,
            bar: isFinished ? classes.finished : classes.inProgress,
          }}
        />
      </div>
      <div style={{ marginTop: "5px" }}>
        <Typography variant="body2" color="textSecondary">
          {completed + " / " + total}
        </Typography>
      </div>
    </div>
  );
};
export default ProgressCell;
