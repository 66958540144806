const getUserLang = (dateTime) => {
  if (dateTime) {
    switch (navigator.language) {
      case "pt-BR":
        return { inputFormat: "DD/MM/YYYY HH:mm", mask: "__/__/____ __:__" };
      case "nb":
        return { inputFormat: "DD.MM.YYYY HH:mm", mask: "__.__.____ __:__" };
      case "us":
        return { inputFormat: "MM/DD/YYYY HH:mm", mask: "__/__/____ __:__" };
      default:
        return { inputFormat: "MM/DD/YYYY HH:mm", mask: "__/__/____ __:__" };
    }
  } else {
    switch (navigator.language) {
      case "pt-BR":
        return { inputFormat: "DD/MM/YYYY", mask: "__/__/____" };
      case "nb":
        return { inputFormat: "DD.MM.YYYY", mask: "__.__.____" };
      case "us":
        return { inputFormat: "MM/DD/YYYY", mask: "__/__/____" };
      default:
        return { inputFormat: "MM/DD/YYYY", mask: "__/__/____" };
    }
  }
};

export default getUserLang;
