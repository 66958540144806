import {
  Fade,
  TableHead,
  TableHeadProps,
  TableRow,
  TableRowProps,
} from "@mui/material";
import React, { useRef } from "react";
import { TableCell, LinearProgress, LinearProgressProps } from "@mui/material";
import MaterialTableHeaderCell from "./MaterialTableHeaderCell";
import {
  ColumnDefinition,
  ToggleSortCallback,
  ToggleFilterCallback,
  FiltersDefinition,
  SortParamsDefinition,
} from "@/types";

interface MaterialTableHeaderProps {
  columns: ColumnDefinition[];
  onToggleSort?: ToggleSortCallback;
  onOpenFilterDialog?: ToggleFilterCallback;
  filters: FiltersDefinition;
  loading: boolean;
  sorting: SortParamsDefinition;
  TableHeadProps?: TableHeadProps;
  LinearProgressProps?: LinearProgressProps;
  TableHeadRowProps?: TableRowProps;
  allowedSortKeys?: string[];
  allowedFilterKeys?: string[];
}

function MaterialTableHeader({
  loading,
  columns,
  onToggleSort,
  onOpenFilterDialog,
  filters,
  sorting,
  TableHeadProps,
  LinearProgressProps,
  TableHeadRowProps,
  allowedSortKeys,
  allowedFilterKeys,
}: MaterialTableHeaderProps) {
  const rowRef = useRef<HTMLTableRowElement>(null);
  return (
    <>
      <TableHead {...TableHeadProps}>
        <TableRow ref={rowRef} {...TableHeadRowProps}>
          {columns.map((column: ColumnDefinition, columnIndex: number) => {
            const filterApplied = Boolean(filters && filters[column.key]);

            const sortDirection =
              sorting && sorting[column.key] ? sorting[column.key] : "";

            if (column.hide) return null;

            return (
              <MaterialTableHeaderCell
                key={columnIndex}
                column={column}
                onToggleSort={onToggleSort}
                onOpenFilterDialog={onOpenFilterDialog}
                filterApplied={filterApplied}
                sortDirection={sortDirection}
                allowedSortKeys={allowedSortKeys}
                allowedFilterKeys={allowedFilterKeys}
              />
            );
          })}
        </TableRow>
      </TableHead>
      {loading && (
        <Fade in timeout={200}>
          <TableHead>
            <TableRow>
              <TableCell
                colSpan={columns?.length ?? 999}
                style={{
                  position: "sticky",
                  padding: 0,
                  top: rowRef?.current?.clientHeight,
                  overflow: "hidden",
                  zIndex: 999,
                  border: "0px",
                }}
              >
                <LinearProgress color="primary" {...LinearProgressProps} />
              </TableCell>
            </TableRow>
          </TableHead>
        </Fade>
      )}
    </>
  );
}

export default MaterialTableHeader;
