import { useState, useCallback } from "react";

const useFilterState = (initialFilters: object = {}) => {
  const [filters, setFilters] = useState(initialFilters);

  const setFilterValue = useCallback((key: string, value: number) => {
    setFilters((previousFilters: object) => ({
      ...previousFilters,
      [key]: value,
    }));
  }, []);

  const handleReset = useCallback(() => {
    setFilters(initialFilters);
  }, [initialFilters]);

  const handleClear = useCallback((overrideFilter: object) => {
    setFilters(overrideFilter ?? {});
  }, []);

  return { filters, setFilters, setFilterValue, handleReset, handleClear };
};

export default useFilterState;
