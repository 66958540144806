import authHeaders from "./authHeaders";
import fetchUrl from "./fetchUrl";

function searchNewSelect(field, params) {
  return (searchTerm) =>
    fetch(
      fetchUrl(
        `/v1/new_select/${field}`,
        {
          ...params,
          term: searchTerm,
        },
        import.meta.env.VITE_VTS_API,
      ),
      { headers: authHeaders() },
    ).then((r) => {
      if (!r.ok) {
        throw r;
      } else {
        return r.json();
      }
    });
}

export default searchNewSelect;
