import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { AddLink, Cancel, Done } from "@mui/icons-material";
import fetchUrl from "../../../lib/fetchUrl";
import authHeaders from "../../../lib/authHeaders";

const AddItemsToDocument = ({
  document,
  project,
  shouldLinkAll,
  setShouldLinkAll,
  updateLinkedItems,
}) => {
  const {
    document_id: documentId,
    items: documentItems,
    unlinked_items: unlinkedItems,
  } = document;
  const [status, setStatus] = useState(0);

  const buildParams = () => {
    let params = { item_number: documentItems };
    if (project) {
      params = { ...params, project: [project], active: [project] };
    }
    return params ?? {};
  };

  const updateItems = () => {
    document.linked_items.push(...unlinkedItems);
    document.unlinked_items = [];
  };

  const updateDocument = () => {
    setStatus(1);
    fetch(
      fetchUrl(
        `${import.meta.env.VITE_VTS_API}/v1/documents/${documentId}/add_tags/`,
        buildParams(),
      ),
      {
        headers: authHeaders(),
        method: "PUT",
      },
    )
      .then((r) => r.json())
      .then((r) => {
        if ((r.status >= 400 && r.status <= 600) || r.status === "Error") {
          alert(`Error: ${r.message}`);
          setStatus(3);
        } else {
          updateItems();
          setStatus(2);
        }
      })
      .catch((e) => {
        alert("Error");
        setStatus(3);
      });
  };

  useEffect(() => {
    if (shouldLinkAll && unlinkedItems?.length !== 0) {
      updateDocument();
      setShouldLinkAll(false);
    }
  }, [shouldLinkAll]);

  useEffect(() => {
    if (status === 2) {
      updateLinkedItems(document.document_id, document.linked_items);
    }
  }, [status]);

  if (status === 2 || unlinkedItems?.length === 0) {
    return <Done style={{ color: "#228B22" }} />;
  }

  if (status === 3) {
    return <Cancel style={{ color: "#d32f2f" }} />;
  }

  return (
    <IconButton onClick={updateDocument} disabled={status !== 0} size="small">
      <Tooltip title="Link unlinked items">
        {status === 0 && <AddLink />}
      </Tooltip>
      {status === 1 && <CircularProgress size={14} />}
    </IconButton>
  );
};

AddItemsToDocument.propTypes = {
  document: PropTypes.object.isRequired,
  project: PropTypes.string,
  shouldLinkAll: PropTypes.bool,
  setShouldLinkAll: PropTypes.func,
  updateLinkedItems: PropTypes.func,
};

export default AddItemsToDocument;
