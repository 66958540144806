import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import PictureAsPdfOutlinedIcon from "@mui/icons-material/PictureAsPdfOutlined";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import VideoLibraryOutlinedIcon from "@mui/icons-material/VideoLibraryOutlined";

const FileTypeIcon = ({ type }) => {
  if (type.includes("/pdf")) return <PictureAsPdfOutlinedIcon />;
  if (type.includes("image/")) return <ImageOutlinedIcon />;
  if (type.includes("video/")) return <VideoLibraryOutlinedIcon />;
  return <InsertDriveFileOutlinedIcon />;
};

export default FileTypeIcon;
