import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  TextField,
  Chip,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Box,
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import {
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Area,
  Bar,
  CartesianGrid,
  ResponsiveContainer,
  Line,
} from "recharts";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import authHeaders from "@/lib/authHeaders";
import fetchUrl from "@/lib/fetchUrl";
import AppBarButtonWrapper from "@/components/AppBarButtonWrapper";

const WorkCenterLoad = ({ machineGroups }) => {
  const [selectedWorkCenters, setSelectedWorkCenters] = useState(
    machineGroups[0][1],
  );
  const [loading, setLoading] = useState(false);
  const [startDate, setstartDate] = useState(moment());
  const [endDate, setEndDate] = useState(moment().add(5, "month"));
  const [data, setData] = useState([]);
  const [machineCenters, setMachineCenters] = useState([]);

  const [dateErrors, setDateErrors] = useState({
    startDate: null,
    endDate: null,
  });

  const datesAreValid =
    moment(startDate, "strict").isValid() &&
    moment(endDate, "strict").isValid() &&
    !dateErrors.startDate &&
    !dateErrors.endDate;

  const fetchMachineCenters = () =>
    fetch(`${import.meta.env.VITE_VTS_API}/v1/production/machine_centers`, {
      headers: authHeaders(),
    })
      .then((r) => r.json())
      .then((data) =>
        setMachineCenters(data.results.map((value) => [value.text, value.id])),
      );

  const fetchData = () => {
    if (selectedWorkCenters?.length > 0 && datesAreValid) {
      setLoading(true);
      fetch(
        fetchUrl(
          `${import.meta.env.VITE_VTS_API}/v1/production/work_center_load`,
          {
            start_date: startDate.toISOString(),
            end_date: endDate.toISOString(),
            work_centers: selectedWorkCenters,
          },
        ),
        {
          headers: authHeaders(),
        },
      )
        .then((d) => d.json())
        .then((r) => {
          setData(
            r?.data?.map((v) => ({
              ...v,
              date: moment(v.date).format("MMM YY"),
            })),
          );
          setLoading(false);
        });
    } else {
      setData();
    }
  };

  useEffect(fetchData, [
    datesAreValid,
    startDate,
    endDate,
    selectedWorkCenters,
  ]);
  useEffect(fetchMachineCenters, []);

  return (
    <>
      <AppBarButtonWrapper showSpinner={loading} />
      <Box mx={{ lg: 8, xs: 2 }}>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item xs={12} md={6} lg={2}>
            <FormControl fullWidth>
              <InputLabel id="label">Groups</InputLabel>
              <Select
                labelId="label"
                onChange={(e) => {
                  setSelectedWorkCenters(e.target.value);
                }}
                value={selectedWorkCenters}
                size="small"
                label="Groups"
              >
                {machineGroups.map(([group, value], i) => (
                  <MenuItem key={i + "work_center_load"} value={value}>
                    {group}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6} lg={2}>
            <Autocomplete
              multiple
              disableCloseOnSelect
              value={selectedWorkCenters}
              options={machineCenters}
              isOptionEqualToValue={(option, value) => option[1] === value}
              getOptionLabel={(option) => option[0]}
              renderInput={(params) => (
                <TextField {...params} label="Machine Centers" size="small" />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={Array.isArray(option) ? option[1] : option}
                    size="small"
                    {...getTagProps({ index })}
                  />
                ))
              }
              onChange={(e, value) =>
                setSelectedWorkCenters(
                  value.map((e) => (Array.isArray(e) ? e[1] : e)),
                )
              }
            />
          </Grid>
          <Grid item xs={6} lg={2}>
            <DatePicker
              label="Start Date"
              value={startDate}
              onChange={(date) => setstartDate(date)}
              views={["year", "month"]}
              onError={(err) =>
                setDateErrors((prev) => ({ ...prev, startDate: err }))
              }
              renderInput={(params) => (
                <TextField {...params} fullWidth size="small" />
              )}
            />
          </Grid>
          <Grid item xs={6} lg={2}>
            <DatePicker
              label="End Date"
              value={endDate}
              onChange={(date) => setEndDate(date)}
              views={["year", "month"]}
              minDate={startDate}
              onError={(err) =>
                setDateErrors((prev) => ({ ...prev, endDate: err }))
              }
              renderInput={(params) => (
                <TextField {...params} fullWidth size="small" />
              )}
            />
          </Grid>
        </Grid>
      </Box>
      <Box pt={3}>
        <ResponsiveContainer height={500} width="100%">
          <ComposedChart data={data} barSize={20}>
            <CartesianGrid strokeDasharray="3 3" />

            <YAxis unit="h" />
            <YAxis dataKey="label" orientation="center" />
            <YAxis yAxisId="right" orientation="right" />
            <XAxis dataKey="date" padding={{ left: 40, right: 40 }} />

            <Tooltip />
            <Legend />
            <Area
              type="linear"
              dataKey="Capacity"
              fill="red"
              stroke="none"
              stackId="c"
            />
            <Area
              type="linear"
              dataKey="Overtime"
              fill="orange"
              stroke="none"
              stackId="c"
            />
            <Bar dataKey="Released" fill="lightgreen" stackId="a" />
            <Bar dataKey="Planned" fill="lightblue" stackId="a" />
            <Bar dataKey="Firm Planned" fill="deepskyblue" stackId="a" />
            <Bar dataKey="Simulated" fill="silver" stackId="a" />
            <Line
              type="linear"
              dataKey="Number of Operators"
              stroke="green"
              yAxisId="right"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Box>
    </>
  );
};

WorkCenterLoad.defaultProps = {
  machineGroups: [
    [
      "Machining",
      [
        "MA0201",
        "MA0202",
        "MA0203",
        "MA0204",
        "MA0205",
        "MA0206",
        "MA0211",
        "MA0212",
      ],
    ],
    ["Cladding", ["WE0306", "WE0307"]],
    ["Pipe weld", ["WE0303", "WE0304", "WE0305", "WE0308", "WE0309"]],
    ["CMM", ["QC0607", "QC0608"]],
    ["Assy/Test", ["AS0401", "AS0402"]],
  ],
};

WorkCenterLoad.propTypes = {
  machineGroups: PropTypes.arrayOf(PropTypes.array).isRequired,
};

export default WorkCenterLoad;
