import React from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import fetchFile from "../lib/fetchFile";
import GetAppIcon from "@mui/icons-material/GetApp";
import fetchUrl from "../lib/fetchUrl";
import authHeaders from "../lib/authHeaders";

const DownloadFileButton = ({
  loading,
  filters,
  fileExtension,
  filename,
  urlEndpoint,
  params,
  ...props
}) => (
  <IconButton
    disabled={loading}
    onClick={() =>
      fetchFile(
        filters || params
          ? fetchUrl(urlEndpoint, {
              format: fileExtension,
              filter: filters,
              ...params,
            })
          : urlEndpoint,

        { headers: authHeaders() },
        filename,
        fileExtension,
      )
    }
    {...props}
  >
    <GetAppIcon />
  </IconButton>
);

DownloadFileButton.propTypes = {
  fileExtension: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  filters: PropTypes.object,
  params: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  urlEndpoint: PropTypes.string.isRequired,
};

export default DownloadFileButton;
