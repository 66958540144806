import React from "react";
import * as Sentry from "@sentry/react";
import { useLocation } from "react-router";
import {
  createRoutesFromChildren,
  matchRoutes,
  useNavigationType,
} from "react-router-dom";

if (import.meta.env.PROD) {
  const userAuthData = JSON.parse(localStorage.getItem("msToken"));

  // Sentry - https://docs.sentry.io/platforms/javascript/guides/react/
  Sentry.init({
    dsn: "https://69975b263d1c8a0ac7224d6df45cb92c@o4506336895500288.ingest.sentry.io/4506336902447104",
    environment: import.meta.env.VITE_RELEASE_ENV,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        ),
      }),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    initialScope: {
      user: {
        username: userAuthData?.account.name,
        email: userAuthData?.account.username,
      },
    },
  });
}
