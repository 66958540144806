import ProjectHighLight from "@/components/HighLight/ProjectHighLight";
import ProductionHighLight from "@/components/HighLight/ProductionHighLight";
import PurchaseHighLight from "@/components/HighLight/PurchaseHighLight";
import humanizeSnakeCase from "@/lib/humanizeSnakeCase";

const getNCRTableColumns = () => [
  {
    name: "Created",
    key: "created_at",
    renderCellFn: (v: any) => new Date(v).toLocaleDateString(),
    disableFilter: true,
  },
  {
    name: "NCR Identifier",
    key: "ncr_reference",
    disableFilter: true,
  },
  {
    name: "Title",
    key: "title",
    disableFilter: true,
  },
  {
    name: "NCR Owner",
    key: "owner",
  },
  {
    name: "Production Order",
    key: "production_number",
    renderCellFn: (v: any, row: any) => (
      <ProductionHighLight>{row.production_number}</ProductionHighLight>
    ),
    // renderCellFn: (v: any, row: any) => <ProductionHighLight>{row.one_d.company.production_number}</ProductionHighLight>,
  },
  {
    name: "Project Number",
    key: "job_number",
    renderCellFn: (v: any, row: any) => (
      <ProjectHighLight>{row.job_number}</ProjectHighLight>
    ),
    //renderCellFn: (v: any, row: any) => <ProjectHighLight>{row.one_d.company.job_number}</ProjectHighLight>,
  },
  {
    name: "Purchase Order",
    key: "purchase_number",
    renderCellFn: (v: any, row: any) => (
      <PurchaseHighLight>{row.purchase_number}</PurchaseHighLight>
    ),
    //renderCellFn: (v: any, row: any) => <PurchaseHighLight>{row.one_d.company.purchase_number}</PurchaseHighLight>,
  },
  {
    name: "Type",
    key: "ncr_type",
    renderCellFn: (v: any) => <span>{humanizeSnakeCase(v)}</span>,
  },
  {
    name: "Level",
    key: "level",
    renderCellFn: (v: any) => <span>{humanizeSnakeCase(v)}</span>,
  },
];
export default getNCRTableColumns;
