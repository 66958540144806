import { Box, Skeleton } from "@mui/material";
import React from "react";

const LoadingColumns = () => {
  return (
    <Box display="flex" flexDirection="column" m={2}>
      <Skeleton width="10%" height={30} />
      <Skeleton width="90%" height={40} />
      <Skeleton width="70%" height={40} />
      <Skeleton width="50%" height={40} />
      <Skeleton width="80%" height={40} />
      <Skeleton width="30%" height={40} />
      <Skeleton width="15%" height={40} />
    </Box>
  );
};

export default LoadingColumns;
