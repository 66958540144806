import React from "react";
import { Link } from "@mui/material";

const getTableComponentColumns = (navigate) => [
  {
    name: "NCR Number",
    key: "ncrNumber",
    filterType: "select",
    renderCellFn: (v) => (
      <Link
        color="inherit"
        href={`/ncr/print-ncr?ncr_number=${v}`}
        onClick={(e) => {
          e.preventDefault();
          navigate(`/ncr/print-ncr?ncr_number=${v}`);
        }}
      >
        {v}
      </Link>
    ),
  },
  {
    name: "Number of days",
    key: "numberOfDays",
  },
  {
    name: "Description",
    key: "description",
    cellPropsFn: (v) => ({
      sx: {
        width: "70%",
      },
    }),
  },
];
export default getTableComponentColumns;
