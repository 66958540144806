import React, { useState, useEffect } from "react";
import { RemoteDataTable } from "@oddadigitalsystem/datatable2";
import { useSnackbar } from "notistack";
import { pickBy } from "lodash";
import queryString from "query-string";
import { useNavigate, useLocation } from "react-router";
import {
  Fab,
  useTheme,
  Button,
  Box,
  ListItem,
  ListItemText,
  IconButton,
  SvgIcon,
} from "@mui/material";
import { mdiDolly } from "@mdi/js";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import SearchIcon from "@mui/icons-material/Search";
import queryStringSerialize from "@/utils/queryStringSerialize";
import authHeaders from "@/lib/authHeaders";
import PurchaseHighLight from "@/components/HighLight/PurchaseHighLight";
import ItemNumberHighLight from "@/components/HighLight/ItemNumberHighLight";
import prepareFilter from "@/lib/prepareFilter";
import AppBarButtonWrapper from "@/components/AppBarButtonWrapper";
import searchNewSelect from "@/lib/searchNewSelect";
import SearchPlaceholder from "@/images/search.svg?react";
import NotFoundPlaceholder from "@/images/notFound.svg?react";
import ErrorPlaceholder from "@/images/error.svg?react";
import DebouncedSearch from "@/components/DebouncedSearch";
import AppBarSpinner from "@/components/AppBarSpinner";
import FullpageTableScrollContainer from "@/components/FullpageTableScrollContainer";
import ItemReceival from "@/pages/purchase/warehouse-receival/ItemReceival";
import ItemNumberList from "@/pages/purchase/warehouse-receival/ItemNumberList";
import TrackingInfo from "@/pages/purchase/warehouse-receival/TrackingInfo";
import SelectPurchaseOrder from "@/pages/purchase/warehouse-receival/SelectPurchaseOrder";
import ItemReceivalForm from "@/pages/purchase/warehouse-receival/ItemReceivalForm";

export const ReceiveItemIcon = () => (
  <SvgIcon>
    <path d={mdiDolly} />
  </SvgIcon>
);

const PlaceholderContainer = ({ primary, secondary, children, props }) => {
  return (
    <Box mt={4} height={250} {...props}>
      {children}
      <ListItem style={{ textAlign: "center", marginTop: 20 }} disabled>
        <ListItemText primary={primary} secondary={secondary} />
      </ListItem>
    </Box>
  );
};

const WarehouseReceival = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  let location = useLocation();
  const theme = useTheme();
  const initialFilters = prepareFilter(queryString.parse(location.search));

  // table state
  const [loading, setLoading] = useState(true);
  const [tableReloadedAt, setTableReloaded] = useState(new Date().getTime());

  // Modal States
  const [activeStep, setActiveStep] = useState(0);
  const [itemNumber, setItemNumber] = useState("");

  //Dialog state
  const [open, setOpen] = useState(false);

  //Tracking info state
  const [trackingData, setTrackingData] = useState();
  const [trackingLoading, setTrackingLoading] = useState(false);

  //search state
  const [searchVal, setSearchVal] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);

  //purchaserorder state
  const [purchaseData, setPurchaseData] = useState([]);
  const [purchaseLoading, setPurchaseLoading] = useState(false);

  //form state
  const [formData, setFormData] = useState();
  const [formLoading, setFormLoading] = useState(false);

  const handleClearFilters = () => {
    setTableReloaded(new Date().getTime());
    navigate({ search: null });
  };

  const handleSubmit = async (form) => {
    for await (const r of form.receipt) {
      setFormLoading(true);
      await fetch(`${import.meta.env.VITE_VTS_API}/v1/warehouse_receipts`, {
        method: "POST",
        headers: authHeaders(),
        body: JSON.stringify(r),
      })
        .then((resp) => resp.json())
        .then((data) =>
          enqueueSnackbar(
            `Serial number: ${r?.serial_number}, Quantity: ${r?.quantity} (${data?.message})`,
            {
              variant: data?.status === "Success" ? "success" : "error",
            },
          ),
        )
        .catch((e) => enqueueSnackbar(JSON.stringify(e), { variant: "error" }));
    }
    setFormLoading(false);
    setOpen(false);
    setActiveStep(0);
    setSearchVal("");
  };

  const fetchItemNumbers = () => {
    setSearchData([]);
    if (open && searchVal) {
      setSearchLoading(true);
      fetch(
        `${
          import.meta.env.VITE_VTS_API
        }/v1/new_select/item_number?term=${searchVal}`,
        {
          headers: authHeaders(),
        },
      )
        .then((r) => {
          if (r.status !== 200) throw new Error(`${r.status} ${r.statusText}`);
          return r.json();
        })
        .then((data) => {
          setSearchLoading(false);
          setSearchData(data.results);
        })
        .catch((e) => {
          setSearchLoading(false);
        });
    }
  };

  const fetchPurchaseOrder = () => {
    if (itemNumber) {
      setPurchaseLoading(true);
      fetch(
        `${
          import.meta.env.VITE_VTS_API
        }/v1/purchase/items_to_receive?${queryStringSerialize({
          filter: {
            item_no: itemNumber,
          },
        })}`,
        {
          headers: authHeaders(),
        },
      )
        .then((r) => {
          if (r.status !== 200) throw new Error(`${r.status} ${r.statusText}`);
          return r.json();
        })
        .then((data) => {
          setPurchaseLoading(false);
          setPurchaseData(data.rows);
        })
        .catch((e) => {
          setPurchaseLoading(false);
        });
    }
  };

  const fetchTrackingInfo = () => {
    if (itemNumber) {
      setTrackingLoading(true);
      fetch(`${import.meta.env.VITE_VTS_API}/v1/items/${itemNumber}`, {
        headers: authHeaders(),
      })
        .then((r) => {
          if (r.status !== 200) throw new Error(`${r.status} ${r.statusText}`);
          return r.json();
        })
        .then((data) => {
          setTrackingLoading(false);
          setTrackingData(data);
        })
        .catch((e) => {
          setTrackingLoading(false);
        });
    }
  };

  const handleReceiveExisting = (obj) =>
    fetch(`${import.meta.env.VITE_VTS_API}/v1/items/${obj.item_no}`, {
      headers: authHeaders(),
    })
      .then((r) => {
        if (r.status !== 200) throw new Error(`${r.status} ${r.statusText}`);
        return r.json();
      })
      .then((data) => {
        if (data.tracking) {
          setTrackingData(data);
          fetch(
            `${
              import.meta.env.VITE_VTS_API
            }/v1/purchase/items_to_receive?${queryStringSerialize({
              filter: {
                item_no: obj.item_no,
                line_no: obj.line_no,
              },
            })}`,
            {
              headers: authHeaders(),
            },
          )
            .then((r) => {
              if (r.status !== 200)
                throw new Error(`${r.status} ${r.statusText}`);
              return r.json();
            })
            .then((data) => {
              setTrackingLoading(false);
              setPurchaseData(data.rows);
              setFormData(data.rows[0]);
              setOpen(true);
              setActiveStep(2);
            })
            .catch((e) => {
              console.log(e);
              setTrackingLoading(false);
            });
        } else {
          alert("This item does not have a tracking number");
          setTrackingLoading(false);
        }
      })
      .catch((e) => {
        enqueueSnackbar(e?.toString());
      });

  useEffect(fetchItemNumbers, [searchVal, open]);
  useEffect(fetchPurchaseOrder, [itemNumber]);
  useEffect(fetchTrackingInfo, [itemNumber]);

  return (
    <>
      <AppBarButtonWrapper showSpinner={loading}>
        {Object.keys(initialFilters)?.length !== 0 ? (
          <Button onClick={handleClearFilters}>Clear filter</Button>
        ) : null}
      </AppBarButtonWrapper>
      <Fab
        color="secondary"
        style={{
          position: "fixed",
          bottom: theme.spacing(2),
          right: theme.spacing(2),
          zIndex: 999,
        }}
        onClick={() => setOpen(!open)}
      >
        <SearchIcon />
      </Fab>
      <ItemReceival
        title="Receive new item"
        open={open}
        onClose={() => {
          setOpen(false);
          setActiveStep(0);
          setSearchVal("");
        }}
        activeStep={activeStep}
        steps={["Search item numbers", "Select purchase order", "Edit"]}
        extraToolbarButtons={() => {
          if (activeStep === 2 && formLoading)
            return <AppBarSpinner loading={formLoading} />;
          if (activeStep === 2 && !formLoading)
            return (
              <Button
                form="item-receival-form"
                type="submit"
                disabled={formLoading}
                edge="end"
              >
                Submit
              </Button>
            );
          return null;
        }}
        onStepClick={(i) => {
          setActiveStep((oldStep) => {
            if (oldStep === 2) setFormData({});
            return i;
          });
        }}
        searchComponent={
          activeStep === 0 && (
            <DebouncedSearch
              autoFocus
              initialValue={searchVal}
              color="secondary"
              onSearchChange={(val) => setSearchVal(val)}
              fullWidth
            />
          )
        }
        itemNumberListComponent={
          <ItemNumberList
            searchValue={searchVal}
            loading={searchLoading}
            data={searchData}
            onClick={(obj) => {
              setItemNumber(obj.value);
              setActiveStep(1);
            }}
            placeholderComponent={() => (
              <PlaceholderContainer
                primary="Start typing an item number"
                secondary="Results will appear here"
              >
                <SearchPlaceholder />
              </PlaceholderContainer>
            )}
            notFoundComponent={() => (
              <PlaceholderContainer
                primary="No results found"
                secondary="Try searching for something else"
              >
                <NotFoundPlaceholder />
              </PlaceholderContainer>
            )}
            errorComponent={() => (
              <PlaceholderContainer
                primary="An error occurred"
                secondary="Failed to load data"
              >
                <ErrorPlaceholder />
              </PlaceholderContainer>
            )}
            listItemActionProps={{
              tooltip: "Show in documents",
              icon: ExitToAppIcon,
              onClick: (obj) =>
                window.open(`/documents/documents?item_number=${obj.value}`),
            }}
          />
        }
        trackingInfoComponent={
          <TrackingInfo
            data={{ ...trackingData, ...formData }}
            loading={trackingLoading}
          />
        }
        purchaseOrderComponent={
          <SelectPurchaseOrder
            loading={purchaseLoading}
            data={purchaseData}
            onClick={(obj) => {
              setFormData(obj);
              setActiveStep(2);
            }}
            notFoundPlaceHolder={
              <PlaceholderContainer
                primary="No purchase orders found for this item number"
                secondary="Go back to the previous step and select another item number"
              >
                <NotFoundPlaceholder />
              </PlaceholderContainer>
            }
          />
        }
        formComponent={
          <ItemReceivalForm
            data={formData}
            trackingData={trackingData}
            onSubmit={handleSubmit}
          />
        }
      />
      <FullpageTableScrollContainer>
        <RemoteDataTable
          key={tableReloadedAt}
          initialFilters={initialFilters}
          size="small"
          dataFetchCallback={(params) => {
            navigate(
              `${location.pathname}?${queryStringSerialize(
                pickBy(params.filter),
              )}`,
            );
            return fetch(
              `${
                import.meta.env.VITE_VTS_API
              }/v1/purchase/items_to_receive?${queryStringSerialize(params)}
            `,
              {
                headers: authHeaders(),
              },
            )
              .then((r) => {
                setLoading(false);
                return r.json();
              })
              .catch((e) => setLoading(false));
          }}
          enableFiltering
          enableSorting
          requestOptions={{
            rowsPerPage: 30,
          }}
          initialColumns={[
            {
              name: "Order",
              key: "document_no",
              format: function Highlight(v) {
                return <PurchaseHighLight list>{v}</PurchaseHighLight>;
              },
              filterOptionsCallback: searchNewSelect("purchase_order_number"),
            },
            { name: "Line", key: "line_no", filterType: "text" },
            {
              name: "Item",
              key: "item_no",
              format: function Highlight(v) {
                return <ItemNumberHighLight>{v}</ItemNumberHighLight>;
              },
              filterOptionsCallback: searchNewSelect("item_number"),
            },
            { name: "Description", key: "description", filterType: "text" },
            { name: "Qty", key: "outstanding_quantity", filterType: "number" },
            {
              name: "Qty Filled",
              key: "warehouse_receipts",
              filterType: "number",
              disableFilter: true,
              disableSorting: true,
            },
            {
              name: "Receive",
              key: "receive_action",
              disableFilter: true,
              disableSorting: true,
              format: (_v, obj) => {
                return (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setTrackingLoading(true);
                      handleReceiveExisting(obj);
                    }}
                    size="large"
                  >
                    <ReceiveItemIcon />
                  </IconButton>
                );
              },
            },
          ]}
        />
      </FullpageTableScrollContainer>
    </>
  );
};

export default WarehouseReceival;
