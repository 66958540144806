import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

const SelectAutoComplete = ({
  label,
  options,
  onChange,
  value,
  id,
  ...props
}) => {
  return (
    <Autocomplete
      id={id}
      options={options}
      getOptionLabel={(opt) => opt.label || ""}
      onChange={(_, value) => onChange(value)}
      value={value}
      renderInput={(params) => <TextField {...params} label={label} />}
      {...props}
    />
  );
};

export default SelectAutoComplete;
