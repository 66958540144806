import { Typography } from "@mui/material";
import React from "react";
import CardBase from "@/pages/home/components/CardBase";

const WeldMaps = ({ weldsToApprove, weldMaps, loading, error }) => {
  const noData = <Typography variant="h6">No data.</Typography>;

  const content = (
    <>
      <Typography variant="h4">{weldsToApprove}</Typography>
      <small>Welds to approve in</small>
      <Typography variant="h4">{weldMaps}</Typography>
      <small>weld maps.</small>
    </>
  );

  return (
    <CardBase
      title="Weld Maps"
      content={weldsToApprove !== null ? content : noData}
      actionTitle="See Items"
      actionHref="/reports/weld-maps"
      loading={loading}
      error={error}
    />
  );
};
export default WeldMaps;
