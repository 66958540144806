import React, { useState } from "react";
import {
  TextField,
  TableRow,
  TableCell,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import { useEffect } from "react";

const EditableLine = ({ record, date, machineCenter, onCreate, onUpdate }) => {
  const [editMode, setEditMode] = useState(false);
  const [saving, setSaving] = useState(false);
  const [formData, setFormData] = useState({
    id: record ? record.id : null,
    quantity: record ? record.quantity : 0,
    overtime: record ? record.overtime : 0,
  });

  useEffect(() => {
    setFormData({
      id: record ? record.id : null,
      quantity: record ? record.quantity : 0,
      overtime: record ? record.overtime : 0,
    });
  }, [record]);

  const handleChange = (e, field) =>
    setFormData({ ...formData, [field]: e.target.value });

  const handleSave = () => {
    if (
      record &&
      formData.quantity === record.quantity &&
      formData.overtime === record.overtime
    ) {
      setEditMode(false);
      return;
    }
    setSaving(true);
    if (formData.id) {
      onUpdate(formData.id, {
        machine_center: machineCenter,
        day: date.format("YYYY-MM-DD"),
        quantity: formData.quantity,
        overtime: formData.overtime,
      });
    } else {
      onCreate({
        machine_center: machineCenter,
        day: date.format("YYYY-MM-DD"),
        quantity: formData.quantity,
        overtime: formData.overtime,
      });
    }
    setSaving(false);
    setEditMode(false);
  };

  const handleCancel = () => {
    setFormData({
      quantity: record ? record.quantity : 0,
      overtime: record ? record.overtime : 0,
    });
    setEditMode(false);
  };

  return (
    <TableRow>
      <TableCell>{date.format("MMMM YYYY")}</TableCell>
      <TableCell onClick={() => setEditMode(true)}>
        {editMode ? (
          <TextField
            size="small"
            type="number"
            inputProps={{
              step: 1,
              min: 0,
            }}
            value={formData.quantity}
            onChange={(e) => handleChange(e, "quantity")}
            required
          />
        ) : (
          formData.quantity
        )}
      </TableCell>
      <TableCell onClick={() => setEditMode(true)}>
        {editMode ? (
          <TextField
            size="small"
            type="number"
            inputProps={{
              step: 0.1,
              min: 0,
            }}
            value={formData.overtime}
            onChange={(e) => handleChange(e, "overtime")}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            required
          />
        ) : (
          formData.overtime + "%"
        )}
      </TableCell>
      <TableCell>
        <IconButton
          size="small"
          onClick={() => (editMode ? handleSave() : setEditMode(true))}
        >
          {saving ? (
            <CircularProgress />
          ) : editMode ? (
            <DoneIcon />
          ) : (
            <EditIcon />
          )}
        </IconButton>
        {editMode && (
          <IconButton size="small" onClick={() => handleCancel()}>
            <ClearIcon />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

export default EditableLine;
