import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

const SelectAutoComplete = ({
  label,
  options,
  onChange,
  inputProps,
  value,
  id,
  ...props
}) => {
  return (
    <Autocomplete
      id={id}
      options={options}
      getOptionLabel={(opt) => opt}
      onChange={(_, value) => onChange(value)}
      value={value}
      renderInput={(params) => (
        <TextField {...params} label={label} {...inputProps} />
      )}
      {...props}
    />
  );
};

export default SelectAutoComplete;
