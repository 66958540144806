import React, { useState, useEffect } from "react";
import { Navigate, useLocation } from "react-router";
import { LinearProgress, Fade } from "@mui/material";
import CopyButton from "./CopyButton";

const RailsVTSiframe = ({ url, title }) => {
  const location = useLocation();
  let searchParams = new URLSearchParams(location.search);
  const appBarHeight = document.getElementById("app-bar")?.clientHeight || 64;
  const { accessToken } = JSON.parse(localStorage.getItem("msToken"));
  const [loading, setLoading] = useState(true);
  const [iframeURL, setIframeURL] = useState("");
  const [initialLoad, setInitialLoad] = useState(false);
  // const { getPageFilter, onUpdateFilter } = useFilters();

  useEffect(() => {
    const iframeElement = document.getElementById("railsvts")?.contentWindow;
    const receiveIframeMessage = (event) => {
      const sourceIsValid =
        event.origin === import.meta.env.VITE_VTS_ENDPOINT &&
        event.source === iframeElement;

      if (sourceIsValid && event.data.reason === "LOADING") {
        setLoading(true);
      }

      if (sourceIsValid && event.data.reason === "IFRAME_DATA") {
        const newURL = new URL(event.data.url);
        newURL.searchParams.delete("layout");
        newURL.searchParams.delete("azure_token");
        setIframeURL(newURL.href);
      }
    };

    window.addEventListener("message", receiveIframeMessage);
    return () => {
      window.removeEventListener("message", receiveIframeMessage);
    };
  }, []);

  const tokenString = accessToken ? `&azure_token=${accessToken}` : "";

  if (!url) return <Navigate to="/not-found" replace />;
  return (
    <>
      <Fade in={loading}>
        <LinearProgress
          color="primary"
          sx={{ position: "absolute", top: appBarHeight, left: 0, right: 0 }}
        />
      </Fade>
      <Fade in={initialLoad}>
        <iframe
          id="railsvts"
          onLoad={() => {
            setLoading(false);
            setInitialLoad(true);
          }}
          src={`${url}?${searchParams}&layout=new${tokenString}`}
          title={title || "RailsVTSiframe"}
          style={{
            width: "100%",
            height: `calc(99vh - ${appBarHeight}px`,
            border: "none",
          }}
        />
      </Fade>
      {iframeURL && <CopyButton textToCopy={iframeURL} />}
    </>
  );
};

export default RailsVTSiframe;
