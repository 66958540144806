import { Box, Fade, Typography, Button, Paper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";

import logo from "@/images/logo.svg";

import { WeldText } from "@/pages/reports/weld-log/components/WeldText";
import { WeldHeader } from "@/pages/reports/weld-log/components/WeldHeader";
import { WeldBody } from "@/pages/reports/weld-log/components/WeldBody";
import { WeldNdtScope } from "@/pages/reports/weld-log/components/WeldNdtScope";
import { WeldTable } from "@/pages/reports/weld-log/components/WeldTable";
import { WeldApprovals } from "@/pages/reports/weld-log/components/WeldApprovals";

const useStyles = makeStyles((theme) => ({
  page: {
    "@media print": {
      margin: "10mm",
    },
  },
  section: {
    "@media print": {
      padding: "20px !important",
    },
  },
  sectionNoBorder: {
    "@media print": {
      paddingTop: "20px !important",
      paddingBottom: "20px !important",
    },
  },
  button: {
    "@media print": {
      display: "none",
    },
  },
}));

const Spacer = () => <div id="spacer" style={{ height: 20 }} />;

const Section = ({ children, disableBorder, ...props }) => {
  const classes = useStyles();

  return (
    <Fade in timeout={1000}>
      <Box
        component={disableBorder ? "div" : Paper}
        variant="outlined"
        p={disableBorder ? 0 : 2}
        className={disableBorder ? classes.sectionNoBorder : classes.section}
        {...props}
      >
        {children}
      </Box>
    </Fade>
  );
};

const Header = ({ title }) => (
  <Typography variant="subtitle2" style={{ marginBottom: 10 }}>
    {title}
  </Typography>
);

export const WeldReport = ({
  data,
  title,
  loading,
  printRef,
  onSign,
  error,
}) => {
  const isApproved = data?.approval?.date ?? false;
  const classes = useStyles();

  return (
    <div ref={printRef} className={classes.page}>
      <Box display="flex" mr={1} alignItems="center">
        <Box width="100%" flex={1}>
          <WeldHeader
            {...data?.header}
            isApproved={isApproved}
            title={title}
            loading={loading}
            error={error}
          />
        </Box>
        <img height="auto" width="35%" alt="logo" src={logo} />
      </Box>

      <Spacer />

      <Section>
        <WeldBody {...data?.body} loading={loading} />
        <Spacer />
        <WeldText title="Comments" value={data?.comments} loading={loading} />
      </Section>

      <Spacer />

      <Section disableBorder>
        <Header title="NDT Scope" />
        <WeldNdtScope ndtScope={data?.ndtScope} loading={loading} />
      </Section>

      <Spacer />

      <Section disableBorder>
        <Header title="Weld" />
        <WeldTable {...data?.weld} loading={loading} />
      </Section>

      <Spacer />
      <Spacer />

      <Section>
        <Box display="flex" alignItems="center" mb={2}>
          <Box flex={1}>
            <Header title="Approvals" />
          </Box>

          <Button
            size="small"
            variant="outlined"
            color={isApproved ? "error" : "primary"}
            onClick={() =>
              error ? undefined : onSign(isApproved ? false : true)
            }
            disabled={loading || error}
            className={classes.button}
            startIcon={
              isApproved ? <ThumbDownOutlinedIcon /> : <ThumbUpOutlinedIcon />
            }
          >
            {isApproved ? "Reject approval" : "Approve"}
          </Button>
        </Box>
        <WeldApprovals
          approval={data?.approval}
          welder={data?.welder}
          loading={loading}
          isApproved={isApproved}
        />
      </Section>
    </div>
  );
};
