import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useTable from "@/components/datatable/useTable";
import MaterialToolbar from "@/components/datatable/MaterialToolbar";
import MaterialDataTable from "@/components/datatable/MaterialDataTable";
import ProjectFilter from "@/pages/project/components/ProjectFilter";
import getTableComponentData from "@/pages/project/components/getTableComponentData";
import getTableComponentColumns from "@/pages/project/components/getTableComponentColumns";
import AppBarButtonWrapper from "@/components/AppBarButtonWrapper";
import FullpageTableScrollContainer from "@/components/FullpageTableScrollContainer";
import "@/pages/project/components/ProjectComponents.css";

const ProjectComponents = () => {
  const [projectSelected, setProjectSelected] = useState(null);
  const { t } = useTranslation();
  const columns = getTableComponentColumns(t);
  const { state, handlers, setters } = useTable({
    columns: columns,
    options: {
      multiSort: false,
      mode: "client",
      debug: false,
    },
  });
  const { handleApplyFilter, handleRemoveFilter, handleSearch } = handlers;
  const { setLoading, setRows } = setters;

  useEffect(() => {
    setLoading(true);
    if (Boolean(projectSelected)) {
      getTableComponentData(projectSelected.value)
        .then((response) => {
          setRows(response.rows);
          setLoading(false);
        })
        .catch(() => {
          setRows([]);
          setLoading(false);
          alert("fail to load");
        });
    } else {
      setRows([]);
      setLoading(false);
    }
  }, [setRows, setLoading, projectSelected]);

  function handleProjectChange(data) {
    setProjectSelected(data);
  }

  return (
    <>
      <AppBarButtonWrapper showSpinner={state.loading}>
        <nav id="ProjectFilterNav">
          <ProjectFilter onProjectChange={handleProjectChange} />
        </nav>
      </AppBarButtonWrapper>
      <FullpageTableScrollContainer>
        <MaterialToolbar
          title={projectSelected?.text}
          loadedRows={state.loadedRows}
          totalRows={state.totalRows}
          filters={state.filters}
          loading={state.loading}
          columns={state.columns}
          onSearch={handleSearch}
          onRemoveFilter={handleRemoveFilter}
        />
        <MaterialDataTable
          TableContainerProps={{ sx: { overflow: "initial" } }} // important to get body scroll working
          onApplyFilter={handleApplyFilter}
          onRemoveFilter={handleRemoveFilter}
          enableFiltering
          {...state}
        />
      </FullpageTableScrollContainer>
    </>
  );
};

export default ProjectComponents;
