import { TextField, capitalize } from "@mui/material";
import AutocompleteRemote from "./AutocompleteRemote";
import AutocompleteMultipleRemote from "./AutocompleteMultipleRemote";
import DateRangeField from "./DateRangeField";

import {
  FiltersDefinition,
  SelectOptionDefinition,
  DateSelectOptionDefinition,
  ColumnDefinition,
  RowDefinition,
} from "@/types";

export type onChangeCallback = (
  value:
    | SelectOptionDefinition
    | SelectOptionDefinition[]
    | DateSelectOptionDefinition
    | null,
) => void;

interface FilterFieldProps {
  variant?: "filled" | "outlined" | "standard";
  column: ColumnDefinition;
  filter: FiltersDefinition;
  onChange: onChangeCallback;
  size?: "medium" | "small" | undefined;
  originalRows?: RowDefinition[];
}

const FilterField = ({
  column,
  filter,
  onChange,
  variant = "outlined",
  size = "small",
  originalRows,
}: FilterFieldProps) => {
  let filterValue;

  const type = column.filterType || "text";

  const getUniqueOptionsFromTable = () => {
    if (
      originalRows &&
      originalRows?.length > 0 &&
      (type === "select" || type === "multipleSelect")
    ) {
      return originalRows
        .map((row, i) => row[column.key])
        .filter((v, i, a) => v !== null && a.indexOf(v) === i)
        .map((v) => ({
          value: v,
          text: typeof v === "string" ? capitalize(v) : v,
        })) as SelectOptionDefinition[];
    }
    return [];
  };

  if (type === "text") {
    filterValue = filter[column.key] as SelectOptionDefinition;
    return (
      <TextField
        variant={variant}
        type="text"
        fullWidth
        defaultValue={filterValue?.value}
        onChange={(e) =>
          onChange({ value: e.target.value, text: e.target.value })
        }
        label={column?.name}
        size={size}
        autoFocus
        autoComplete="off"
      />
    );
  }

  if (type === "number") {
    filterValue = filter[column.key] as SelectOptionDefinition;
    return (
      <TextField
        variant={variant}
        type="number"
        fullWidth
        defaultValue={
          filterValue?.value
            ? parseFloat(filterValue.value as string)
            : undefined
        }
        onChange={(e) =>
          onChange({ value: e.target.value, text: e.target.value.toString() })
        }
        label={column?.name}
        size={size}
        autoFocus
        autoComplete="off"
      />
    );
  }

  if (type === "multipleSelect") {
    filterValue = filter[column.key] as SelectOptionDefinition[];
    return (
      <AutocompleteMultipleRemote
        size={size}
        variant={variant}
        column={column}
        initialValue={filterValue}
        onChange={onChange}
        initialOptions={getUniqueOptionsFromTable()}
      />
    );
  }

  if (type === "select") {
    filterValue = filter[column.key] as SelectOptionDefinition;
    return (
      <AutocompleteRemote
        size={size}
        variant={variant}
        column={column}
        initialValue={filterValue}
        onChange={onChange}
        initialOptions={getUniqueOptionsFromTable()}
      />
    );
  }

  if (type === "date") {
    filterValue = filter[column.key] as DateSelectOptionDefinition;
    return (
      <DateRangeField
        variant={variant}
        onChange={onChange}
        initialValue={filterValue}
        size={size}
      />
    );
  } else return <></>;
};

export default FilterField;
