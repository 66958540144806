import React from "react";
import ProjectHighLight from "@/components/HighLight/ProjectHighLight";

const getTableComponentColumns = () => [
  {
    name: "Project",
    key: "project",
    filterType: "select",
    renderCellFn: (v) => <ProjectHighLight>{v}</ProjectHighLight>,
    cellPropsFn: (v) => ({
      sx: {
        width: "5%",
      },
    }),
  },
  {
    name: "Customer",
    key: "customer",
    filterType: "select",
    cellPropsFn: (v) => ({
      sx: {
        width: "15%",
      },
    }),
  },
  {
    name: "PO",
    key: "po",
    filterType: "select",
    cellPropsFn: (v) => ({
      sx: {
        width: "15%",
      },
    }),
  },
  {
    name: "Manager",
    key: "manager",
    filterType: "select",
    cellPropsFn: (v) => ({
      sx: {
        width: "15%",
      },
    }),
  },
  {
    name: "Planner",
    key: "planner",
    filterType: "select",
    cellPropsFn: (v) => ({
      sx: {
        width: "15%",
      },
    }),
  },
  {
    name: "Purchaser",
    key: "purchaser",
    filterType: "select",
    cellPropsFn: (v) => ({
      sx: {
        width: "15%",
      },
    }),
  },
  {
    name: "Doc Controller",
    key: "doc_controller",
    filterType: "select",
    cellPropsFn: (v) => ({
      sx: {
        width: "15%",
      },
    }),
  },
];
export default getTableComponentColumns;
