import { pickBy } from "lodash";
import queryStringSerialize from "../utils/queryStringSerialize";

/**
 *
 * @param {string} url
 * @param {Object} params
 * @param {string} origin
 * @returns {string} URL With Query Strings Encoded
 */
const fetchUrl = (url, params = {}, origin = window.location.origin) => {
  var newurl = new URL(url, origin);
  newurl.search = queryStringSerialize(
    pickBy(params, (e) => (Array.isArray(e) ? e.length > 0 : e)),
  );
  return newurl.toString();
};

export default fetchUrl;
