import PropTypes from "prop-types";

import BlockIcon from "@mui/icons-material/Block";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ScheduleIcon from "@mui/icons-material/Schedule";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import { nudgeAnimation } from "./nudgeAnimation";

const TabIcon = ({
  currentIndex,
  index,
  error,
  disabled,
  errorColor,
  filled,
  fontSize,
  inline,
}) => {
  const isSelected =
    typeof currentIndex === "number" &&
    typeof index === "number" &&
    currentIndex === index;

  const style = {
    fontSize: fontSize,
    verticalAlign: inline ? "middle" : undefined,
  };

  if (filled && !error)
    return <CheckCircleIcon style={{ color: "#37B24B", ...style }} />;

  if (!isSelected && error)
    return (
      <ErrorOutlineIcon
        style={{ color: errorColor, ...style }}
        sx={nudgeAnimation(true)}
      />
    );
  if (isSelected)
    return (
      <EditOutlinedIcon
        style={{ color: error ? errorColor : null, ...style }}
        sx={nudgeAnimation(error)}
      />
    );
  if (disabled) return <BlockIcon style={style} />;
  if (!isSelected && !error) return <HistoryToggleOffIcon style={style} />;
  else return <ScheduleIcon style={style} />;
};

TabIcon.propTypes = {
  currentIndex: PropTypes.number,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  index: PropTypes.number,
  fontSize: PropTypes.number,
  inline: PropTypes.bool,
};

export default TabIcon;
