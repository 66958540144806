import { alpha } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const ColoredTooltip = styled(({ className, ...props }) => {
  return <Tooltip arrow classes={{ popper: className }} {...props} />;
})(({
  theme,
  baseColor = "#FFFFFF",
  fontColor,
  borderColor,
  elevation = 2,
  borderThickness = 1,
}) => {
  const fontCol = fontColor ?? theme.palette.getContrastText(baseColor);
  const borderCol = borderColor ?? alpha(fontCol, 0.2);

  return {
    [`& .${tooltipClasses.arrow}`]: {
      color: baseColor,
      "&::before": {
        border: `${borderThickness}px solid ${borderCol}`,
      },
    },
    [`& .${tooltipClasses.tooltip}`]: {
      color: fontCol,
      backgroundColor: baseColor,
      boxShadow: theme.shadows[elevation],
      border: `${borderThickness}px solid ${borderCol}`,
    },
  };
});

export default ColoredTooltip;
