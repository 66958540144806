import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Box,
  Drawer,
  TextField,
  Stack,
} from "@mui/material";
import loadFilterOptions from "../../../../services/project/delivery-plan/loadFilterOptions";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import SelectAutoComplete from "./SelectAutoComplete";
import moment from "moment";
import { useDateMask } from "../../../../hooks/useLocaleMask";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";

const DeliveryPlanFilterDrawer = ({
  filters,
  open,
  onSubmit,
  onClose,
  onFilterChange,
  onClear,
}) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { mask } = useDateMask();

  const [filterOptions, setFilterOptions] = useState({});
  const [filterOptionsLoading, setFilterOptionsLoading] = useState(true);

  const handleSubmit = () => {
    typeof onSubmit === "function" && onSubmit(filters);
  };

  const startDateText = filters?.start_date
    ? t("Start date")
    : `${t("Start date")} (${t("automatic")})`;

  const endDateText = filters?.end_date
    ? t("End date")
    : `${t("End date")} (${t("automatic")})`;

  const handleDateChange = (dates) => {
    onFilterChange(
      "start_date",
      dates[0]?.startOf("day").toISOString() || null,
    );
    onFilterChange("end_date", dates[1]?.endOf("day").toISOString() || null);
  };

  const selectedOption = (options, id) => {
    return options?.find((option) => option.id === id) ?? "";
  };

  useEffect(() => {
    loadFilterOptions()
      .then((filterOptions) => {
        setFilterOptions(filterOptions);
      })
      .catch((e) => {
        enqueueSnackbar(t("Failed to load filters"), {
          variant: "error",
        });
      })
      .finally(() => {
        setFilterOptionsLoading(false);
      });
  }, [enqueueSnackbar, t]);

  return (
    <Drawer open={open} anchor="right" onClose={onClose}>
      <Box width={340}>
        <Box display="flex" alignItems="center" m={2}>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            {t("Filters")}
          </Typography>
          <Button
            disabled={filterOptionsLoading}
            onClick={() => onClear()}
            color="inherit"
          >
            {t("Clear")}
          </Button>
          <Button
            disabled={filterOptionsLoading}
            onClick={handleSubmit}
            color="primary"
          >
            {t("Save")}
          </Button>
        </Box>
        <Box m={2} display="flex" flexDirection="column">
          <Stack spacing={2}>
            <DateRangePicker
              calendars={1}
              mask={mask}
              startText={startDateText}
              endText={endDateText}
              clearable
              onChange={handleDateChange}
              value={[
                filters.start_date ? moment(filters.start_date) : null,
                filters.end_date ? moment(filters.end_date) : null,
              ]}
              renderInput={(startProps, endProps) => (
                <Stack spacing={2} sx={{ width: "100%" }}>
                  <TextField {...startProps} fullWidth size="small" />
                  <TextField {...endProps} fullWidth size="small" />
                </Stack>
              )}
            />
            <SelectAutoComplete
              label={t("Project Manager")}
              id="manager"
              onChange={(value) => {
                onFilterChange("manager", value?.id);
              }}
              options={filterOptions?.managers || []}
              value={selectedOption(filterOptions?.managers, filters?.manager)}
              disabled={filterOptionsLoading}
              size="small"
            />
            <SelectAutoComplete
              label={t("Customer PN")}
              id="customer_pn"
              onChange={(value) => onFilterChange("customer_pn", value?.id)}
              options={filterOptions?.customer_pns || []}
              value={selectedOption(
                filterOptions?.customer_pns,
                filters?.customer_pn,
              )}
              disabled={filterOptionsLoading}
              size="small"
            />
            <SelectAutoComplete
              label={t("Customer")}
              id="customer"
              onChange={(value) => onFilterChange("customer", value?.id)}
              options={filterOptions?.customers || []}
              value={selectedOption(
                filterOptions?.customers,
                filters?.customer,
              )}
              disabled={filterOptionsLoading}
              size="small"
            />
          </Stack>
        </Box>
      </Box>
    </Drawer>
  );
};

DeliveryPlanFilterDrawer.propTypes = {
  filters: PropTypes.shape({
    customer: PropTypes.string,
    customer_pn: PropTypes.string,
    end_date: PropTypes.string,
    manager: PropTypes.string,
    start_date: PropTypes.string,
  }),
  onClear: PropTypes.func,
  onClose: PropTypes.func,
  onFilterChange: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

export default DeliveryPlanFilterDrawer;
