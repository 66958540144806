import { Chip } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { green, red } from "@mui/material/colors";
import { alpha } from "@mui/material/styles";

const errorStyle = {
  color: red[500],
  background: alpha(red[500], 0.2),
  fontWeight: 500,
};
const filledStyle = {
  color: green[500],
  background: alpha(green[500], 0.2),
  fontWeight: 500,
};

const getStyle = (filled, error) => {
  if (!error && filled) return filledStyle;
  if (error) return errorStyle;
  else return null;
};

const getlabel = (filled, error) => {
  if (!error && filled) return "Filled";
  if (error) return "Error";
  else return "Empty";
};

const getIcon = (filled, error) => {
  if (!error && filled) return <DoneIcon style={{ color: "inherit" }} />;
  if (error) return <ErrorOutlineIcon style={{ color: "inherit" }} />;
  else return null;
};

const StatusChip = ({ filled, error, ...props }) => {
  return (
    <Chip
      size="small"
      icon={getIcon(filled, error)}
      label={getlabel(filled, error)}
      style={getStyle(filled, error)}
      {...props}
    />
  );
};

export default StatusChip;
