import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import AppBarSpinner from "./AppBarSpinner";

const AppBarButtonWrapper = ({ children, showSpinner, spinnerProps }) => {
  const [topBarRef, setTopBarRef] = useState(null);

  useEffect(() => {
    setTopBarRef(document.getElementById("appbar-extra-buttons"));
  }, []);

  return (
    topBarRef &&
    createPortal(
      <>
        <AppBarSpinner loading={showSpinner} {...spinnerProps} /> {children}
      </>,
      topBarRef,
    )
  );
};

AppBarButtonWrapper.defaultProps = {
  showSpinner: false,
};

AppBarButtonWrapper.propTypes = {
  children: PropTypes.element,
  showSpinner: PropTypes.bool,
  spinnerProps: PropTypes.object,
};

export default AppBarButtonWrapper;
