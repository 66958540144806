import { alpha, Button, ButtonGroup, Link } from "@mui/material";
import getRoutingStatusColor from "@/utils/getRoutingStatusColor";
import ColoredTooltip from "@/components/ColoredTooltip";
import humanizeSnakeCase from "@/lib/humanizeSnakeCase";

const OperationsCell = ({
  routing,
  productionOrderNumber,
  onClick,
  orientation = "horizontal",
}) => {
  return (
    <ButtonGroup
      style={{
        display: "flex",
        flexWrap: "wrap",
        maxWidth: "100%",
      }}
      size="small"
      variant="outlined"
      orientation={orientation}
    >
      {routing.map(
        (
          {
            machine_center,
            number,
            status,
            delayed,
            start_date,
            end_date,
            description,
          },
          i,
        ) => {
          const url = `/production/operations?operation=${number}&prod_order=${productionOrderNumber}`;
          const { bg, border, font } = getRoutingStatusColor(status, delayed);
          const backgroundColor = alpha(bg, 0.9);

          return (
            <ColoredTooltip
              baseColor={bg}
              key={i + "operations_cell"}
              placement="bottom"
              title={
                <div style={{ fontWeight: 400 }}>
                  <div style={{ fontWeight: 500 }}>{description}</div>
                  <div>
                    {new Date(start_date).toLocaleDateString() +
                      " - " +
                      new Date(end_date).toLocaleDateString()}
                  </div>
                  <div>{`Order: ${productionOrderNumber}`}</div>
                  <div>{`Operation: ${number}`}</div>
                  <div>
                    <span>{`Status: ${humanizeSnakeCase(status ?? "")}`}</span>
                    {delayed && <span>{` (Delayed)`}</span>}
                  </div>
                </div>
              }
            >
              <Button
                style={{
                  backgroundColor,
                  color: font,
                  borderColor: border,
                  minWidth: 42,
                  marginBottom: 2,
                  paddingTop: 2,
                  paddingBottom: 2,
                  paddingLeft: 5,
                  paddingRight: 5,
                }}
              >
                <Link
                  style={{
                    color: "inherit",
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                  href={url}
                  underline={
                    Boolean(onClick) && Boolean(url) ? "hover" : "none"
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    onClick && onClick(url);
                  }}
                >
                  {machine_center}
                </Link>
              </Button>
            </ColoredTooltip>
          );
        },
      )}
    </ButtonGroup>
  );
};
export default OperationsCell;

export const OperationTemplate = ({ orientation = "horizontal" }) => (
  <OperationsCell
    orientation={orientation}
    routing={[
      {
        number: "Finished",
        status: "done",
        delayed: false,
      },
      {
        number: "In progress",
        status: "in_progress",
        delayed: false,
      },
      {
        number: "Hold",
        status: "hold",
        delayed: false,
      },

      {
        number: "Planned",
        status: "planned",
        delayed: false,
      },
      {
        number: "Delayed",
        status: "planned",
        delayed: true,
      },

      {
        number: "Pre-planned",
        status: "pre_planned",
        delayed: false,
      },
    ]}
  />
);
