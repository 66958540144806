import React, { useState, useEffect, cloneElement } from "react";
import {
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
  Fade,
  SvgIcon,
  Grow,
  ListItem,
  alpha,
  ListItemProps,
  ListItemButton,
  IconButton,
  IconProps,
  Box,
  Theme,
  SxProps,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { mdiPinOutline } from "@mdi/js";
import { mdiPin } from "@mdi/js";
import ToggleIcon from "@/components/ToggleIcon";
import {
  IconType,
  ListType,
} from "@/pages/main/navigation-drawer/NavigationDrawer";

type Color = "primary" | "secondary";
interface PinnableListItemIconProps {
  alwaysShowPin?: boolean;
  color: Color;
  icon?: IconType;
  isPinnable?: boolean;
  onClick?: (value: boolean) => void;
  pinned?: boolean;
  selected?: boolean;
}
interface DrawerListItemProps {
  alwaysShowPin: boolean;
  color: Color;
  enablePinning: boolean;
  id: string | number;
  isPinned?: boolean;
  listItem: ListType;
  onClick?: (list: ListType) => void;
  onExpand?: (expanded: boolean) => void;
  onPinClick?: (path: string) => void;
  onUnpinClick?: (path: string) => void;
  props?: ListItemProps;
  selected?: boolean;
  selectedPath?: string;
  subMenuOpen?: boolean;
}

const drawerSx = (color: "primary" | "secondary") =>
  ({
    "&.MuiListItemText-primary": {
      fontWeight: 400,
    },
    "&.MuiTouchRipple-root": {
      color: ({ palette }: Theme) => palette[color].main,
    },

    borderRadius: "0.4em",
    "&.Mui-selected": {
      backgroundColor: ({ palette }: Theme) => alpha(palette[color].main, 0.2),
      "&.MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: ({ palette }: Theme) => palette[color].main,
        fontWeight: 500,
      },
      "&.Mui-focusVisible": {
        backgroundColor: ({ palette }: Theme) =>
          alpha(palette[color].main, 0.3),
      },
      "&.Mui-selected:hover": {
        backgroundColor: ({ palette }: Theme) =>
          alpha(palette[color].main, 0.3),
      },
    },
  }) as SxProps;

const noWrapProps = {
  primaryTypographyProps: {
    noWrap: true,
  },
  secondaryTypographyProps: {
    noWrap: true,
  },
};

const PinOutlined = ({ ...props }) => (
  <SvgIcon {...props}>
    <path d={mdiPinOutline} />
  </SvgIcon>
);

const PinIcon = ({ ...props }) => (
  <SvgIcon {...props}>
    <path d={mdiPin} />
  </SvgIcon>
);

const PinnableListItemIcon = ({
  alwaysShowPin,
  color,
  icon,
  isPinnable,
  onClick,
  pinned,
  selected,
}: PinnableListItemIconProps) => {
  const [hover, setHover] = useState(false);
  const [internalPinned, setInternalPinned] = useState<boolean>(
    pinned ?? false,
  );

  const handlePinClick = (e: React.MouseEvent) => {
    if (isPinnable) {
      e.stopPropagation();
      e.preventDefault();
      setInternalPinned(!internalPinned);
      setTimeout(() => {
        onClick?.(internalPinned);
      }, 300);
    }
  };

  useEffect(() => {
    setInternalPinned(pinned ?? false);
  }, [pinned]);

  return (
    <ListItemIcon>
      <IconButton
        size="small"
        onMouseEnter={() => isPinnable && setHover(true)}
        onMouseLeave={() => isPinnable && setHover(false)}
        onClick={handlePinClick}
        disableRipple={!isPinnable}
        tabIndex={-1}
      >
        {(isPinnable && hover) || (alwaysShowPin && pinned) ? (
          <Grow in timeout={200} key="pin">
            <Box display="flex">
              <ToggleIcon
                on={internalPinned}
                onIcon={<PinIcon color={color} />}
                offIcon={<PinOutlined color="action" />}
              />
            </Box>
          </Grow>
        ) : (
          <Grow in timeout={200} key="icon">
            <Box display="flex">
              <IconRenderer
                icon={icon}
                props={{ color: selected ? color : "action" }}
              />
            </Box>
          </Grow>
        )}
      </IconButton>
    </ListItemIcon>
  );
};

const DrawerListItem = ({
  alwaysShowPin,
  color,
  enablePinning,
  id,
  isPinned,
  listItem,
  onClick,
  onExpand,
  onPinClick,
  onUnpinClick,
  selected,
  selectedPath,
  subMenuOpen,
}: DrawerListItemProps) => {
  const { icon, path, title, subtitle, subMenu, hidden, disabled } = listItem;

  const [expanded, setExpanded] = useState(() => {
    if (subMenu?.find((element) => element.path === selectedPath)) return true;
    if (subMenuOpen) return true;
    else return false;
  });

  const handleClick = (item: ListType) => {
    if (item.subMenu) {
      setExpanded?.(!expanded);
      onExpand?.(expanded);
    } else {
      onClick?.(item);
    }
  };

  const handlePinClick = (pinned: boolean, item: ListType) => {
    if (item.path) {
      if (pinned) onUnpinClick?.(item.path);
      else onPinClick?.(item.path);
    }
  };

  const toggleIconColor = subMenu?.find((e) => e.path === selectedPath)
    ? color
    : "action";

  if (hidden) return null;
  if (title || subtitle)
    return (
      <Fade in>
        <div>
          <ListItem
            disablePadding
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              handleClick(listItem);
            }}
          >
            <ListItemButton
              component="a"
              href={path}
              id={"drawerListItem-" + id}
              disabled={disabled}
              selected={!subMenu && selected}
              sx={{ pl: 1, py: 0.5, mx: 1.5, my: 0.5, ...drawerSx(color) }}
            >
              <PinnableListItemIcon
                onClick={(pinned) => handlePinClick(pinned, listItem)}
                pinned={isPinned}
                icon={icon}
                selected={selected}
                alwaysShowPin={alwaysShowPin}
                color={color}
                isPinnable={Boolean(path) && enablePinning}
              />

              <ListItemText
                primary={title}
                secondary={subtitle}
                {...noWrapProps}
              />
              {subMenu && !expanded && <ExpandMore color={toggleIconColor} />}
              {subMenu && expanded && <ExpandLess color={toggleIconColor} />}
            </ListItemButton>
          </ListItem>

          {subMenu && (
            <Collapse in={expanded} timeout="auto">
              <List
                disablePadding
                dense
                sx={{
                  mx: 1.5,
                }}
              >
                {subMenu.map((subElement, i) => {
                  const isSelected = subElement?.path === selectedPath;

                  return (
                    <ListItem
                      key={i + "drawer_list_item"}
                      id={"subMenuItem-" + id}
                      onClick={(e) => {
                        e.preventDefault();
                        handleClick(subElement);
                      }}
                      disablePadding
                    >
                      <ListItemButton
                        component="a"
                        href={path}
                        disabled={disabled}
                        selected={isSelected}
                        sx={{ my: 0.3, ...drawerSx(color) }}
                      >
                        <Box display="flex" alignItems="center">
                          <Box minWidth={30}>
                            {subElement.icon ? (
                              <Box display="flex">
                                <IconRenderer
                                  icon={subElement.icon}
                                  props={{
                                    color: isSelected ? color : "action",
                                    sx: { fontSize: "inherit" },
                                  }}
                                />
                              </Box>
                            ) : (
                              <DotCircle selected={isSelected} color={color} />
                            )}
                          </Box>

                          <ListItemText
                            primary={subElement.title}
                            secondary={subElement.subtitle}
                            {...noWrapProps}
                          />
                        </Box>
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            </Collapse>
          )}
        </div>
      </Fade>
    );
  return null;
};

DrawerListItem.defaultProps = {
  subtitle: undefined,
  disabled: false,
  id: "drawerListItem",
  onClick: undefined,
  selected: false,
  enablePinning: false,
  color: "secondary",
  listItem: {},
};

export default DrawerListItem;

const IconRenderer = ({
  icon,
  props,
}: {
  icon: IconType;
  props: IconProps;
}) => {
  const Icon = icon as React.ElementType<IconProps>;

  if (!icon) return null;
  if (React.isValidElement(icon)) {
    return cloneElement(icon, { ...props });
  } else return <Icon {...props} />;
};

const DotCircle = ({
  selected,
  color,
}: {
  selected: boolean;
  color: Color;
}) => (
  <Box
    sx={{
      height: "7px",
      width: "7px",
      ml: "5px",
      backgroundColor: ({ palette }) =>
        selected ? palette[color].main : alpha("#000", 0.2),
      borderRadius: "50%",
    }}
  />
);
