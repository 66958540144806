import SignOutIcon from "@mui/icons-material/ExitToApp";
import logout from "../lib/logout";

const footerRoutes = () => {
  return [
    {
      title: "Sign Out",
      icon: SignOutIcon,
      onClick: () => logout(),
    },
  ];
};
export default footerRoutes;
