import PropTypes from "prop-types";
import { alpha, Box } from "@mui/material";

import { red } from "@mui/material/colors";

const NcrCell = ({ children, ncr, sx }) => {
  return (
    <Box
      component="span"
      sx={{
        color: ncr ? red[500] : "inherit",
        border: ncr ? `1px solid ${red[500]}` : undefined,
        padding: "3px",
        borderRadius: "4px",
        backgroundColor: ncr ? alpha(red[500], 0.2) : undefined,
        fontWeight: ncr ? 500 : undefined,
        "&:hover": {
          background: ncr ? alpha(red[500], 0.3) : undefined,
        },
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

NcrCell.propTypes = {
  children: PropTypes.node,
  ncr: PropTypes.bool,
  sx: PropTypes.object,
};

export default NcrCell;
