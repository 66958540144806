import { useState, useMemo, useCallback, useEffect } from "react";
import moment from "moment";
import { parse } from "query-string";
import { pickBy } from "lodash";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useNavigate, useLocation } from "react-router";
import { getISOWeek, parseISO, format, isThisWeek } from "date-fns";

import { alpha, Badge, Fade, IconButton } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import FilterIcon from "@mui/icons-material/FilterList";
import { RemoteDataTable } from "@oddadigitalsystem/datatable2";

import queryStringSerialize from "../../../utils/queryStringSerialize";
import { getFiltersFromQueryString } from "./../../../lib/queryStringOperations";

import AppBarButtonWrapper from "../../../components/AppBarButtonWrapper";
import ProjectHighLight from "../../../components/HighLight/ProjectHighLight";
import ProductionHighLight from "../../../components/HighLight/ProductionHighLight";
import FullpageTableScrollContainer from "../../../components/FullpageTableScrollContainer";
import NcrCell from "../../../components/NcrCell";

import LoadingColumns from "./LoadingColumns";
import DeliveryQuantityCell from "./DeliveryQuantityCell";
import DeliveryPlanFilterDrawer from "./filters/DeliveryPlanFilterDrawer";
import loadWeekColumns from "../../../services/project/delivery-plan/loadWeekColumns";
import loadDeliveryPlanData from "../../../services/project/delivery-plan/loadDeliveryPlanData";
import useFilterState from "../../../hooks/useFilterState";

const DEFAULT_FILTERS = {
  start_date: moment().subtract(3, "months").toISOString(),
  end_date: moment().toISOString(),
  project_manager: null,
  customer_po: null,
  customer: null,
};

const generateWeekHeader = (isostring) => {
  const parsedDate = parseISO(isostring);
  const weekNumber = getISOWeek(parsedDate);
  const date = format(parsedDate, "dd.MM.yy");

  return `W: ${weekNumber}
  ${date}`;
};

const wrapHeader = {
  whiteSpace: "pre-line",
  overflowWrap: "break-word",
  lineHeight: 1.2,
  fontSize: 12,
  padding: 6,
};

const cellStyle = { fontSize: 12, padding: 6 };

const DeliveryPlan = () => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const urlParams = useMemo(
    () => parse(location.search, { arrayFormat: "bracket" }),
    [location.search],
  );

  const urlParamsFilters = getFiltersFromQueryString(urlParams);

  const { filters, setFilters, setFilterValue, handleClear } = useFilterState({
    ...DEFAULT_FILTERS,
    ...urlParamsFilters,
  });

  const [tableRefreshed, refreshTable] = useState();
  const tableRefreshDependency = `${tableRefreshed}`;

  const [loading, setLoading] = useState(false);

  const [weekColumns, setWeekColumns] = useState({});
  const [columnsLoading, setColumnsLoading] = useState(false);

  const hasWeeks = Object.keys(weekColumns).length > 0;
  const amountOfFiltersApplied = Object.entries(pickBy(filters))?.length ?? 0;
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

  const fetchWeekColumns = useCallback(
    (newFilters, forceRefreshTable) => {
      setColumnsLoading(true);
      loadWeekColumns({ filter: pickBy(newFilters) })
        .then((columns) => {
          setWeekColumns(columns);
        })
        .catch((error) => {
          enqueueSnackbar(
            `${t("Failed to load the table columns")}: ${error}`,
            {
              variant: "error",
            },
          );
        })
        .finally(() => {
          setColumnsLoading(false);
          forceRefreshTable && refreshTable(new Date().getTime());
        });
    },
    [enqueueSnackbar, t],
  );

  const handleDeliveryPlans = useCallback(
    ({ search, ...parameters }) => {
      const mergedParams = {
        ...parameters,
        filter: {
          ...parameters?.filter,
          ...pickBy(filters),
        },
        sort: parameters.sort || {},
      };

      if (search) mergedParams.filter.search = search;
      const queryString = queryStringSerialize(mergedParams);
      navigate({ search: queryString });
      setLoading(true);

      return loadDeliveryPlanData(queryString)
        .catch((e) => {
          console.log(e);
          enqueueSnackbar(
            `${t("Failed to load delivery plans data")}: ${e?.toString()}`,
            { variant: "error" },
          );
          return e;
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [enqueueSnackbar, filters, navigate, t],
  );

  const handleUpdateFilters = (newFilters) => {
    const queryString = queryStringSerialize({ filter: pickBy(newFilters) });
    navigate({ search: queryString });
    setFilters(newFilters);
    setFilterDrawerOpen(false);
    fetchWeekColumns(newFilters, true);
  };

  useEffect(() => {
    fetchWeekColumns(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <AppBarButtonWrapper showSpinner={loading || columnsLoading}>
        <IconButton
          onClick={() => setFilterDrawerOpen((oldState) => !oldState)}
          disabled={loading}
          size="large"
        >
          <Badge badgeContent={amountOfFiltersApplied} color={"primary"}>
            <FilterIcon />
          </Badge>
        </IconButton>
        <IconButton
          onClick={() => refreshTable(new Date().getTime())}
          disabled={loading}
          size="large"
        >
          <RefreshIcon />
        </IconButton>
      </AppBarButtonWrapper>

      <DeliveryPlanFilterDrawer
        filters={pickBy(filters)}
        open={filterDrawerOpen}
        onSubmit={handleUpdateFilters}
        onClose={() => setFilterDrawerOpen(false)}
        onFilterChange={setFilterValue}
        onClear={() => handleClear(DEFAULT_FILTERS)}
      />

      <FullpageTableScrollContainer>
        {columnsLoading && <LoadingColumns />}
        <Fade in={!columnsLoading} timeout={200}>
          <div>
            {hasWeeks && (
              <RemoteDataTable
                key={tableRefreshDependency}
                size="small"
                dataFetchCallback={handleDeliveryPlans}
                enableSearch
                initialColumns={[
                  {
                    disableSorting: true,
                    key: "description",
                    name: t("Description"),
                    columnStyle: { ...wrapHeader },
                    cellStyle: { ...cellStyle },
                  },
                  {
                    disableSorting: true,
                    key: "part_number",
                    name: t("Part Number"),
                    columnStyle: { ...wrapHeader },
                    cellStyle: { ...cellStyle },
                  },
                  {
                    disableSorting: true,
                    key: "production_order",
                    name: t("Production Order"),
                    columnStyle: { ...wrapHeader },
                    cellStyle: { ...cellStyle },
                    format: (v, { ncr }) => (
                      <NcrCell ncr={ncr}>
                        <ProductionHighLight
                          LinkProps={{ color: "inherit", underline: "none" }}
                        >
                          {v}
                        </ProductionHighLight>
                      </NcrCell>
                    ),
                  },
                  {
                    disableSorting: true,
                    key: "project_number",
                    name: t("Project Number"),
                    columnStyle: { ...wrapHeader },
                    cellStyle: { ...cellStyle },
                    format: (i, v) => (
                      <ProjectHighLight isDelivery={true} task={v.task_number}>
                        {v.project_number}
                      </ProjectHighLight>
                    ),
                  },
                  {
                    disableSorting: true,
                    key: "project_name",
                    name: t("Project Name"),
                    columnStyle: { ...wrapHeader },
                    cellStyle: { ...cellStyle },
                  },
                  ...Object.entries(weekColumns.total_weeks)?.map(
                    ([key, { date }]) => {
                      const isCurrentWeek = isThisWeek(parseISO(date));
                      const highlightColor = "rgba(255, 210, 0)";

                      const highlightBackgroundColor = isCurrentWeek
                        ? alpha(highlightColor, 0.2)
                        : undefined;

                      return {
                        disableSorting: true,
                        key,
                        name: generateWeekHeader(date),
                        columnStyle: {
                          ...wrapHeader,
                          fontSize: 12,
                        },
                        cellStyle: {
                          // make the content fit the cell
                          height: "1em",
                          padding: "0px",
                          borderBottom: "none",
                          borderLeft: `1px solid rgba(224, 224, 224, 1)`,
                          backgroundColor: highlightBackgroundColor,
                        },
                        format: (v) => <DeliveryQuantityCell {...v} />,
                      };
                    },
                  ),
                ]}
              />
            )}
          </div>
        </Fade>
      </FullpageTableScrollContainer>
    </>
  );
};

export default DeliveryPlan;
