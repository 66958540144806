import authHeaders from "../../../lib/authHeaders";
import queryStringSerialize from "../../../utils/queryStringSerialize";

interface ApiWeekColumns {
  this_week: string;
  total_weeks: {
    [key: string]: {
      date: string;
      total: string;
    };
  };
}

const loadWeekColumns = async (filters: any): Promise<ApiWeekColumns> => {
  return fetch(
    `${
      import.meta.env.VITE_VTS_API
    }/v1/project/delivery_plans/week_columns?${queryStringSerialize(filters)}`,
    {
      headers: authHeaders(),
    },
  ).then((response) => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error(`Error ${response.status}. Failed to load the filters.`);
    }
  });
};

export default loadWeekColumns;
