import { InputAdornment, CircularProgress, Box } from "@mui/material";

const asyncOptionsAdornment = (
  data,
  loading,
  disabled,
  disabledText = "Select previous event",
) => {
  const length = data?.length;

  const valueToShow = () => {
    if (loading) return <CircularProgress size={18} disableShrink />;
    if (disabled) return disabledText;
    if (typeof length === "number") return length + " options";
    else return <Box sx={{ color: "error.main" }}>Error</Box>;
  };

  return {
    endAdornment: (
      <InputAdornment
        position="start"
        disableTypography
        sx={{ fontSize: 12, pr: 1.5, pl: 1 }}
      >
        {valueToShow()}
      </InputAdornment>
    ),
  };
};

export default asyncOptionsAdornment;
