import { alpha, Box } from "@mui/material";
import humanizeSnakeCase from "@/lib/humanizeSnakeCase";
import ColoredTooltip from "@/components/ColoredTooltip";
import getRoutingStatusColor from "@/utils/getRoutingStatusColor";

const RoutingStatusCell = ({ status, value }) => {
  const { bg, border, font } = getRoutingStatusColor(status);

  const backgroundColor = alpha(bg, 0.9);
  const borderBottom = `1px solid ${border}`;
  const capitalizedStatus = humanizeSnakeCase(status ?? "");

  return (
    <ColoredTooltip
      title={`${capitalizedStatus}`}
      baseColor={bg}
      placement="left"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor,
          color: font,
          height: "100%",
          width: "100%",
          fontSize: "inherit",
          borderBottom,
        }}
      >
        {value}
      </Box>
    </ColoredTooltip>
  );
};

export default RoutingStatusCell;
