import PropTypes from "prop-types";
import React from "react";

import DownloadFileButton from "@/components/DownloadFileButton";
import AppBarButtonWrapper from "@/components/AppBarButtonWrapper";

const ItemsToBuyButtons = ({ filters, loading }) => {
  return (
    <AppBarButtonWrapper showSpinner={loading}>
      <DownloadFileButton
        loading={loading}
        urlEndpoint={`${import.meta.env.VITE_VTS_API}/v1/purchase/items_to_buy`}
        filename="Items to buy"
        fileExtension="csv"
        filters={filters}
      />
    </AppBarButtonWrapper>
  );
};

ItemsToBuyButtons.propTypes = {
  // parsed url params
  filters: PropTypes.object,
  loading: PropTypes.bool,
};

export default ItemsToBuyButtons;
