import ItemNumberHighLight from "@/components/HighLight/ItemNumberHighLight";
import NcrCell from "@/components/NcrCell";

const ItemCell = ({ item, ncr, project_no }) => {
  return (
    <ItemNumberHighLight
      value={item}
      LinkProps={{ underline: "none" }}
      project_no={project_no}
    >
      <NcrCell ncr={ncr}>{item}</NcrCell>
    </ItemNumberHighLight>
  );
};

export default ItemCell;
