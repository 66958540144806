import PropTypes from "prop-types";
import { useRef, useMemo, useState, forwardRef } from "react";
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  Box,
  MenuItem,
  IconButton,
  Chip,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  FormLabel,
  CardActionArea,
  Fade,
  CircularProgress,
  Tooltip,
  Grow,
} from "@mui/material";
import * as Yup from "yup";
//TODO: Update picker implementation
import { useReactToPrint } from "react-to-print";
import { Modal, ModalGateway } from "react-images";
import { Formik, Form, Field } from "formik";
import searchNewSelect from "@/lib/searchNewSelect";
import MaterialCarousel from "@/components/MaterialCarousel";
import NewSelectAutocomplete from "@/components/NewSelectAutocomplete";
import PrintComment from "@/pages/project/comments/form/PrintComment";
import DateTimePickerField from "@/pages/project/comments/form/DateTimePickerField";
//icons
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import PrintIcon from "@mui/icons-material/Print";
import DeleteIcon from "@mui/icons-material/Delete";

const statusOptions = {
  Hold: "Hold",
  Done: "Done",
  Merket: "Marked",
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const ImageBox = ({ src, name, onDelete }) => {
  const [loading, setLoading] = useState(true);
  const [hover, setHover] = useState(false);

  return (
    <>
      <Fade in={!loading} timeout={500}>
        <span
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          style={{ width: "100%", height: "100%" }}
        >
          <img
            src={src}
            alt={name}
            onLoad={() => setLoading(false)}
            style={{
              objectFit: "cover",
              height: "100%",
              width: "100%",
              transform: hover ? "scale(1.07)" : "scale(1)",
              transitionDuration: "0.2s",
            }}
          />

          <Tooltip title={name} enterDelay={500} enterNextDelay={500} arrow>
            <div>
              <ImageListItemBar
                title={
                  <Chip
                    label={name}
                    size="small"
                    color={onDelete ? "primary" : "default"}
                    style={{
                      width: "100%",
                      backgroundColor: onDelete ? null : "white",
                      color: onDelete ? "white" : null,
                    }}
                    onDelete={onDelete}
                    deleteIcon={<DeleteIcon style={{ color: "white" }} />}
                  />
                }
                style={{
                  background:
                    "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0) 100%)",
                }}
              />
            </div>
          </Tooltip>
        </span>
      </Fade>
    </>
  );
};

ImageBox.propTypes = {
  name: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
  src: PropTypes.string,
};

const CommentsForm = ({
  open,
  onClose,
  data,
  onSubmit,
  onDelete,
  enableStatusField,
  debug,
}) => {
  const [galleryIndex, setGalleryIndex] = useState(null);

  const printRef = useRef();
  const imageInputRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const fetchProjects = useMemo(() => {
    return searchNewSelect("project_number");
  }, []);

  return (
    <>
      <PrintComment data={data} ref={printRef} />
      <Formik
        initialValues={{
          source: data?.source || "internal",
          createdAt: data?.createdAt
            ? new Date(data.createdAt).toISOString()
            : new Date().toISOString(),
          projectNumber: data?.projectNumber
            ? { value: data.projectNumber, text: "" }
            : { value: "", text: "" },
          comment: data?.comment || "",
          images: data?.images || [],
          id: data?.id || "",
          reference: data?.reference || "",
          reference2: data?.reference2 || "",
          uploadedImages: [],
        }}
        validationSchema={Yup.object().shape({
          source: Yup.mixed().oneOf(["internal", "external"]),
          createdAt: Yup.string().required("Field is required"),
          projectNumber: Yup.object()
            .shape({
              value: Yup.string().required("Field is required"),
              text: Yup.string(),
            })
            .nullable()
            .required("Field is required"),
          comment: Yup.string().required("Field is required"),
          images: Yup.mixed(),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          onSubmit && onSubmit(values);
        }}
      >
        {({
          values,
          errors,
          isSubmitting,
          setFieldValue,
          setFieldError,
          isValid,
          setSubmitting,
        }) => {
          const mergedImages = [...values.uploadedImages, ...values.images];

          return (
            <>
              <ModalGateway>
                {Number.isInteger(galleryIndex) ? (
                  <Modal
                    onClose={() => setGalleryIndex(null)}
                    styles={{
                      blanket: (base, _state) => ({
                        ...base,
                        zIndex: 1500,
                      }),
                      positioner: (base, _state) => ({ ...base, zIndex: 1500 }),
                      dialog: (base, _state) => ({ ...base, zIndex: 1500 }),
                    }}
                  >
                    <MaterialCarousel
                      currentIndex={galleryIndex}
                      views={mergedImages.map((image) => {
                        const isUploaded = image instanceof File;
                        const src = isUploaded
                          ? URL.createObjectURL(image)
                          : image.src;
                        return { source: src, caption: image.name };
                      })}
                    />
                  </Modal>
                ) : null}
              </ModalGateway>

              <Dialog
                open={open}
                onClose={onClose}
                maxWidth="sm"
                fullWidth
                disableEnforceFocus
                TransitionComponent={Transition}
              >
                <Box display="flex" alignItems="center">
                  <DialogTitle style={{ flex: 1 }}>
                    {data?.id ? "Edit comment" : "Add comment"}
                  </DialogTitle>

                  <Box mr={1}>
                    {data?.id && (
                      <>
                        <IconButton
                          onClick={() => {
                            if (onDelete) {
                              setSubmitting(true);
                              onDelete(data.id);
                            }
                          }}
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <CircularProgress size={16} color="primary" />
                          ) : (
                            <DeleteIcon />
                          )}
                        </IconButton>
                        <IconButton
                          onClick={handlePrint}
                          disabled={isSubmitting}
                        >
                          <PrintIcon />
                        </IconButton>
                      </>
                    )}

                    <IconButton onClick={() => onClose && onClose()}>
                      <CancelIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Form
                  id="commentForm"
                  noValidate
                  style={{
                    overflowY: "auto",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <DialogContent dividers>
                    <Box mb={3}>
                      {values?.reference && (
                        <Chip
                          icon={<InfoOutlinedIcon />}
                          label={`Reference: ${values.reference}`}
                          style={{ marginRight: 10 }}
                        />
                      )}
                      {values?.reference2 && (
                        <Chip
                          icon={<InfoOutlinedIcon />}
                          label={`Reference 2: ${values.reference2}`}
                        />
                      )}
                    </Box>
                    <Box display="flex">
                      <Box flex={1} mr={1}>
                        <Field
                          as={TextField}
                          size="small"
                          name="source"
                          fullWidth
                          variant="outlined"
                          label="Source"
                          select
                          required
                          error={Boolean(errors.source)}
                          helperText={errors.source}
                        >
                          <MenuItem value="internal">Internal</MenuItem>
                          <MenuItem value="external">External</MenuItem>
                        </Field>
                      </Box>
                      <Box flex={1} ml={1}>
                        <Field
                          name="createdAt"
                          component={DateTimePickerField}
                          label={"Created at"}
                          inputProps={{
                            fullWidth: true,
                            size: "small",
                            required: true,
                          }}
                          disablePast={!values.id}
                          disabled={data?.createdAt}
                          ampm={false}
                          format="DD.MM.yyyy HH:mm"
                        />
                      </Box>
                    </Box>
                    <Box mt={2}>
                      <NewSelectAutocomplete
                        size="small"
                        label="Project number"
                        optionsCallBack={fetchProjects}
                        onChange={(value) =>
                          setFieldValue("projectNumber", value)
                        }
                        value={values.projectNumber}
                        fullWidth
                        variant="outlined"
                        required
                        error={Boolean(
                          errors.projectNumber?.value || errors.projectNumber,
                        )}
                        helperText={
                          errors.projectNumber?.value || errors.projectNumber
                        }
                      />
                    </Box>
                    {enableStatusField && (
                      <Box mt={2}>
                        <TextField
                          size="small"
                          name="commentSelect"
                          fullWidth
                          variant="outlined"
                          label="Status"
                          onChange={(e) =>
                            setFieldValue("comment", e.target.value)
                          }
                          value={
                            Object.keys(statusOptions).includes(values.comment)
                              ? values.comment
                              : ""
                          }
                          select
                          InputLabelProps={{
                            shrink: true,
                          }}
                          SelectProps={{
                            displayEmpty: true,
                            renderValue: (selected) => {
                              if (
                                !Object.keys(statusOptions).includes(
                                  selected,
                                ) ||
                                selected === ""
                              ) {
                                return "Comment";
                              }

                              return statusOptions[selected];
                            },
                          }}
                        >
                          <MenuItem value="">Comment</MenuItem>
                          <MenuItem value="Hold">Hold</MenuItem>
                          <MenuItem value="Merket">Marked</MenuItem>
                          <MenuItem value="Done">Done</MenuItem>
                        </TextField>
                      </Box>
                    )}
                    <Box mt={2}>
                      {enableStatusField &&
                      Object.keys(statusOptions).includes(
                        values.comment,
                      ) ? null : (
                        <Field
                          as={TextField}
                          label="Comment"
                          name="comment"
                          multiline
                          fullWidth
                          variant="outlined"
                          required
                          minRows={5}
                          error={Boolean(errors.comment)}
                          helperText={errors.comment}
                          inputProps={{ sx: { resize: "vertical" } }}
                        />
                      )}
                    </Box>
                    <Box mt={2}>
                      <Box mt={2}>
                        <Box display="flex" alignItems="center" mb={2}>
                          <FormLabel style={{ flex: 1 }}>
                            {mergedImages?.length === 0 ? " " : "Images"}
                          </FormLabel>

                          <Button
                            id="addImage"
                            onClick={() => imageInputRef.current.click()}
                            variant="outlined"
                            startIcon={<ImageOutlinedIcon />}
                          >
                            {"Select images"}
                          </Button>
                        </Box>
                        <input
                          hidden
                          ref={imageInputRef}
                          type="file"
                          accept="image/png, image/gif, image/jpeg"
                          multiple={true}
                          onChange={(event) =>
                            setFieldValue("uploadedImages", [
                              ...event.target.files,
                              ...values.uploadedImages,
                            ])
                          }
                        />
                        <ImageList rowHeight={120} cols={4} gap={15}>
                          {mergedImages.map((image, i) => {
                            const isUploaded = image instanceof File;

                            const src = isUploaded
                              ? URL.createObjectURL(image)
                              : image.src;

                            const name = image.name;

                            const handleRemoveImage = () => {
                              setFieldValue(
                                "uploadedImages",
                                values.uploadedImages.filter(
                                  (file) => file.name !== name,
                                ),
                              );
                            };

                            return (
                              <ImageListItem
                                key={i + name}
                                onClick={() => setGalleryIndex(i)}
                                component={CardActionArea}
                                sx={{
                                  borderRadius: 2,
                                  overflow: "hidden",
                                }}
                              >
                                <ImageBox
                                  src={src}
                                  name={name}
                                  onDelete={
                                    isUploaded
                                      ? () => handleRemoveImage()
                                      : null
                                  }
                                />
                              </ImageListItem>
                            );
                          })}
                        </ImageList>
                      </Box>
                    </Box>
                    {debug && (
                      <pre>
                        <code>
                          {JSON.stringify({ errors, values, data }, null, "\t")}
                        </code>
                      </pre>
                    )}
                  </DialogContent>
                </Form>
                <DialogActions>
                  <Button onClick={() => onClose && onClose()} color="inherit">
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    color="primary"
                    form="commentForm"
                    disabled={isSubmitting || !isValid}
                  >
                    {data?.id ? "Update" : "Create"}
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          );
        }}
      </Formik>
    </>
  );
};

CommentsForm.defaultProps = {
  enableStatusField: false,
};

CommentsForm.propTypes = {
  data: PropTypes.shape({
    comment: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    images: PropTypes.array,
    projectNumber: PropTypes.string.isRequired,
    reference: PropTypes.string,
    reference2: PropTypes.string,
    source: PropTypes.string.isRequired,
  }),
  debug: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool,
  enableStatusField: PropTypes.bool,
};

export default CommentsForm;
