import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Fade,
} from "@mui/material";
import { Skeleton } from "@mui/material";

const CellValue = ({ loading, value }) =>
  loading ? (
    <Box>
      <Skeleton />
    </Box>
  ) : (
    <Fade in timeout={500}>
      <span>{value}</span>
    </Fade>
  );

export const WeldTable = ({
  wps,
  rootMaterial,
  fillerMaterial,
  capMaterial,
  welderId,
  date,
  loading,
}) => {
  const head = [
    "WPS",
    "Root material",
    "Filler material",
    "Cap material",
    "Welder",
    "Date",
  ];

  return (
    <Paper variant="outlined" style={{ overflow: "hidden" }}>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {head?.map((key) => (
                <TableCell key={key} align="center">
                  {key}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align="center" style={{ borderBottom: 0 }}>
                <CellValue value={wps} loading={loading} />
              </TableCell>
              <TableCell align="center" style={{ borderBottom: 0 }}>
                <CellValue value={rootMaterial} loading={loading} />
              </TableCell>

              <TableCell align="center" style={{ borderBottom: 0 }}>
                <CellValue value={fillerMaterial} loading={loading} />
              </TableCell>

              <TableCell align="center" style={{ borderBottom: 0 }}>
                <CellValue value={capMaterial} loading={loading} />
              </TableCell>

              <TableCell align="center" style={{ borderBottom: 0 }}>
                <CellValue value={welderId} loading={loading} />
              </TableCell>

              <TableCell align="center" style={{ borderBottom: 0 }}>
                <CellValue
                  value={new Date(date).toLocaleDateString()}
                  loading={loading}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
