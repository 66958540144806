import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router";
import getOpenNCRsColumns from "./getOpenNCRsColumns";
import useTable from "@/components/datatable/useTable";
import MaterialToolbar from "@/components/datatable/MaterialToolbar";
import MaterialDataTable from "@/components/datatable/MaterialDataTable";
import { Card, TablePagination, Typography, CardContent } from "@mui/material";
import Alert from "@mui/material/Alert";
import { useQuery } from "@tanstack/react-query";
import authHeaders from "@/lib/authHeaders";
import LinkHeader from "@/components/LinkHeader";

const OpenNCRs = () => {
  const { error, data, isLoading } = useQuery({
    queryKey: ["dashboard-ncrs"],
    queryFn: () =>
      fetch(`${import.meta.env.VITE_VTS_API}/v1/dashboard/ncr`, {
        headers: authHeaders(),
      }).then((response) => response.json()),
  });

  const navigate = useNavigate();
  const columns = useMemo(() => getOpenNCRsColumns(navigate), [navigate]);

  const { handlers, state, setters } = useTable({
    columns: columns,
    options: {
      multiSort: false,
      mode: "client",
      debug: false,
    },
  });

  const {
    handleApplyFilter,
    handleRemoveFilter,
    handleSearch,
    handlePageChange,
    handleChangeRowsPerPage,
    handleToggleSort,
  } = handlers;

  const { page, rows, rowsPerPage, getPaginatedRows } = state;

  const { setRows } = setters;

  useEffect(() => {
    setRows(data?.ncrs || []);
  }, [data, setRows]);

  if (error) {
    return (
      <Card variant="outlined" sx={{ px: 3, py: 3 }}>
        <Typography variant="p">Open NCRs</Typography>
        <CardContent
          sx={{ height: 420, verticalAlign: "top", pt: 2, px: 0, pb: 0 }}
        >
          <Alert severity="error">{error?.message}</Alert>
        </CardContent>
      </Card>
    );
  }

  const todayISO = new Date().toISOString().split("T")[0];
  const openNcrUrl = `/ncr/list?closed=false&end-date=${todayISO}&start-date=2016-01-01`;

  return (
    <Card variant="outlined">
      <MaterialToolbar
        title={<LinkHeader title="Open NCRs" to={openNcrUrl} />}
        columns={state.columns}
        onSearch={handleSearch}
        onRemoveFilter={handleRemoveFilter}
        initialSearch={state.search}
      />
      <MaterialDataTable
        onApplyFilter={handleApplyFilter}
        onRemoveFilter={handleRemoveFilter}
        onToggleSort={(_e, column) => handleToggleSort(column.key)}
        enableFiltering
        {...state}
        loading={isLoading}
        rows={getPaginatedRows()}
        TableContainerProps={{
          sx: { height: 351 },
        }}
        TableProps={{ size: "small" }}
      />
      <TablePagination
        component="div"
        rowsPerPageOptions={[5, 10, 20, 50, { value: -1, label: "All" }]}
        rowsPerPage={rowsPerPage}
        count={rows.length}
        page={page}
        onPageChange={(e, newIndex) => handlePageChange(newIndex)}
        onRowsPerPageChange={(e) => {
          handleChangeRowsPerPage(parseInt(e.target.value));
        }}
        disabled={rows.length <= 0}
        sx={{ m: 1 }}
      />
    </Card>
  );
};

export default OpenNCRs;
