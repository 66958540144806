import { Typography } from "@mui/material";
import React from "react";
import CardBase from "@/pages/home/components/CardBase";

const ReceiptControl = ({ itemsInControl, qtyInControl, loading, error }) => {
  const noData = <Typography variant="h6">No data.</Typography>;

  const content = (
    <>
      <Typography variant="h4">{itemsInControl}</Typography>
      <small>Item numbers in receipt control, representing</small>
      <Typography variant="h4">{qtyInControl}</Typography>
      <small>UOM waiting to be released.</small>
    </>
  );

  return (
    <CardBase
      title="Receipt Control"
      content={itemsInControl ? content : noData}
      actionTitle="See Items"
      actionHref="/items/receipt-control"
      loading={loading}
      error={error}
    />
  );
};

export default ReceiptControl;
