import {
  TableCell,
  TableCellProps,
  TableRow,
  TableRowProps,
} from "@mui/material";
import React, { memo } from "react";
import { ColumnDefinition, RowDefinition } from "@/types";
import RefreshAtEnd from "./RefreshAtEnd";

interface MaterialTableBodyProps {
  rows: RowDefinition[];
  columns: ColumnDefinition[];
  rowPropsFn?: (rowIndex: number, row: RowDefinition) => TableRowProps;
  onRowClick?: (row: RowDefinition) => void;
  loading: boolean;
  error: boolean;
  onScrollEnd?: () => void;
  totalRows: number;
  TableCellProps?: TableCellProps;
}

function MaterialTableBody({
  rows,
  columns,
  rowPropsFn,
  onRowClick,
  loading,
  error,
  onScrollEnd,
  totalRows,
  TableCellProps = {},
}: MaterialTableBodyProps) {
  const MemoizedRefreshAtEnd = memo(() => {
    return <RefreshAtEnd onVisible={() => onScrollEnd?.()} />;
  });

  const colSpan = columns?.length ?? 999;

  if (loading && rows?.length === 0) return <LoadingRow colSpan={colSpan} />;
  if (error) return <ErrorRow colSpan={colSpan} />;
  if (!rows?.length) return <EmptyRow colSpan={colSpan} />;

  return (
    <>
      {rows.map((row: RowDefinition, rowIndex: number) => {
        const rowProps: TableRowProps = rowPropsFn?.(rowIndex, row) ?? {};

        return (
          <TableRow
            key={rowIndex}
            hover={onRowClick ? true : false}
            onClick={() => onRowClick?.(row)}
            {...rowProps}
          >
            {columns.map((column: ColumnDefinition, columnIndex: number) => {
              const value = row[column.key];
              const cellProps: TableCellProps = {
                ...TableCellProps,
                ...column?.cellPropsFn?.(value, row),
              };

              const displayValue = column?.renderCellFn?.(value, row) ?? value;

              if (column.hide) return null;

              return (
                <TableCell key={columnIndex} {...cellProps}>
                  {displayValue}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}
      {onScrollEnd && totalRows !== Infinity && totalRows > rows.length && (
        <MemoizedRefreshAtEnd />
      )}
    </>
  );
}

export default MaterialTableBody;

const ErrorRow = ({ colSpan = 999 }) => (
  <TableRow>
    <TableCell colSpan={colSpan} align="center">
      Error
    </TableCell>
  </TableRow>
);

const EmptyRow = ({ colSpan = 999 }) => (
  <TableRow>
    <TableCell colSpan={colSpan} align="center">
      No results
    </TableCell>
  </TableRow>
);

const LoadingRow = ({ colSpan = 999 }) => {
  return (
    <TableRow>
      <TableCell colSpan={colSpan} align="center">
        Loading data...
      </TableCell>
    </TableRow>
  );
};
