import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import {
  Box,
  Card,
  Typography,
  MenuItem,
  IconButton,
  Menu,
  TextField,
  Button,
  Fade,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTheme } from "@mui/styles";

const EditComment = ({ onDelete, onEdit, isEditing }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = (event) => {
    handleClose();
    onDelete && onDelete();
  };

  const handleEdit = (event) => {
    handleClose();
    onEdit && onEdit();
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      >
        <MoreVertIcon style={{ fontSize: "inherit" }} />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {!isEditing && (
          <MenuItem dense onClick={handleEdit}>
            Edit
          </MenuItem>
        )}

        <MenuItem dense onClick={handleDelete}>
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
};

EditComment.propTypes = {
  isEditing: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

const CommentUser = ({ user, timestamp }) => (
  <Box>
    <Typography
      component="span"
      color="primary"
      style={{ fontSize: 14, fontWeight: 500 }}
    >
      {user}
    </Typography>

    <Typography
      variant="subtitle2"
      component="span"
      color="textSecondary"
      style={{ fontSize: 14, fontWeight: 500 }}
    >
      {" • " + new Date(timestamp).toLocaleDateString()}
    </Typography>
  </Box>
);

CommentUser.propTypes = {
  timestamp: PropTypes.string,
  user: PropTypes.string,
};

const CommentCard = ({
  timestamp,
  user,
  text,
  onDelete,
  onUpdate,
  hidden,
  isNew,
  parentIsEditing,
}) => {
  const theme = useTheme();
  const [edit, setEdit] = useState(false);
  const [textValue, setTextValue] = useState(text);

  const isDisabled = !textValue || textValue === text;

  useEffect(() => {
    parentIsEditing && setEdit(false);
  }, [parentIsEditing]);

  if (hidden) return null;
  else
    return (
      <Card
        variant="outlined"
        sx={{
          pt: edit ? 2 : 1,
          pb: edit ? 0 : 1,
          px: edit ? 2 : 1,
          my: edit ? 1.5 : 1,
          borderRadius: edit ? 2 : undefined,
          transition: "200ms",
          borderLeft:
            !edit && isNew
              ? `2px solid ${theme.palette.primary.main}`
              : undefined,
        }}
      >
        <Box display="flex">
          <Box flex={1} ml={edit ? 1 : 0}>
            <CommentUser user={user} timestamp={timestamp} />
          </Box>

          <EditComment
            isEditing={edit}
            onDelete={() => onDelete()}
            onEdit={() => {
              setEdit(true);
            }}
          />
        </Box>

        {edit ? (
          <Box my={1} mx={edit ? 1 : 0}>
            <TextField
              multiline
              variant="outlined"
              value={textValue}
              fullWidth
              label="Edit description"
              onChange={(e) => setTextValue(e.target.value)}
              size="small"
              minRows={4}
              maxRows={10}
              inputProps={{ sx: { resize: "vertical" } }}
            />
            <Box display="flex" mt={1}>
              <Box ml="auto">
                <Button
                  size="small"
                  onClick={() => {
                    setEdit(false);
                    setTextValue(text);
                  }}
                  color="inherit"
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  color="primary"
                  disabled={isDisabled}
                  onClick={() => {
                    setEdit(false);
                    onUpdate(textValue);
                  }}
                >
                  Update
                </Button>
              </Box>
            </Box>
          </Box>
        ) : (
          <Fade in timeout={500}>
            <Typography style={{ fontSize: 14, whiteSpace: "pre-line" }}>
              {text}
            </Typography>
          </Fade>
        )}
      </Card>
    );
};

CommentCard.propTypes = {
  hidden: PropTypes.bool,
  isNew: PropTypes.bool,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
  text: PropTypes.string,
  timestamp: PropTypes.string,
  user: PropTypes.string,
};

export default CommentCard;
