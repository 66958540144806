import React from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useNavigate } from "react-router";
import logout from "@/lib/logout";
import ResponsiveButtons from "@/components/ResponsiveButtons";

const HomeButtons = () => {
  //Redirect utilities
  const navigate = useNavigate();
  const { railsUserId } = JSON.parse(localStorage.getItem("msToken"));

  const options = [
    {
      title: "Profile",
      onClick: (e) => navigate(`/profile/${railsUserId}`),
      icon: <AccountCircleIcon />,
    },
    {
      title: "Sign out",
      onClick: logout,
      icon: <ExitToAppIcon />,
    },
  ];

  return <ResponsiveButtons options={options} />;
};

export default HomeButtons;
