import PropTypes from "prop-types";
import { Chip, Divider, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { forwardRef } from "react";

const useStyles = makeStyles((theme) => ({
  print: {
    display: "none",
    "@media print": {
      display: "block",
      margin: "40px",
    },
  },
}));

const TextLine = ({ title, value }) => {
  if (value)
    return (
      <>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 10,
            marginBottom: 10,
          }}
        >
          <Typography style={{ flex: 1, fontWeight: 500, fontSize: "inherit" }}>
            {title}
          </Typography>
          <Typography style={{ minWidth: "85%", textAlign: "left" }}>
            {value}
          </Typography>
        </div>
        <Divider />
      </>
    );
  else return null;
};

TextLine.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
};

const PrintComment = forwardRef(({ data }, ref) => {
  const classes = useStyles();
  return (
    <div ref={ref} className={classes.print}>
      <TextLine
        title="Date:"
        value={new Date(data?.createdAt).toLocaleString("nb")}
      />
      <TextLine title="Source:" value={data?.source} />
      <TextLine title="Project:" value={data?.projectNumber} />
      <TextLine title="Reference:" value={data?.reference} />
      <TextLine title="Reference 2:" value={data?.reference2} />
      <TextLine title="User:" value={data?.user} />
      <TextLine title="Comment:" value={data?.comment} />
      {data?.images?.length > 0 && (
        <>
          <Typography
            style={{
              fontWeight: 500,
              fontSize: "inherit",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            Images:
          </Typography>
          <div style={{ textAlign: "center" }}>
            {data?.images.map((image, i) => (
              <div key={i + "print_comment"}>
                <br />
                <img src={image.src} alt={image.name} width="500px" />
                <br />
                <Chip
                  label={image.name}
                  variant="outlined"
                  style={{ marginTop: 20 }}
                />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
});

PrintComment.propTypes = {
  data: PropTypes.shape({
    comment: PropTypes.string,
    createdAt: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        src: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    projectNumber: PropTypes.string.isRequired,
    reference: PropTypes.string,
    reference2: PropTypes.string,
    source: PropTypes.string.isRequired,
    user: PropTypes.string.isRequired,
  }),
};
export default PrintComment;
