import PropTypes from "prop-types";
import React from "react";
import {
  IconButton,
  List,
  ListItem,
  ListItemText,
  Fade,
  Divider,
  Tooltip,
  Box,
  Skeleton,
} from "@mui/material";

const PlaceholderMessage = ({ ...props }) => (
  <ListItem disabled style={{ textAlign: "inherit" }}>
    <ListItemText {...props} />
  </ListItem>
);

const LoadingListItem = ({ showActionLoading, compactLayout }) => (
  <Fade in>
    <ListItem
      style={{
        height: compactLayout ? 61 : 69,
        maxHeight: compactLayout ? 61 : 69,
      }}
      divider
    >
      <ListItemText
        primary={
          <Skeleton
            width="20%"
            height={10}
            style={{ transform: "none", marginBottom: 10 }}
          />
        }
        secondary={
          <Skeleton width="60%" height={10} style={{ transform: "none" }} />
        }
      />
      {showActionLoading && (
        <Skeleton
          width={20}
          height={20}
          style={{ transform: "none", marginRight: 3 }}
        />
      )}
    </ListItem>
  </Fade>
);

const ItemNumberList = ({
  compactLayout,
  data,
  error,
  listItemActionProps,
  listProps,
  loading,
  placeholderComponent: PlaceholderComponent,
  errorComponent: ErrorComponent,
  notFoundComponent: NotFoundComponent,
  translations,
  numberOfLoadingLines,
  onClick,
  dataKeys,
  searchValue,
}) => {
  return (
    <>
      {searchValue && data?.length > 0 && <Divider />}

      {!searchValue && !loading && (
        <Fade in={!searchValue} timeout={500}>
          <Box textAlign="center">
            {PlaceholderComponent ? (
              <PlaceholderComponent />
            ) : (
              <PlaceholderMessage
                primary={translations.placeholderPrimary}
                secondary={translations.placeholderSecondary}
              />
            )}
          </Box>
        </Fade>
      )}

      {error && searchValue && !data?.length && !loading && (
        <Fade in={true} timeout={500}>
          <Box textAlign="center">
            {ErrorComponent ? (
              <ErrorComponent />
            ) : (
              <PlaceholderMessage
                primary={translations.errorPrimary}
                secondary={translations.errorSecondary}
              />
            )}
          </Box>
        </Fade>
      )}

      {!error && searchValue && !data?.length && !loading && (
        <Fade in={true} timeout={500}>
          <Box textAlign="center">
            {NotFoundComponent ? (
              <NotFoundComponent />
            ) : (
              <PlaceholderMessage
                primary={translations.noResultsPrimary}
                secondary={translations.noResultsSecondary}
              />
            )}
          </Box>
        </Fade>
      )}

      <Fade in>
        <List
          disablePadding
          autoFocus={searchValue}
          dense={compactLayout}
          style={{ overflowY: "auto" }}
          {...listProps}
        >
          {searchValue &&
            loading &&
            [...Array(data?.length || numberOfLoadingLines)].map((_x, i) => (
              <LoadingListItem
                key={i + "loading_list_item"}
                compactLayout={compactLayout}
                showActionLoading={listItemActionProps}
              />
            ))}
          {!loading &&
            dataKeys &&
            searchValue &&
            data?.map((option, i) => (
              <ListItem
                key={i + "item_number_list"}
                button
                divider
                onClick={() => onClick && onClick(option)}
              >
                <ListItemText
                  primary={`${option[dataKeys.listTitleKey]}${
                    option[dataKeys.extraListTitleKey]
                      ? ", " + option[dataKeys.extraListTitleKey]
                      : ""
                  }`}
                  secondary={`${option[dataKeys.listSubtitleKey]}${
                    option[dataKeys.extraListSubtitleKey]
                      ? ", " + option[dataKeys.extraListSubtitleKey]
                      : ""
                  }`}
                />
                {listItemActionProps && (
                  <Tooltip
                    title={listItemActionProps?.tooltip || ""}
                    arrow
                    placement="left"
                  >
                    <IconButton
                      size={compactLayout ? "small" : "medium"}
                      edge="end"
                      onClick={(e) => {
                        e.stopPropagation();
                        listItemActionProps?.onClick(option);
                      }}
                    >
                      {listItemActionProps?.icon && (
                        <listItemActionProps.icon />
                      )}
                    </IconButton>
                  </Tooltip>
                )}
              </ListItem>
            ))}
        </List>
      </Fade>
    </>
  );
};

ItemNumberList.defaultProps = {
  title: "Search",
  compactLayout: false,
  debounceTimeout: 200,
  numberOfLoadingLines: 10,
  dataKeys: {
    listTitleKey: "value",
    listSubtitleKey: "text",
    extraListTitleKey: undefined,
    extraListSubtitleKey: undefined,
  },
  translations: {
    errorPrimary: "Something went wrong",
    errorSecondary: "Failed to load data",
    noResultsPrimary: "No results found",
    noResultsSecondary: "Try searching for something else",
    placeholderPrimary: "Type something",
    placeholderSecondary: "The results will appear here",
  },
};

ItemNumberList.propTypes = {
  /**
   * If true the UI will be morge compact with less margins
   */
  compactLayout: PropTypes.bool,
  /**
   * Default shape of the data
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
  /**
   * Define the keys of the data object
   * Extra keys will be displayed after the main keys
   */
  dataKeys: PropTypes.shape({
    // value
    listTitleKey: PropTypes.string.isRequired,
    // text
    listSubtitleKey: PropTypes.string.isRequired,
    // undefined
    extraListTitleKey: PropTypes.string,
    // undefined
    extraListSubtitleKey: PropTypes.string,
  }),
  error: PropTypes.bool,
  /**
   * Display a button for each list item
   * If truthy a skeleton for the loading state will be added
   */
  listItemActionProps: PropTypes.shape({
    icon: PropTypes.object.isRequired,
    /**
     * onClick callback which returns object of the line
     */
    onClick: PropTypes.func.isRequired,
    /**
     * If truthy a tooltip will show on hover
     */
    tooltip: PropTypes.string,
  }),
  /**
   * Any props supported by Mui List
   */
  listProps: PropTypes.object,
  loading: PropTypes.bool,
  placeholderComponent: PropTypes.func,
  /**
   * Translations passed to the placeholder components and textfield label
   */
  translations: PropTypes.shape({
    errorPrimary: PropTypes.string.isRequired,
    errorSecondary: PropTypes.string.isRequired,
    noResultsPrimary: PropTypes.string.isRequired,
    noResultsSecondary: PropTypes.string.isRequired,
    placeholderPrimary: PropTypes.string.isRequired,
    placeholderSecondary: PropTypes.string.isRequired,
  }),
  /**
   * Determines the number of loading skeletons to show
   * If data prop exists it uses the length of the previous state when loading is re-triggered
   */
  numberOfLoadingLines: PropTypes.number,
  /**
   * Callback for listitem click
   * returns the full object of the line
   */
  onClick: PropTypes.func,
};

export default ItemNumberList;
