import { useParams } from "react-router";
import RailsVTSiframe from "@/components/RailsVTSiframe";

const WeldMap = () => {
  const params = useParams();
  const URL = params.id
    ? `${import.meta.env.VITE_VTS_ENDPOINT}/weld_maps/${params.id}`
    : undefined;

  return <RailsVTSiframe url={URL} />;
};

export default WeldMap;
