import SaveIcon from "@mui/icons-material/Save";
import { Fab } from "@mui/material";

const SaveButton = ({ ...props }) => (
  <Fab
    type="submit"
    variant="extended"
    color="primary"
    style={{
      position: "fixed",
      right: "0",
      bottom: "0",
      zIndex: "9999",
      margin: "20px",
    }}
    {...props}
  >
    <SaveIcon style={{ marginRight: "10px" }} />
    Save
  </Fab>
);

export default SaveButton;
