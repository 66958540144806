import { LinearProgress, Snackbar, Card, Box, Container } from "@mui/material";
import Alert from "@mui/material/Alert";

const LoadingPage = ({
  error,
  logo,
  alt = "logo",
  CardProps,
  LinearProgressProps,
}) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <Container maxWidth="xs">
          <Card variant="outlined" {...CardProps}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                my: 3,
              }}
            >
              <img height={64} alt={alt} src={logo} />
            </Box>
            {!error?.code && <LinearProgress {...LinearProgressProps} />}
          </Card>
        </Container>
      </div>

      <Snackbar open={Boolean(error && Object.keys(error)?.length)}>
        <Alert elevation={6} variant="filled" severity="error">
          {`${error?.code}: ${error?.message}`}
        </Alert>
      </Snackbar>
    </>
  );
};

export default LoadingPage;
