import { useParams } from "react-router";
import RailsVTSiframe from "@/components/RailsVTSiframe";

const ViewDocument = () => {
  const params = useParams();
  const URL = params.documentId
    ? `${import.meta.env.VITE_VTS_ENDPOINT}/documents/documents/${
        params.documentId
      }`
    : undefined;

  return <RailsVTSiframe url={URL} />;
};

export default ViewDocument;
