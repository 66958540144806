import React from "react";
import DescriptionIcon from "@mui/icons-material/Description";
import HighLight from "./HighLight";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import BuildIcon from "@mui/icons-material/Build";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
const ProductionHighLight = (props) => <HighLight {...props} />;

ProductionHighLight.defaultProps = {
  choices: [
    {
      name: "Order",
      url: "/production/production-order",
      parameter: "prod_order",
      icon: <BuildIcon />,
    },
    {
      name: "Reports",
      url: "/reports/reports",
      parameter: "production_order",
      icon: <AccountTreeIcon />,
    },
    {
      name: "Documents",
      url: "/documents/documents",
      parameter: "production_order",
      icon: <DescriptionIcon />,
    },
    {
      name: "Comments",
      url: "/project/comments",
      parameter: "project",
      icon: <ChatBubbleIcon />,
    },
  ],
};

export default ProductionHighLight;
