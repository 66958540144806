import React, { useState } from "react";
import { Fab, Tooltip } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DoneIcon from "@mui/icons-material/Done";
import copyTextToClipboard from "../lib/iframeUtils/copyTextToClipboard";

const CopyButton = ({ textToCopy }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyButtonClick = () => {
    copyTextToClipboard(textToCopy);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <Tooltip arrow title="Copy url">
      <Fab
        size="small"
        variant="contained"
        color="primary"
        onClick={handleCopyButtonClick}
        sx={{ position: "fixed", bottom: "20px", left: "16px" }}
      >
        {isCopied ? (
          <DoneIcon fontSize="small" />
        ) : (
          <ContentCopyIcon fontSize="small" />
        )}
      </Fab>
    </Tooltip>
  );
};

export default CopyButton;
