import React, { useState } from "react";
import PropTypes from "prop-types";
import { IconButton, Badge } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import { Modal, ModalGateway } from "react-images";
import MaterialCarousel from "./MaterialCarousel";

const ImageButtonBadge = ({ images, ...props }) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <ModalGateway>
        {open ? (
          <Modal
            onClose={(e) => {
              setOpen(false);
              e.stopPropagation();
            }}
          >
            <MaterialCarousel
              fixPropagation
              views={images}
              styles={{
                blanket: (base, state) => ({
                  ...base,
                  zIndex: 1500,
                  pointerEvents: "none",
                  background: "none !important",
                }),
                positioner: (base, state) => ({
                  ...base,
                  zIndex: 1500,
                  pointerEvents: "none",
                  background: "none !important",
                }),
                dialog: (base, state) => ({
                  ...base,
                  zIndex: 1500,
                  pointerEvents: "none",
                  background: "none !important",
                }),
              }}
            />
          </Modal>
        ) : null}
      </ModalGateway>
      <IconButton
        disabled={images.length === 0}
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
        {...props}
        size="large"
      >
        <Badge badgeContent={images.length} color="error">
          {images.length <= 1 && <ImageIcon />}
          {images.length > 1 && <PhotoLibraryIcon />}
        </Badge>
      </IconButton>
    </div>
  );
};

ImageButtonBadge.defaultProps = {
  images: [],
};

ImageButtonBadge.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default ImageButtonBadge;
