import { Box, Button, Grow, Backdrop, Typography } from "@mui/material";

import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ToggleIcon from "@/components/ToggleIcon";

export const FileDropContent = ({ isDragActive, hasFiles }) => {
  return (
    <div
      style={{
        textAlign: "center",
        marginTop: "auto",
      }}
    >
      <div
        style={{
          transform: isDragActive ? "scale(2.2)" : "scale(1.8)",
          transitionDuration: "0.5s",
        }}
      >
        <ToggleIcon
          on={isDragActive}
          onIcon={<CloudUploadIcon color="primary" />}
          offIcon={<CloudUploadOutlinedIcon color="action" />}
        />
      </div>
      <Typography variant="body2">
        {isDragActive ? "Release files" : "Drag files here"}
      </Typography>

      <Grow in={!isDragActive && !hasFiles} timeout={500}>
        <div>
          <Typography variant="body2">{"or"}</Typography>
          <Button size="small" color="primary">
            Browse
          </Button>
        </div>
      </Grow>
    </div>
  );
};

export const FileDropOverlay = ({
  isDragActive,
  hasFiles,
  isDraggingOutside,
}) => {
  const getBorder = () => {
    if (!hasFiles && !isDragActive) return "1px dashed lightgray";
    if (!hasFiles && isDragActive) return "1px dashed  lightgray";
    else return undefined;
  };

  const open = !hasFiles || isDragActive || isDraggingOutside;

  return (
    <Backdrop
      open={open}
      style={{
        position: "absolute",
        zIndex: 1,
        backgroundColor: "rgba(255, 255, 255, 0.6)",
        borderRadius: isDragActive ? "10px" : "5px",
        border: getBorder(),
        margin: hasFiles ? undefined : "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        right: 0,
        bottom: 0,
        left: 0,
        top: 0,
        backdropFilter: isDragActive || isDraggingOutside ? "blur(3px)" : null,
      }}
    >
      <Box position="relative">
        <FileDropContent isDragActive={isDragActive} hasFiles={hasFiles} />
      </Box>
    </Backdrop>
  );
};

export default FileDropOverlay;
