import makeStyles from "@mui/styles/makeStyles";
import { blue, green, grey } from "@mui/material/colors";

export default makeStyles({
  Done: {
    backgroundColor: `${blue[800]} !important`,
    color: "white !important",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: blue[900],
      color: "white",
    },
  },
  In_Progress: {
    backgroundColor: `${green[600]} !important`,
    color: "white",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: green[700],
      color: "white",
    },
  },
  Pre_Planned: {
    backgroundColor: "white !important",
    color: "black !important",
    borderRadius: 0,
    "&:hover": {
      color: "black",
    },
  },
  Planned: {
    backgroundColor: `${grey[600]} !important`,
    color: "white !important",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: grey[800],
      color: "white",
    },
  },
  delayed: {
    backgroundColor: "black !important",
    color: "white !important",
    borderRadius: 0,
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
});
