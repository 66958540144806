import Carousel from "react-images";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CloseIcon from "@mui/icons-material/Close";
import { Fab, useMediaQuery, useTheme } from "@mui/material";

const NextButton = ({ innerProps, onClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div style={{ position: "absolute", top: "50%", right: "1.5rem" }}>
      <Fab
        onClick={onClick}
        color="secondary"
        size={isMobile ? "small" : "large"}
        {...innerProps}
      >
        <NavigateNextIcon />
      </Fab>
    </div>
  );
};

const PrevButton = ({ innerProps, onClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div style={{ position: "absolute", top: "50%", left: "1.5rem" }}>
      <Fab
        onClick={onClick}
        color="secondary"
        size={isMobile ? "small" : "large"}
        {...innerProps}
      >
        <NavigateBeforeIcon />
      </Fab>
    </div>
  );
};

const Header = ({ currentView, modalProps }) => {
  const { onClose } = modalProps;
  return (
    <div
      style={{
        position: "absolute",
        top: "1.5rem",
        right: "1.5rem",
        zIndex: 5000,
      }}
    >
      <Fab onClick={onClose} size="small">
        <CloseIcon />
      </Fab>
    </div>
  );
};

const View = ({ data }) => (
  <img
    src={data.src}
    alt={data.name}
    onClick={(e) => e.stopPropagation()}
    style={{
      objectFit: "scale-down",
      width: "100%",
      height: "100%",
      maxHeight: "100vh",
    }}
  />
);

const MaterialCarousel = ({ fixPropagation, ...carouselProps }) => {
  const defaultComponents = {
    NavigationNext: NextButton,
    NavigationPrev: PrevButton,
    Header: Header,
  };

  const ViewComponent = {
    View: View,
  };

  const components = fixPropagation
    ? { ...defaultComponents, ...ViewComponent }
    : defaultComponents;

  return <Carousel components={components} {...carouselProps} />;
};

export default MaterialCarousel;
