import { LinearProgress, Typography } from "@mui/material";
import React from "react";
import CardBase from "@/pages/home/components/CardBase";

const Rescheduling = ({
  rescheduleOperations,
  totalOperations,
  rescheduleOrders,
  totalProductionOrders,
  loading,
  error,
}) => {
  const noData = <Typography variant="h6">No data.</Typography>;

  const content = (
    <>
      <Typography variant="h4">
        {rescheduleOperations} / {totalOperations}
        <LinearProgress
          variant="determinate"
          value={(rescheduleOperations / totalOperations) * 100}
        />
      </Typography>
      <small>Operations in</small>
      <Typography variant="h4">
        {rescheduleOrders} / {totalProductionOrders}
        <LinearProgress
          variant="determinate"
          value={(rescheduleOrders / totalProductionOrders) * 100}
        />
      </Typography>
      <small>production orders need rescheduling.</small>
    </>
  );

  return (
    <CardBase
      title="Rescheduling"
      content={rescheduleOperations ? content : noData}
      loading={loading}
      error={error}
    />
  );
};

export default Rescheduling;
