const fetchFile = (url, extraOpts = {}, filename, filetype) =>
  fetch(url, extraOpts)
    .then((r) => r.blob())
    .then((blob) => URL.createObjectURL(blob))
    .then((uril) => {
      var link = document.createElement("a");
      link.href = uril;
      link.download = `${filename}-${new Date().toLocaleString()}.${filetype}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .catch((e) => console.error(e));

export default fetchFile;
