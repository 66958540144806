import React, { useEffect, useState } from "react";
import { FormHelperText, Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { isValid, startOfDay, endOfDay } from "date-fns";

import { DateSelectOptionDefinition } from "@/types";

const errorHandler = (
  reason: string | null,
  setError: (error: string) => void,
  name: string,
) => {
  name = name || "Date";
  switch (reason) {
    case "invalidDate":
      setError("Invalid date");
      break;

    case "disablePast":
      setError("Values in the past are not allowed");
      break;

    case "maxDate":
      setError(`${name} should not be after end date`);
      break;

    case "minDate":
      setError(`${name} should not be before start date`);
      break;

    case "shouldDisableDate":
      setError("Error");
      break;

    default:
      setError("");
  }
};

interface DateRangeFieldProps {
  initialValue: DateSelectOptionDefinition | null;
  onChange: (value: DateSelectOptionDefinition | null) => void;
  variant?: "filled" | "outlined" | "standard";
  size?: "small" | "medium" | undefined;
}

const DateRangeField = ({
  initialValue,
  onChange,
  variant,
  size,
}: DateRangeFieldProps) => {
  const [initialStartDate, initialEndDate] = initialValue?.value || ["", ""];

  const [startDate, setStartDate] = useState(
    isValid(new Date(initialStartDate)) ? new Date(initialStartDate) : null,
  );
  const [endDate, setEndDate] = useState(
    isValid(new Date(initialEndDate)) ? new Date(initialEndDate) : null,
  );
  const [bothFilledError, setBothFilledError] = useState("");
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");

  const errorClear = !bothFilledError && !startDateError && !endDateError;

  const handleStartDateChange = (date: number | Date | null) =>
    setStartDate(date ? startOfDay(date) : null);
  const handleEndDateChange = (date: number | Date | null) =>
    setEndDate(date ? endOfDay(date) : null);

  const handleSetStartDateError = (error: string) => {
    setStartDateError(error);
  };

  useEffect(() => {
    if (
      ![startDate, endDate].every((e) => e === null) &&
      ![startDate, endDate].every((e) => e)
    ) {
      setBothFilledError("Both dates must be filled");
    } else {
      setBothFilledError("");
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (
      errorClear &&
      startDate &&
      endDate &&
      isValid(startDate) &&
      isValid(endDate)
    ) {
      onChange({
        value: [startDate.toISOString(), endDate.toISOString()],
        text: `${startDate.toLocaleDateString(
          navigator.language,
        )} - ${endDate.toLocaleDateString(navigator.language)}`,
      });
    } else {
      onChange(null);
    }
  }, [errorClear, onChange, startDate, endDate]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <DatePicker
            label="Start date"
            value={startDate}
            maxDate={endDate}
            onChange={(date) => handleStartDateChange(date)}
            onError={(reason) =>
              errorHandler(reason, handleSetStartDateError, "Start date")
            }
            renderInput={(params) => (
              <TextField
                fullWidth
                variant={variant}
                helperText={startDateError}
                size={size}
                {...params}
              />
            )}
          />
          <FormHelperText variant={variant} error={Boolean(bothFilledError)}>
            {endDate && bothFilledError}
          </FormHelperText>
        </Grid>
        <Grid item xs={12} lg={6}>
          <DatePicker
            label="End date"
            value={endDate}
            minDate={startDate}
            onChange={(date) => handleEndDateChange(date)}
            onError={(reason) =>
              errorHandler(reason, setEndDateError, "End date")
            }
            renderInput={(params) => (
              <TextField
                fullWidth
                variant={variant}
                helperText={endDateError}
                size={size}
                {...params}
              />
            )}
          />
          <FormHelperText variant={variant} error={Boolean(bothFilledError)}>
            {startDate && bothFilledError}
          </FormHelperText>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};

DateRangeField.defaultProps = {
  initialValue: {
    value: [undefined, undefined],
    text: "",
  },
};

export default DateRangeField;
