import { useState, useEffect } from "react";

import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MenuIcon from "@mui/icons-material/Menu";
import "@/pages/main/TopBar.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1.5),
    "@media (max-width: 400px)": {
      marginRight: theme.spacing(0.5),
    },
  },
  title: {
    flexGrow: 1,
    "@media (max-width: 400px)": {
      fontSize: 16,
    },
    "@media (max-width: 320px)": {
      fontSize: 14,
    },
  },
  buttons: {
    marginTop: theme.spacing(1),
  },
}));

const TopBar = ({
  openMenu,
  title = "",
  position = "sticky",
  buttons: Buttons,
  color = "default",
  ...props
}) => {
  const classes = useStyles();
  const [iframeTitle, setIframeTitle] = useState(null);

  useEffect(() => {
    if (title?.length) {
      setIframeTitle(title);
    }

    const iframeElement = document.getElementById("railsvts")?.contentWindow;
    const receiveIframeMessage = (event) => {
      const sourceIsValid =
        event.origin === import.meta.env.VITE_VTS_ENDPOINT &&
        event.source === iframeElement;

      if (sourceIsValid && event.data.reason === "IFRAME_DATA") {
        setIframeTitle(event.data?.title?.split("-")[0]?.trim());
      }
    };

    window.addEventListener("message", receiveIframeMessage);
    return () => {
      window.removeEventListener("message", receiveIframeMessage);
    };
  }, [title]);

  return (
    <AppBar
      className={classes.root}
      id="app-bar"
      position={position}
      color={color}
      {...props}
    >
      <Toolbar>
        <IconButton
          id="topMenuButton"
          className={classes.menuButton}
          color="inherit"
          aria-label="Menu"
          onClick={openMenu}
          edge="start"
        >
          <MenuIcon />
        </IconButton>
        <Typography className={classes.title} variant="h6" color="inherit">
          {iframeTitle}
        </Typography>
        <div>{Buttons && <Buttons className={classes.buttons} />}</div>
        <div id="appbar-extra-buttons" />
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
