import { Box, ButtonBase, Theme, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";
import { LinkVariant } from "@/config/icons";
import { Link, LinkProps as ReactRouterLinkProps } from "react-router-dom";

export type LinkHeaderProps = {
  title: string;
} & ReactRouterLinkProps;

const LinkHeader = ({ title = "", ...props }: LinkHeaderProps) => {
  return (
    <Link style={{ color: "inherit" }} {...props}>
      <ButtonBase
        sx={{
          width: "max-content",
          px: 0.5,
          py: 0.5,
          borderRadius: 1,
          "&:hover": {
            backgroundColor: (theme: Theme) =>
              alpha(theme.palette.primary.main, 0.2),
            color: (theme: any) => theme.palette.primary.main,
          },
          "&:focus": {
            backgroundColor: (theme: Theme) =>
              alpha(theme.palette.primary.main, 0.5),
          },
        }}
      >
        <Box display="flex" alignItems="center" mx={0.5}>
          <Typography
            variant="subtitle1"
            sx={{
              "&:hover": {
                color: (theme: Theme) => theme.palette.primary.main,
              },
            }}
          >
            {title}
          </Typography>
          <LinkVariant color="inherit" sx={{ fontSize: 16, ml: 0.5 }} />
        </Box>
      </ButtonBase>
    </Link>
  );
};

export default LinkHeader;
