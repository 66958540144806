import React from "react";
import PropTypes from "prop-types";
import { Chip } from "@mui/material";
import { LinkOff } from "@mui/icons-material";

const UnlinkedItem = ({ items, disabled }) => {
  return (
    <>
      {items?.map((item) => {
        return (
          <Chip
            sx={{ ml: 0.5, mb: 0.5, p: 0 }}
            label={item}
            size="small"
            color={"default"}
            variant={"filled"}
            icon={<LinkOff />}
            disabled={disabled}
          />
        );
      })}
    </>
  );
};

UnlinkedItem.propTypes = {
  items: PropTypes.array,
  disabled: PropTypes.bool,
};
export default UnlinkedItem;
