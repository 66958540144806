const queryStringSerialize = function queryStringSerialize(obj, prefix) {
  const str = [];
  let p;

  for (p in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, p)) {
      const key = isNaN(parseInt(p, 10)) ? p : "";
      const k = prefix ? prefix + "[" + key + "]" : p;
      const v = obj[p];
      str.push(
        v !== null && typeof v === "object"
          ? queryStringSerialize(v, k)
          : encodeURIComponent(k) + "=" + encodeURIComponent(v),
      );
    }
  }

  return str.join("&");
};

export default queryStringSerialize;
