import React, { useCallback, useEffect, useMemo } from "react";
import queryString from "query-string";
import { useNavigate, useLocation } from "react-router";
import useTable from "@/components/datatable/useTable";
import AppBarButtonWrapper from "@/components/AppBarButtonWrapper";
import DownloadFileButton from "@/components/DownloadFileButton";
import MaterialDataTable from "@/components/datatable/MaterialDataTable";
import MaterialToolbar from "@/components/datatable/MaterialToolbar";
import ProjectHighLight from "@/components/HighLight/ProjectHighLight";
import PurchaseHighLight from "@/components/HighLight/PurchaseHighLight";
import ItemNumberHighLight from "@/components/HighLight/ItemNumberHighLight";
import FullpageTableScrollContainer from "@/components/FullpageTableScrollContainer";
import searchNewSelect from "@/lib/searchNewSelect";
import { fetchWithToken } from "@/lib/fetchWithToken";
import { QueryStringParams } from "@/lib/iframeUtils/types";
import {
  getFiltersFromQueryString,
  getSortingFromQueryString,
} from "@/lib/queryStringOperations";
import { ColumnDefinition, SortParamsDefinition } from "@/types";

const parseQueryStringFilters = (params: QueryStringParams) =>
  Object.entries(getFiltersFromQueryString(params || {})).reduce(
    (filters, [key, value]) => {
      if (Array.isArray(value) && key.includes("_date")) {
        const start = new Date(value[0]).toLocaleDateString("no");
        const end = new Date(value[1]).toLocaleDateString("no");
        return {
          ...filters,
          [key]: { text: `${start} - ${end}`, value: value },
        };
      }
      if (Array.isArray(value))
        return { ...filters, [key]: value.map((v) => ({ value: v, text: v })) };
      else return { ...filters, [key]: { value: value, text: value } };
    },
    {},
  );

const ENDPOINT = `${import.meta.env.VITE_VTS_API}/v1/purchase/items_to_receive`;

const ItemsToReceive = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const params = queryString.parse(location.search, {}) as QueryStringParams;

  const initialFilters = parseQueryStringFilters(params);

  const filterProjectNo = (initialFilters as { job_no?: { value?: string } })
    ?.job_no?.value;

  const initialSort = {
    requested_date: "asc",
    ...getSortingFromQueryString(params),
  };

  const columns = [
    {
      name: "Project",
      key: "job_no",
      renderCellFn: (v: string) => <ProjectHighLight>{v}</ProjectHighLight>,
      filterType: "select",
      filterOptionsCallback: searchNewSelect("project_number"),
    },
    {
      name: "Purch. Order",
      key: "document_no",
      renderCellFn: (v: string) => <PurchaseHighLight>{v}</PurchaseHighLight>,
      filterType: "select",
      filterOptionsCallback: searchNewSelect("purchase_order_number"),
    },
    { name: "Line", key: "line_no", filterType: "text" },
    {
      name: "Item",
      key: "item_no",
      renderCellFn: (v: string) => (
        <ItemNumberHighLight>{v}</ItemNumberHighLight>
      ),
      filterType: "select",
      filterOptionsCallback: searchNewSelect("item_number"),
    },
    {
      name: "Proj. Task",
      key: "job_task_no",
      filterType: "select",
      filterOptionsCallback: searchNewSelect("project_task_number", {
        extra: filterProjectNo,
        strict: 1,
      }),
    },
    {
      name: "Cust. Part",
      key: "customer_item",
      filterType: "select",
      filterOptionsCallback: searchNewSelect("customer_item"),
    },
    { name: "Description", key: "description", filterType: "text" },
    {
      name: "Vendor",
      key: "vendor_name",
      filterType: "select",
      filterOptionsCallback: searchNewSelect("vendor_number"),
    },
    { name: "Qty", key: "outstanding_quantity", filterType: "number" },
    {
      name: "Purchaser",
      key: "purchaser_code",
      filterType: "select",
      filterOptionsCallback: searchNewSelect("purchaser"),
    },
    {
      name: "Requested",
      key: "requested_receipt_date",
      renderCellFn: (_v: string, row: any) => {
        const isLate =
          row.requested_receipt_date &&
          new Date(row.requested_receipt_date) < new Date();

        const value = row.requested_receipt_date
          ? new Date(row.requested_receipt_date).toLocaleDateString("no")
          : null;

        return (
          <span style={{ color: isLate ? "red" : undefined }}>{value}</span>
        );
      },
      filterType: "date",
    },
    {
      name: "Promised",
      key: "promised_receipt_date",
      filterType: "date",
      renderCellFn: (_v: string, row: any) => {
        const isLate =
          row.promised_receipt_date &&
          new Date(row.promised_receipt_date) < new Date();

        const value = row.promised_receipt_date
          ? new Date(row.promised_receipt_date).toLocaleDateString("no")
          : null;

        return (
          <span style={{ color: isLate ? "red" : undefined }}>{value}</span>
        );
      },
    },
  ];

  const { state, handlers, setters } = useTable({
    options: { multiSort: true, mode: "server" },
    columns: columns as ColumnDefinition[],
    initialFilters: initialFilters,
    initialSort: initialSort as SortParamsDefinition,
    initialRowsPerPage: 30,
  });

  const { setTotalRows, setLoading } = setters;
  const {
    handleApplyFilter,
    handleRemoveFilter,
    handleToggleSort,
    handleAddRows,
  } = handlers;

  const url = useMemo(() => {
    const url = new URL(ENDPOINT);
    url.searchParams.set("start", `${state.page}`);
    url.searchParams.set("limit", `${state.rowsPerPage}`);

    Object.entries(state.filters as object).forEach(([key, filter]) => {
      const isDateFilter = key.includes("_date");

      if (isDateFilter && Array.isArray(filter.value)) {
        const dates = filter.value;
        dates.forEach((date: string) =>
          url.searchParams.append(`filter[${key}][]`, date),
        );
      }
      if (Array.isArray(filter)) {
        filter.map((v) => url.searchParams.append(`filter[${key}][]`, v.value));
      } else {
        !isDateFilter &&
          url.searchParams.append(`filter[${key}]`, filter.value);
      }
    });

    Object.entries(state.sorting).forEach(([key, value]) => {
      url.searchParams.set(`sort[${key}]`, value);
    });

    return url;
  }, [state.filters, state.page, state.rowsPerPage, state.sorting]);

  useEffect(() => {
    setLoading(true);
    fetchWithToken(url.toString())
      .then((r) => {
        handleAddRows(r.rows, true);
        setTotalRows(r?.total);
      })
      .catch((err) => {
        handleAddRows([], true);
        console.log(err);
      })
      .finally(() => setLoading(false));
  }, [url, state.refreshedAt, setLoading, handleAddRows, setTotalRows]);

  const handleLoadMore = useCallback(() => {
    const nextUrl = new URL(url);
    nextUrl.searchParams.set("start", `${state.rows.length}`);
    nextUrl.searchParams.set("limit", `${state.rowsPerPage}`);

    if (!state.loading) {
      setLoading(true);
      fetchWithToken(nextUrl.toString())
        .then((r) => {
          handleAddRows(r.rows, false);
          setTotalRows(r?.total);
        })
        .catch((err) => {
          handleAddRows([], true);
          console.log(err);
        })
        .finally(() => setLoading(false));
    }
  }, [
    handleAddRows,
    state.loading,
    state.rows.length,
    state.rowsPerPage,
    setLoading,
    setTotalRows,
    url,
  ]);

  useEffect(() => {
    navigate({ search: url.search });
  }, [url, navigate]);

  return (
    <>
      <AppBarButtonWrapper showSpinner={state.loading}>
        <DownloadFileButton
          loading={state.loading}
          urlEndpoint={`${url.toString()}&format=csv`}
          filename="Items to receive"
          fileExtension="csv"
          filters={false}
          params={false}
        />
      </AppBarButtonWrapper>
      <FullpageTableScrollContainer>
        <MaterialToolbar
          loadedRows={state.loadedRows}
          totalRows={state.totalRows}
          filters={state.filters}
          loading={state.loading}
          columns={state.columns}
          onRemoveFilter={handleRemoveFilter}
        />
        <MaterialDataTable
          TableContainerProps={{ sx: { overflow: "initial" } }} // important to get body scroll working
          TableProps={{ size: "small" }}
          onPagination={handleLoadMore}
          onApplyFilter={handleApplyFilter}
          onRemoveFilter={handleRemoveFilter}
          onToggleSort={(e, column) => handleToggleSort(column.key)}
          enableFiltering
          {...state}
        />
      </FullpageTableScrollContainer>
    </>
  );
};

export default ItemsToReceive;
