import React from "react";
import PropTypes from "prop-types";

import DownloadFileButton from "@/components/DownloadFileButton";
import AppBarButtonWrapper from "@/components/AppBarButtonWrapper";

const ReceiptControlButtons = ({ filters, loading }) => {
  return (
    <AppBarButtonWrapper showSpinner={loading}>
      <DownloadFileButton
        loading={loading}
        urlEndpoint="/v1/item/receipt"
        filename="Receipt Control"
        fileExtension="csv"
        filters={filters}
      />
    </AppBarButtonWrapper>
  );
};

ReceiptControlButtons.propTypes = {
  // parsed url params
  filters: PropTypes.object,
  loading: PropTypes.bool,
};

export default ReceiptControlButtons;
