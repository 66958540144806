import { useEffect, useState } from "react";
import authHeaders from "../lib/authHeaders";
import { useSnackbar } from "notistack";

const useFetch = ({
  url,
  initialData = undefined,
  fetchCondition = true,
  disableSnackbar = false,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(initialData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (url && fetchCondition) {
      setLoading(true);

      fetch(url, { headers: authHeaders() })
        .then((res) => {
          if (!res.ok) {
            throw Error(`${res.status}: ${res.statusText}`);
          } else return res.json();
        })
        .then((response) => {
          setData(response);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [url, fetchCondition]);

  useEffect(() => {
    !disableSnackbar &&
      error &&
      enqueueSnackbar(error.toString(), { variant: "error" });
  }, [disableSnackbar, error, enqueueSnackbar]);

  const refetch = () => {
    setLoading(true);
    fetch(url, { headers: authHeaders() })
      .then((res) => res.ok && res.json())
      .then((response) => {
        setData(response);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { data, loading, error, refetch };
};

export default useFetch;
