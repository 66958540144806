import PropTypes from "prop-types";
import { Box, Container, Fade } from "@mui/material";
import { Skeleton } from "@mui/material";

const TabPanel = ({
  children,
  index,
  currentIndex,
  loading,
  maxWidth,
  ...other
}) => {
  if (loading && currentIndex === index)
    return (
      <Fade in>
        <Container component={Box} my={3} maxWidth={maxWidth}>
          {[...Array(3).keys()].map((key) => (
            <div key={key}>
              <Skeleton height={40} />
              <Skeleton height={40} width={"80%"} />
              <Skeleton height={100} width={"90%"} />
            </div>
          ))}
        </Container>
      </Fade>
    );
  else
    return (
      <div
        role="tabpanel"
        hidden={currentIndex !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        <Fade in={currentIndex === index} timeout={500}>
          <Container component={Box} my={3} maxWidth={maxWidth}>
            {children}
          </Container>
        </Fade>
      </div>
    );
};

TabPanel.defaultProps = {
  maxWidth: "md",
};

TabPanel.propTypes = {
  children: PropTypes.element,
  currentIndex: PropTypes.number,
  index: PropTypes.number,
  maxWidth: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", false]),
};

export default TabPanel;
