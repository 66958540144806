import { keyframes } from "@emotion/react";

const animation = keyframes`
    0%  { transform: translate(0px, 0px) rotate(-7deg); },
    33%  { transform: translate(0px, 0px) rotate(7deg); },
    66%  { transform: translate(0px, 0px) rotate(-7deg); },
`;

// use on sx prop
export const nudgeAnimation = (on) => {
  if (on) {
    return {
      animation: `${animation} 0.5s linear`,
      animationIterationCount: "infinite",
    };
  } else return undefined;
};
