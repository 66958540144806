import React, { useState } from "react";

import { useNavigate } from "react-router";
import {
  ListItemAvatar,
  ListItem,
  Avatar,
  ListItemText,
  Box,
} from "@mui/material";

import logo from "@/images/logo.svg";
import vtsLogo from "@/images/vtsLogo.png";

import NavigationDrawer, {
  ListType,
} from "@/pages/main/navigation-drawer/NavigationDrawer";
import TopBar from "@/pages/main/TopBar";

import "@/pages/main/Navigation.css";

const Navigation = ({
  buttons,
  currentPath,
  footerRoutes,
  onNavigate,
  routes,
  title,
}: {
  buttons: React.ComponentType;
  currentPath: string;
  footerRoutes: ListType[];
  onNavigate: (path?: string) => void;
  routes: ListType[];
  title?: string;
}) => {
  const navigate = useNavigate();

  const { railsUserId, account } = JSON.parse(
    localStorage.getItem("msToken") ?? "",
  );

  const Buttons = buttons ? buttons : () => null;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const handleToggleNavDrawer = () => setIsDrawerOpen((state) => !state);

  return (
    <>
      <TopBar
        title={title ?? ""}
        openMenu={() => handleToggleNavDrawer()}
        position={undefined}
        color={undefined}
        className="no-print"
        buttons={() => <Buttons />}
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.95)",
          color: "black",
          backdropFilter: "blur(20px)",
        }}
      />
      <NavigationDrawer
        onNavigate={onNavigate}
        currentPath={currentPath}
        enablePinning
        enableMostVisited
        open={isDrawerOpen}
        showPinnedAsSelected={false}
        list={routes}
        footerList={footerRoutes}
        onToggleDrawer={handleToggleNavDrawer}
        enableSearch
        alwaysShowPin
        showTopDivider
        dense
        TopComponent={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            mt={2}
          >
            <a
              href="https://portal.oddatechnology.no/"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="Odda Technology logo" src={logo} height={40} />
            </a>
          </Box>
        }
        BottomComponent={
          <ListItem
            button
            dense
            onClick={() => {
              navigate(`/profile/${railsUserId}`);
              handleToggleNavDrawer();
            }}
          >
            <Box display="flex" alignItems="center">
              <ListItemAvatar sx={{ backgroundColor: "transparent" }}>
                <Avatar sx={{ backgroundColor: "transparent" }}>
                  <img height={30} width={30} src={vtsLogo} alt="VTS logo" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={account?.name}
                secondary={account?.username}
                primaryTypographyProps={{ noWrap: true }}
                secondaryTypographyProps={{ noWrap: true }}
              />
            </Box>
          </ListItem>
        }
      />
    </>
  );
};

export default Navigation;
