import React, { Fragment, useEffect, useRef, useState } from "react";
import DrawerListItem from "./DrawerListItem";
import {
  Box,
  Card,
  Divider,
  Drawer,
  Icon,
  List,
  ListSubheader,
  Typography,
} from "@mui/material";
import DebouncedSearch, {
  DebouncedSearchProps,
} from "../../../components/DebouncedSearch";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import DndTarget from "./DndTarget";

export type IconType = React.ComponentType<typeof Icon> | React.ReactNode;

export interface ListType {
  category?: string;
  divider?: boolean;
  hidden?: boolean;
  icon?: IconType;
  onClick?: (listItem: ListType) => void;
  path?: string;
  subMenu?: ListType[];
  subtitle?: string;
  title?: string;
  disabled?: boolean;
}

interface NavigationDrawerProps {
  alwaysShowPin?: boolean;
  autoScrollToSelected?: boolean;
  BottomComponent?: React.ReactNode;
  color?: "primary" | "secondary";
  dense?: boolean;
  currentPath?: string;
  enableMostVisited?: boolean;
  enablePinning?: boolean;
  enableSearch?: boolean;
  expandAllMenus?: boolean;
  footerList?: ListType[];
  list?: ListType[];
  localStorageClickedKey?: string;
  localStoragePinKey?: string;
  onNavigate?: (path?: string) => void;
  onToggleDrawer?: () => void;
  open?: boolean;
  searchOptions?: DebouncedSearchProps;
  showPinnedAsSelected?: boolean;
  showTopDivider?: boolean;
  TopComponent?: React.ReactNode;
  width?: string | number;
}

const searchList = (list: ListType[], searchString: string) => {
  const term = searchString.toLowerCase();
  let result = list.filter(
    (element) =>
      element?.title?.toLowerCase().includes(term) ||
      element?.subtitle?.toLowerCase().includes(term) ||
      element?.subMenu?.some(
        (subElement) =>
          subElement?.title?.toLowerCase().includes(term) ||
          subElement?.subtitle?.toLowerCase().includes(term),
      ),
  );
  return result;
};

const defaultSearchOptions = {
  variant: "outlined" as DebouncedSearchProps["variant"],
  size: "small" as DebouncedSearchProps["size"],
  showSearchIcon: true as DebouncedSearchProps["showSearchIcon"],
  autoFocus: false as DebouncedSearchProps["autoFocus"],
};

const NavigationDrawer = ({
  alwaysShowPin = false,
  autoScrollToSelected = false,
  BottomComponent,
  color = "primary",
  currentPath,
  dense,
  enableMostVisited = false,
  enablePinning = false,
  enableSearch,
  expandAllMenus,
  footerList = [],
  list = [],
  localStoragePinKey = "pinnedRoutes",
  localStorageClickedKey = "clickedRoutes",
  onNavigate,
  onToggleDrawer,
  open = false,
  searchOptions,
  showPinnedAsSelected = true,
  showTopDivider,
  TopComponent,
  width = 290,
}: NavigationDrawerProps) => {
  const initialPinnedRoutes = JSON.parse(
    localStorage.getItem(localStoragePinKey) ?? "[]",
  ) as Array<string>;

  const initialClickedRoutes = JSON.parse(
    localStorage.getItem(localStorageClickedKey) ?? "[]",
  ) as {
    path: string;
    count: number;
  }[];

  const scrollRef = useRef<null | HTMLDivElement>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchIsActive, setSearchIsActive] = useState(false);
  const [pinnedRoutes, setPinnedRoutes] = useState(initialPinnedRoutes);
  const [clickedRoutes, setClickedRoutes] = useState(initialClickedRoutes);

  const handleNavigate = (listItem: ListType) => {
    if (listItem.onClick) {
      listItem.onClick(listItem);
    } else {
      listItem.path && handleStoreRouteClick(listItem.path);
      listItem.path && onNavigate?.(listItem.path);
    }
    handleCloseDrawer();
  };

  const handleSearchChange = (value: string) => setSearchTerm(value);

  const handleCloseDrawer = () => {
    onToggleDrawer?.();
    setSearchTerm("");
    setSearchIsActive(false);
  };

  //merge list and footerList to enable searching in both
  const combinedList = [...list, ...footerList].filter(
    (element) => element.title || element.subtitle,
  );

  const mainList =
    enableSearch && searchTerm ? searchList(combinedList, searchTerm) : list;

  //Pinning
  const pinnedList = pinnedRoutes.map((path: string, _i: number) =>
    combinedList.find((element) => element.path === path),
  ) as ListType[];

  const handleAddPin = (path: string) =>
    setPinnedRoutes((prev: Array<string>) => [...prev, path]);

  const handleRemovePin = (path: string) =>
    setPinnedRoutes((prev: Array<string>) => prev.filter((p) => p !== path));

  // Most visited
  const mostVisitedList = clickedRoutes
    ?.slice(0, 10)
    .map((clicked, i) =>
      combinedList.find((element) => element?.path === clicked?.path),
    ) as ListType[];

  const handleStoreRouteClick = (path: string) => {
    if (enableMostVisited && path) {
      const index = clickedRoutes.findIndex((obj) => obj.path === path);
      const pathExists = index >= 0;

      let routes = [...clickedRoutes];

      if (pathExists) {
        routes[index].count = routes[index].count + 1;
      } else {
        routes.push({ path: path, count: 1 });
      }

      const sortedByCount = routes.sort((a, b) => b.count - a.count);
      setClickedRoutes(sortedByCount);
    }
  };

  const executeScroll = () =>
    scrollRef?.current?.scrollIntoView({
      block: "center",
    });

  useEffect(() => {
    const timer = setTimeout(() => {
      autoScrollToSelected && executeScroll();
    }, 1);
    return () => clearTimeout(timer);
  }, [open, autoScrollToSelected]);

  useEffect(() => {
    enableMostVisited &&
      window.localStorage.setItem(
        localStorageClickedKey,
        JSON.stringify(clickedRoutes),
      );
  }, [clickedRoutes, enableMostVisited, localStorageClickedKey]);

  useEffect(() => {
    enablePinning &&
      window.localStorage.setItem(
        localStoragePinKey,
        JSON.stringify(pinnedRoutes),
      );
  }, [pinnedRoutes, enablePinning, localStoragePinKey]);

  const subMenuIsOpen = Boolean(searchTerm || expandAllMenus);
  const hidePinDuringSearch = searchIsActive ? false : alwaysShowPin;

  const shouldShowPinnedList =
    enablePinning && !searchIsActive && !searchTerm && pinnedList?.length > 0;

  const shouldShowMostVisited =
    enableMostVisited &&
    searchIsActive &&
    !searchTerm &&
    mostVisitedList?.length > 0;

  const shouldShowBottomComponent = !shouldShowMostVisited && !searchTerm;
  const shouldShowFooterList = !searchIsActive && footerList?.length > 0;

  const searchOpts = { ...defaultSearchOptions, ...searchOptions };

  // DndKit hooks
  const sensors = useSensors(
    useSensor(PointerSensor, {
      // Fix onDragEnd interrupting click events
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragEnd(event: DragEndEvent): void {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setPinnedRoutes((prevPinnedRoutes) => {
        const oldIndex = prevPinnedRoutes.indexOf(active.id as string);
        const newIndex = prevPinnedRoutes.indexOf(over?.id as string);

        return arrayMove(prevPinnedRoutes, oldIndex, newIndex);
      });
    }
  }

  return (
    <Drawer anchor="left" open={open} onClose={handleCloseDrawer}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        width={width}
        overflow="hidden"
      >
        {TopComponent}
        {enableSearch && (
          <>
            <Box m={2}>
              <DebouncedSearch
                color={color}
                {...searchOpts}
                onFocusChange={(bool: boolean) => setSearchIsActive(bool)}
                removeFocusOnBlur={!shouldShowMostVisited}
                debounceTimeout={0}
                onSearchChange={handleSearchChange}
                label="Search..."
                type="text"
                fullWidth
              />
            </Box>

            {showTopDivider && <Divider />}
          </>
        )}
        {searchTerm && !mainList?.length && (
          <Box display="flex" justifyContent="center" mt={2}>
            <Typography variant="body2" color="textSecondary">
              {"No results"}
            </Typography>
          </Box>
        )}

        <List
          style={{
            flex: 1,
            overflowX: "hidden",
            overflowY: "auto",
            height: "100%",
          }}
          disablePadding
          dense={dense}
        >
          {shouldShowPinnedList && (
            <StickyListSubheader>{"Pinned"}</StickyListSubheader>
          )}

          {shouldShowPinnedList && (
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
              modifiers={[restrictToVerticalAxis]}
            >
              <SortableContext
                items={pinnedList.map((item) => item?.path as UniqueIdentifier)}
                strategy={verticalListSortingStrategy}
              >
                {pinnedList.map((item, i) => {
                  const selected =
                    showPinnedAsSelected && currentPath === item?.path;

                  return (
                    <DndTarget key={item?.path} id={item?.path}>
                      <DrawerListItem
                        color={color}
                        alwaysShowPin={alwaysShowPin}
                        enablePinning={enablePinning}
                        id={i}
                        isPinned={true}
                        key={`pinned-${i}-${item?.path}`}
                        listItem={item}
                        onClick={(item) => handleNavigate(item)}
                        onPinClick={handleAddPin}
                        onUnpinClick={handleRemovePin}
                        selected={selected}
                        selectedPath={currentPath}
                        subMenuOpen={subMenuIsOpen}
                      />
                    </DndTarget>
                  );
                })}
              </SortableContext>
            </DndContext>
          )}

          {shouldShowPinnedList && <Divider sx={{ mt: 2 }} />}

          {shouldShowMostVisited && (
            <StickyListSubheader>{"Most visited"}</StickyListSubheader>
          )}

          {shouldShowMostVisited &&
            mostVisitedList?.map((item, i) => {
              const selected = currentPath === item?.path;
              const isPinned = pinnedList?.some(
                (option) => option?.path === item?.path,
              );

              return (
                <DrawerListItem
                  alwaysShowPin={hidePinDuringSearch}
                  color={color}
                  enablePinning={enablePinning}
                  id={`mostVisited-${i}`}
                  isPinned={isPinned}
                  key={`mostVisited-${i}-${item?.path}`}
                  listItem={item}
                  onClick={(listItem) => handleNavigate(listItem)}
                  onPinClick={handleAddPin}
                  onUnpinClick={handleRemovePin}
                  selected={searchIsActive ? false : selected}
                  selectedPath={currentPath}
                  subMenuOpen={subMenuIsOpen}
                />
              );
            })}

          {!shouldShowMostVisited &&
            mainList?.map((item, i) => {
              const isPinned = pinnedList?.some(
                (option) => option?.path === item.path,
              );
              const selected = currentPath === item?.path;
              const shouldAddPaddingToFirstItem = i === 0 && !item.category;

              return (
                <Fragment key={`drawerItem-${i}-${item?.path}`}>
                  {shouldAddPaddingToFirstItem && <Box pt={1} />}
                  {item.category && (
                    <StickyListSubheader>{item.category}</StickyListSubheader>
                  )}

                  <>
                    <DrawerListItem
                      alwaysShowPin={hidePinDuringSearch}
                      color={color}
                      enablePinning={enablePinning}
                      id={`drawerItem-${i}`}
                      isPinned={isPinned}
                      listItem={item}
                      onClick={(listItem) => handleNavigate(listItem)}
                      onPinClick={handleAddPin}
                      onUnpinClick={handleRemovePin}
                      selected={searchIsActive ? false : selected}
                      selectedPath={currentPath}
                      subMenuOpen={subMenuIsOpen}
                    />
                    {selected && <div ref={scrollRef} id="scrollanchor" />}
                  </>

                  {item.divider && <Divider sx={{ mt: 2 }} />}
                </Fragment>
              );
            })}

          <Box height={100} id="endlist-spacer" />
        </List>

        {shouldShowBottomComponent && (
          <Card sx={{ zIndex: 9999 }}>
            {shouldShowFooterList && (
              <List dense={dense} disablePadding sx={{ mt: 1 }}>
                {footerList?.map((item, i) => {
                  const isSelected = currentPath === item?.path;
                  return (
                    <DrawerListItem
                      alwaysShowPin={false}
                      color={color}
                      id={`footerListItem-${i}`}
                      key={i}
                      listItem={item}
                      onClick={(listItem) => handleNavigate(listItem)}
                      selected={isSelected}
                      selectedPath={currentPath}
                    />
                  );
                })}
              </List>
            )}
            {BottomComponent}
          </Card>
        )}
      </Box>
    </Drawer>
  );
};

export default NavigationDrawer;

const StickyListSubheader = ({ children }: { children: React.ReactNode }) => (
  <>
    <ListSubheader>{children}</ListSubheader>
  </>
);
