import React from "react";
import { Box } from "@mui/material";
import ItemNumberHighLight from "@/components/HighLight/ItemNumberHighLight";
import TreeDataIcon from "@/pages/project/components/TreeDataIcon";

const getTableComponentColumns = (_t) => [
  {
    name: _t("Task"),
    key: "task",
    disableSort: true,
  },
  {
    name: _t("Item"),
    key: "item",
    renderCellFn: (v) => <ItemNumberHighLight>{v}</ItemNumberHighLight>,
    formatSearch: (v) => v,
    disableSort: true,
  },
  {
    key: "description",
    name: _t("Description"),
    renderCellFn: (cell, row) => {
      const boxSpacing = row.level * 3;
      return (
        <>
          {boxSpacing ? (
            <Box className="TreeDataIconBox" pl={boxSpacing - 3}>
              <TreeDataIcon />
            </Box>
          ) : null}
          <Box pl={boxSpacing}>{cell}</Box>
        </>
      );
    },
    disableSort: true,
  },
  {
    name: _t("Quantity"),
    key: "quantity",
    disableSort: true,
  },
  {
    name: _t("Part"),
    key: "part",
    disableSort: true,
  },
  {
    name: _t("Drawing"),
    key: "drawing",
    disableSort: true,
  },
  {
    name: _t("Serial"),
    key: "serial",
    disableSort: true,
  },
  {
    name: _t("Make/Buy"),
    key: "make_buy",
    disableSort: true,
  },
  {
    name: _t("To order"),
    key: "to_order",
    renderCellFn: (v) => v || <span style={{ color: "red" }}>No Order</span>,
    formatSearch: (v) => v || "No Order",
    disableSort: true,
  },
  {
    name: _t("From order"),
    key: "from_order",
    disableSort: true,
  },
];
export default getTableComponentColumns;
