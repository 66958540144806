import React from "react";
import {
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useMediaQuery from "@mui/material/useMediaQuery";
import PropTypes from "prop-types";

const ResponsiveButtons = ({ color, options }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      {!isMobile &&
        options.map((option, i) => (
          <IconButton color={color} onClick={option.onClick} key={i}>
            {option.icon}
          </IconButton>
        ))}
      {isMobile && (
        <>
          <IconButton color={color} onClick={handleOpen}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="home-button-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            style={{ minWidth: 300, width: 300 }}
          >
            {options.map((option, i) => (
              <MenuItem
                onClick={() => {
                  option.onClick();
                  setAnchorEl(null);
                }}
                key={i}
              >
                <ListItemIcon>{option.icon}</ListItemIcon>
                <Typography variant="inherit" style={{ marginRight: 20 }}>
                  {option.title}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </div>
  );
};

ResponsiveButtons.defaultProps = {
  options: [],
  color: "default",
};

ResponsiveButtons.propTypes = {
  color: PropTypes.oneOf(["default", "inherit", "primary", "secondary"]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element,
    }),
  ),
};

export default ResponsiveButtons;
