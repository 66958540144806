import React from "react";
import { Toolbar, Typography, CircularProgress, Box } from "@mui/material";
import PropTypes from "prop-types";

/**
 *
 * @param {object} props
 * @param {string} props.title
 * @param {boolean} [props.loading]
 * @param {React.ReactNode} [props.children]
 * @param {Object} [props.gridProps]
 *
 */
const HeaderToolbar = ({ title, loading, children, ...props }) => {
  return (
    <Toolbar {...props}>
      <Box
        display="flex"
        width="100%"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="start"
        {...props}
      >
        <Typography variant="h5" gutterBottom sx={{ flexGrow: 1 }}>
          {title}
        </Typography>

        <Box display="inline-flex" alignItems="center">
          {loading && <CircularProgress size={20} sx={{ mr: 2 }} />}

          {children}
        </Box>
      </Box>
    </Toolbar>
  );
};

HeaderToolbar.propTypes = {
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  gridProps: PropTypes.object,
  children: PropTypes.node,
};

export default HeaderToolbar;
