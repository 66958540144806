import React, { useState } from "react";
import {
  Typography,
  Toolbar,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Grid,
  Button,
  CircularProgress,
  Box,
  Alert,
  Stack,
  Container,
  Card,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import authHeaders from "../../../lib/authHeaders";
import AddItemsToDocument from "./AddItemsToDocument";
import CombinedPDFButton from "./CombinedPDFButton";
import DocumentCheck from "./DocumentCheck";
import LinkedItem from "./LinkedItem";
import UnlinkedItem from "./UnlinkedItem";

const TFMCImport = () => {
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState(null);
  const [documents, setDocuments] = useState([]);
  const [skipLines, setSkipLines] = useState(null);
  const [isError, setIsError] = useState(false);
  const [shouldLinkAll, setShouldLinkAll] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    onDropAccepted: (files) => {
      setLoading(true);
      setIsError(false);
      handleFileUpload(files[0]);
    },
    onDropRejected: (e) => {
      console.error(e);
      alert("Invalid File");
    },
    multiple: false,
    preventDropOnDocument: true,
    accept: {
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
  });

  const handleClear = () => {
    setDocuments([]);
    setProject(null);
    setSkipLines(null);
  };

  const handleFileUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      const APIResponse = await fetch(
        `${import.meta.env.VITE_VTS_API}/v1/tfmc_import`,
        {
          method: "POST",
          headers: authHeaders({ defaultContentType: true }),
          body: formData,
        },
      );
      const jsonResponse = await APIResponse.json();

      if (jsonResponse?.status >= 400 && jsonResponse?.status <= 600) {
        setIsError(true);
      }

      setProject(jsonResponse.project);
      if (jsonResponse?.skip_lines?.length) {
        setSkipLines(jsonResponse.skip_lines);
      }

      const tempArr = [];
      for (const key in jsonResponse.entries) {
        if (Object.hasOwnProperty.call(jsonResponse?.entries, key)) {
          const element = jsonResponse.entries[key];
          tempArr.push({
            document_name: key.trim(),
            ...element,
          });
        }
      }

      const sortedTempArr = tempArr.sort((a, b) => {
        return a.document_name.localeCompare(b.document_name);
      });

      setDocuments(sortedTempArr);
      setLoading(false);
    } catch (error) {
      setIsError(true);
      setLoading(false);
    }
  };

  const handleLinkAll = () => {
    const unlinkedDoc = documents.filter(
      ({ document_id, unlinked_items }) =>
        document_id && unlinked_items?.length !== 0,
    );

    if (unlinkedDoc.length === 0) {
      alert("All items are already linked to documents!");
    } else {
      setShouldLinkAll(true);
    }
  };

  const filterDocument = Object.entries(documents).filter(([k, v]) =>
    v.hasOwnProperty("document_id"),
  );

  const linkedDocuments = filterDocument.map(([k, v]) => ({
    id: v.document_id[0],
    type: "document",
  }));

  const updateLinkedItems = (documentId, linkedItems) => {
    setDocuments((prevDocuments) =>
      prevDocuments.map((document) =>
        document.document_id === documentId
          ? { ...document, linked_items: linkedItems, unlinked_items: [] }
          : document,
      ),
    );
  };

  return (
    <>
      {documents?.length && !loading ? (
        <Toolbar>
          {project ? (
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              {`Project ${project}`}
            </Typography>
          ) : null}

          <Stack spacing={2} direction="row">
            <Button onClick={handleLinkAll} variant="outlined">
              Link All
            </Button>
            <Button onClick={handleClear} variant="outlined">
              Start Over
            </Button>
          </Stack>
        </Toolbar>
      ) : null}

      {isError && (
        <Box style={{ padding: 16 }}>
          <Alert severity="error">Something went wrong during request!</Alert>
        </Box>
      )}

      {skipLines?.length && (
        <Box style={{ padding: 16 }}>
          <Alert severity="warning">
            {`Line ${skipLines?.join(", ")} has no item number. Skipped all.`}
          </Alert>
        </Box>
      )}

      <Container sx={{ mt: 25 }} maxWidth="md">
        <Card sx={{ p: 2 }}>
          {!documents?.length && !loading ? (
            <div
              {...getRootProps({ className: "dropzone" })}
              style={{
                textAlign: "center",
                border: "1px dashed #D5D5D5",
                cursor: "pointer",
                borderRadius: 8,
                minHeight: 200,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#5E5E5E",
              }}
              elevation={0}
            >
              <input {...getInputProps()} />
              <Typography variant="subtitle1">
                Drag and drop the TFMC file, or click here to select it.
              </Typography>
            </div>
          ) : null}
        </Card>
      </Container>

      {loading && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 16,
          }}
        >
          <Typography style={{ textAlign: "center", fontSize: "1rem" }}>
            Processing <br /> This may take some time depending on the size of
            the file
          </Typography>
          <CircularProgress style={{ marginLeft: "16px" }} />
        </Box>
      )}

      {documents?.length && !loading ? (
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={8} md={6} sx={{ position: "relative" }}>
            <CombinedPDFButton
              sx={{
                display: "inline-flex",
                position: "absolute",
                top: 15,
                right: -50,
              }}
              name={project}
              list={linkedDocuments}
              disabled={!Boolean(linkedDocuments.length)}
            />
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Document</TableCell>
                    <TableCell>Linked Item</TableCell>
                    <TableCell>Unlinked Item</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {documents.map((document) => {
                    return (
                      <TableRow key={document.document_name}>
                        <TableCell>
                          <DocumentCheck document={document} />
                        </TableCell>
                        <TableCell>
                          <LinkedItem items={document?.linked_items} />
                        </TableCell>
                        <TableCell>
                          <UnlinkedItem
                            items={
                              document?.document_id
                                ? document?.unlinked_items
                                : document?.items
                            }
                            disabled={!Boolean(document?.document_id)}
                          />
                        </TableCell>
                        <TableCell align="center">
                          {document?.document_id?.length === 1 ? (
                            <AddItemsToDocument
                              document={document}
                              project={project}
                              shouldLinkAll={shouldLinkAll}
                              setShouldLinkAll={setShouldLinkAll}
                              updateLinkedItems={updateLinkedItems}
                            />
                          ) : null}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      ) : null}
    </>
  );
};

export default TFMCImport;
