import { LinearProgress, Typography } from "@mui/material";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import authHeaders from "@/lib/authHeaders";
import CardBase from "@/pages/home/components/CardBase";

const timeDeliveryUri = `${
  import.meta.env.VITE_VTS_API
}/v1/project/on_time_delivery/kpi`;
const OnTimeDelivery = () => {
  const { error, data, isLoading } = useQuery({
    queryKey: ["on_time_delivery"],
    queryFn: () =>
      fetch(timeDeliveryUri, {
        headers: authHeaders(),
      }).then((response) => response.json()),
  });

  const hasData =
    data &&
    data.hasOwnProperty("on_time_delivery") &&
    data.hasOwnProperty("on_time_delivery");

  const noData = <Typography variant="h6">No data</Typography>;

  const content = (
    <>
      <Typography variant="h4">
        {data?.on_time_delivery ? data?.on_time_delivery.toFixed(2) + "%" : ""}
      </Typography>
      <LinearProgress variant="determinate" value={data?.on_time_delivery} />
      <small>
        {data?.on_time_delivery ? "Since the beginning." : "No data."}
      </small>
    </>
  );

  return (
    <CardBase
      title="On Time Delivery"
      content={hasData ? content : noData}
      actionTitle="See more"
      actionHref="/project/on-time-delivery"
      loading={isLoading}
      error={error}
    />
  );
};
export default OnTimeDelivery;
