import { useState, useEffect } from "react";
import { TextField, MenuItem, Grid, CircularProgress } from "@mui/material";
import { Autocomplete } from "@mui/material";
import { useFormikContext } from "formik";
import CommentComponent from "../common/comments/CommentComponent";
import NcrField from "../common/NcrField";
import { levelOptions } from "./levelOptions";
import getEventTypes from "@/services/ncr/getEventTypes";
import getOwnerOptions from "@/services/ncr/getOwnerOptions";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const DescriptionForm = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { values, setFieldValue, errors, status, setStatus } =
    useFormikContext();

  const [options, setOptions] = useState({ owners: [] });
  const [loading, setLoading] = useState({ ncrTypes: true, owners: true });

  useEffect(() => {
    getEventTypes()
      .then((ncrTypes) => {
        //store ncrTypes in formik status context so it can be consumed by other subforms
        setStatus({ ncrTypes: ncrTypes });
      })
      .catch((e) => {
        setStatus({ ncrTypes: [] });
        enqueueSnackbar(e.toString(), { variant: "error" });
      })
      .finally(() =>
        setLoading((loadingState) => ({ ...loadingState, ncrTypes: false })),
      );
    getOwnerOptions()
      .then((owners) => setOptions((options) => ({ ...options, owners })))
      .catch((e) => enqueueSnackbar(e.toString(), { variant: "error" }))
      .finally(() =>
        setLoading((loadingState) => ({ ...loadingState, owners: false })),
      );
  }, [enqueueSnackbar, setStatus]);

  const handleTypeChange = (value) => {
    setFieldValue("ncr_type", value);
    setFieldValue("level", levelOptions(value)[0]);
    setFieldValue("two_d.event_reason", undefined);
    setFieldValue("two_d.event_reason2", undefined);
    setFieldValue("two_d.event_reason3", undefined);
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <NcrField name="title" label="Title" required margin="dense" />
        </Grid>
        <Grid item xs={12} lg={6}>
          <NcrField
            name="ncr_type"
            label="Type"
            select
            required
            onChange={(e) => handleTypeChange(e.target.value)}
            fastField={false}
            margin="dense"
            disabled={loading.ncrTypes}
            InputProps={{
              endAdornment: loading.ncrTypes && (
                <CircularProgress
                  size={18}
                  style={{ margin: 2, marginRight: 20 }}
                />
              ),
            }}
          >
            {status?.ncrTypes?.length > 0 ? (
              status.ncrTypes.map(({ id, description, key }, i) => (
                <MenuItem key={id} value={key}>
                  {description?.[t("_LANGUAGE")] || description?.en}
                </MenuItem>
              ))
            ) : (
              <MenuItem disabled value={undefined}>
                No options available
              </MenuItem>
            )}
          </NcrField>
        </Grid>
        <Grid item xs={12} lg={6}>
          <NcrField
            name="level"
            label="Level"
            select
            required
            margin="dense"
            fastField={false}
          >
            {levelOptions(values.ncr_type)?.map((option, i) => (
              <MenuItem key={option + i} value={option}>
                {option.charAt(0).toUpperCase() + option.slice(1)}
              </MenuItem>
            ))}
          </NcrField>
        </Grid>
        <Grid item xs={12} lg={6}>
          <NcrField
            name="registered_by"
            label="Registered by"
            disabled
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Autocomplete
            name="owner"
            options={options.owners}
            value={values.owner}
            onChange={(event, newValue) => {
              setFieldValue("owner", newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="dense"
                label="Owner"
                variant="outlined"
                size="small"
                required
                error={Boolean(errors.owner)}
                helperText={errors.owner}
                disabled={loading.owners}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: loading.owners ? (
                    <>
                      <CircularProgress size={18} style={{ margin: 2 }} />
                      {params.InputProps.endAdornment}
                    </>
                  ) : (
                    params.InputProps.endAdornment
                  ),
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <CommentComponent name="comments" />
    </div>
  );
};

export default DescriptionForm;
