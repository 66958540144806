import authHeaders from "@/lib/authHeaders";
import EventType from "@/models/ncr/EventType";

const getEventTypes = async (): Promise<Array<EventType>> =>
  fetch(`${import.meta.env.VITE_VTS_API}/v1/ncr_event_options/event_types`, {
    headers: authHeaders(),
  }).then((r) => {
    if (r.ok || r.status === 200) return r.json();
    else throw r.statusText;
  });

export default getEventTypes;
