import React from "react";
import AppsIcon from "@mui/icons-material/Apps";
import DescriptionIcon from "@mui/icons-material/Description";
import HighLight from "./HighLight";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import BuildIcon from "@mui/icons-material/Build";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import EventNoteIcon from "@mui/icons-material/EventNote";

const ProjectHighLight = (props) => {
  const { isDelivery, task } = props;

  const updatedChoices = [...ProjectHighLight.defaultProps.choices];

  if (isDelivery) {
    updatedChoices[1] = {
      name: "Planning",
      url: "/project/planning",
      parameter: "project",
      icon: <EventNoteIcon />,
      extraParams: `&task=${task ?? ""}`,
    };
  }

  return <HighLight {...props} choices={updatedChoices} />;
};

ProjectHighLight.defaultProps = {
  choices: [
    {
      name: "Deliveries",
      url: "/project/deliveries",
      parameter: "project",
      icon: <AppsIcon />,
    },
    {
      name: "Components",
      url: "/project/components",
      parameter: "project",
      icon: <AccountTreeIcon />,
    },
    {
      name: "Progress Per Resource",
      url: "/production/progress-per-resource",
      parameter: "filter%5Bjob_no%5D",
      icon: <BuildIcon />,
    },
    {
      name: "NCRs",
      url: "/ncr/list",
      parameter: "project",
      icon: <NewReleasesIcon />,
    },
    {
      name: "Documents",
      url: "/documents/documents",
      parameter: "project",
      icon: <DescriptionIcon />,
    },
    {
      name: "Comments",
      url: "/project/comments",
      parameter: "project_number",
      icon: <ChatBubbleIcon />,
    },
    {
      name: "Tasks",
      url: "/project/tasks",
      parameter: "project",
      icon: <FormatListBulletedIcon />,
    },
    {
      name: "Purchase Orders",
      url: "/purchase/orders",
      parameter: "project",
      icon: <ShoppingCartIcon />,
    },
  ],
};

export default ProjectHighLight;
