import PropTypes from "prop-types";
import { Fab, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ItemNumberHighLight from "@/components/HighLight/ItemNumberHighLight";

import VisibilityIcon from "@mui/icons-material/Visibility";
import AddIcon from "@mui/icons-material/Add";
import { pickBy } from "lodash";
import { RemoteDataTable } from "@oddadigitalsystem/datatable2";
import queryStringSerialize from "@/utils/queryStringSerialize";
import authHeaders from "@/lib/authHeaders";
import { useNavigate } from "react-router";
import queryString from "query-string";
import prepareFilter from "@/lib/prepareFilter";
import searchNewSelect from "@/lib/searchNewSelect";
import FullpageTableScrollContainer from "@/components/FullpageTableScrollContainer";
import WeldMapsButtons from "@/pages/reports/weld-maps/WeldMapsButtons";
import { useSearchParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

const WeldMaps = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const initialFilters = prepareFilter(
    queryString.parse(searchParams.toString()),
  );

  return (
    <>
      <WeldMapsButtons filters={queryString.parse(searchParams.toString())} />
      <FullpageTableScrollContainer>
        <RemoteDataTable
          initialFilters={initialFilters}
          size="small"
          enableFiltering
          enableSorting
          requestOptions={{
            rowsPerPage: 30,
          }}
          dataFetchCallback={(params) => {
            const serializedFilters = queryStringSerialize(
              pickBy(params.filter),
            );
            const serializedParams = queryStringSerialize(pickBy(params));

            setSearchParams(serializedFilters, { replace: true });
            return fetch(
              `${import.meta.env.VITE_VTS_API}/v1/weld_maps?${serializedParams}
              `,
              {
                headers: authHeaders(),
              },
            )
              .then((r) => r.json())
              .catch((e) => console.log(e));
          }}
          initialColumns={[
            {
              name: "Actions",
              key: "id_new",
              format: function Format(_v, row) {
                return (
                  <IconButton
                    key={row.id}
                    onClick={() => navigate(`/reports/weld-map/${row.id}`)}
                    size="small"
                  >
                    <VisibilityIcon />
                  </IconButton>
                );
              },
              disableFilter: true,
              disableSorting: true,
            },
            {
              name: "Number",
              key: "id",
              sort: "desc",
              filterType: "text",
            },
            {
              name: "Item",
              key: "item_number",
              format: function Highlight(v) {
                return (
                  <ItemNumberHighLight>{v.toString()}</ItemNumberHighLight>
                );
              },
              filterOptionsCallback: searchNewSelect("item_number"),
            },
            {
              name: "Created At",
              key: "created_at",
              format: (v) =>
                [
                  new Date(v).toLocaleDateString("no"),
                  new Date(v).toLocaleTimeString("no"),
                ].join(" "),
              filterType: "date",
            },
            {
              name: "Creator",
              key: "created_by",
              filterOptionsCallback: searchNewSelect("user"),
            },
            {
              name: "All Welds Approved?",
              key: "all_approved",
              format: (v) => (v ? "Yes" : "No"),
              disableFilter: true,
            },
          ]}
        />
        <Fab
          onClick={() => navigate("/reports/weld-maps/new")}
          color="secondary"
          className={classes.fab}
        >
          <AddIcon />
        </Fab>
      </FullpageTableScrollContainer>
    </>
  );
};

WeldMaps.propTypes = {
  filters: PropTypes.object,
};

export default WeldMaps;
