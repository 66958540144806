import PropTypes from "prop-types";
import { Tab, useTheme, Box, Tooltip, useMediaQuery } from "@mui/material";
import TabIcon from "./TabIcon";

const Label = ({ label, error, errorColor, icon, disabled }) => (
  <Tooltip
    title={
      disabled
        ? "Feature not yet implemented, fill out remaining steps in Navision"
        : ""
    }
    arrow
  >
    <Box
      component="span"
      color={error ? errorColor : null}
      display="flex"
      alignItems="baseline"
    >
      <Box mr={icon ? 2 : undefined} mb="2px">
        {icon}
      </Box>
      <Box>{label}</Box>
    </Box>
  </Tooltip>
);

const NcrTab = ({
  label,
  index,
  currentIndex,
  error,
  disabled,
  filled,
  ...props
}) => {
  const theme = useTheme();
  const errorColor = theme.palette.error.main;
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Tab
      label={
        <Label
          label={label}
          error={error}
          disabled={disabled}
          errorColor={errorColor}
          icon={
            !isMobile ? (
              <TabIcon
                index={index}
                currentIndex={currentIndex}
                error={error}
                disabled={disabled}
                errorColor={errorColor}
                filled={filled}
                fontSize={20}
                inline={true}
              />
            ) : undefined
          }
        />
      }
      index={index}
      disabled={disabled}
      icon={
        !isMobile ? undefined : (
          <TabIcon
            index={index}
            currentIndex={currentIndex}
            error={error}
            disabled={disabled}
            errorColor={errorColor}
            filled={filled}
          />
        )
      }
      style={{ pointerEvents: "auto" }}
      {...props}
    />
  );
};

NcrTab.propTypes = {
  currentIndex: PropTypes.number,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  index: PropTypes.number,
  label: PropTypes.string,
};

export default NcrTab;
