import { Paper, Typography, Chip, Stack, alpha } from "@mui/material";
import {
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { isoStringToMonthYear } from "@/lib/isoStringToMonthYear";
import { red, green, blue } from "@mui/material/colors";
import { WeldDefect } from "@/pages/reports/weld-defects/WeldDefects";

type Height = string | number;

interface Props {
  totalRate?: number;
  totalWeldLength?: number;
  totalDefect?: number;
  data: WeldDefect[];
  height?: Height;
}

const WeldDefectChart = ({
  data,
  totalRate,
  totalWeldLength,
  totalDefect,
  height = 400,
}: Props) => (
  <>
    <ResponsiveContainer width="100%" height={height}>
      <ComposedChart data={data}>
        <defs>
          <linearGradient id="colorWeldDefectRate" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={green[400]} stopOpacity={0.78} />
            <stop offset="95%" stopColor={green[400]} stopOpacity={0} />
          </linearGradient>
        </defs>
        <CartesianGrid />
        <XAxis
          dataKey="date"
          tick={<CustomizedAxisTick />}
          interval={0}
          height={55}
        />
        <YAxis dataKey="rate" tick={{ fontSize: 14 }} />
        <Tooltip
          wrapperStyle={{ outline: "none" }}
          content={<CustomizedTooltip />}
        />

        <Area
          name="Weld defect rate"
          dataKey="rate"
          unit="%"
          stroke={green[500]}
          fill="url(#colorWeldDefectRate)"
          strokeWidth={2}
          type="monotone"
        />
      </ComposedChart>
    </ResponsiveContainer>
    {data?.length > 0 && (
      <Stack
        direction="row"
        spacing={2}
        sx={{
          mt: 2,
          justifyContent: "center",
        }}
      >
        <TotalChip
          title="Weld length, total"
          value={totalWeldLength}
          unit="mm"
          color={blue[500]}
        />
        <TotalChip
          title="Weld defect, total"
          value={totalDefect}
          unit="mm"
          color={red[500]}
        />
        <TotalChip
          title="Weld defect rate, total"
          value={totalRate}
          unit="%"
          color={green[500]}
        />
      </Stack>
    )}
  </>
);

export default WeldDefectChart;

const TotalChip = ({
  value,
  unit,
  title,
  color = "#fff",
}: {
  value?: number;
  unit?: string;
  title?: string;
  color: string;
}) => {
  if (typeof value === "number")
    return (
      <Chip
        label={`${title}: ${value} ${unit} `}
        size="small"
        sx={{ backgroundColor: alpha(color, 0.2), color: color }}
      />
    );
  else return null;
};

const CustomizedAxisTick = ({ x, y, payload }: any) => {
  const chartIsEmpty = !payload?.value;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={chartIsEmpty ? "50%" : undefined}
        y={0}
        dy={10}
        fontSize={14}
        textAnchor={"end"}
        transform={chartIsEmpty ? undefined : "rotate(-35)"}
        fill="#666"
      >
        {payload.value
          ? isoStringToMonthYear(payload.value)?.toUpperCase()
          : "No data"}
      </text>
    </g>
  );
};

const CustomizedTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload?.length) {
    const [rate] = payload;

    return (
      <Paper sx={{ p: 1.5, fontSize: 13 }} variant="outlined">
        <Typography variant="subtitle2">
          {` ${isoStringToMonthYear(label)?.toUpperCase()}`}
        </Typography>
        <Typography sx={{ color: blue[400], fontSize: "inherit" }}>
          {`Weld length: ${rate.payload.weld_length} mm`}
        </Typography>
        <Typography sx={{ color: red[400], fontSize: "inherit" }}>
          {`Weld defect: ${rate.payload.weld_defect} mm`}
        </Typography>
        <Typography sx={{ color: rate?.color, fontSize: "inherit" }}>
          {`${rate?.name}: ${rate?.value} ${rate?.unit}`}
        </Typography>
      </Paper>
    );
  }

  return null;
};
