import { RouteConfig, RoutePage, Roles } from "./routes";

type FlattenedAppRoutes = Partial<Omit<RoutePage, "permittedUserRole">> & {
  category?: string;
};
const filterRoutes = (routesConfig: RouteConfig[], userRole: Roles) => {
  const flattenedRoutes: FlattenedAppRoutes[] = [];

  routesConfig.forEach((routeCategory) => {
    let shouldAddCategory = false;
    const tempRoutesArr: RoutePage[] = [];

    routeCategory.pages.forEach((page) => {
      if (
        !page.permittedUserRole ||
        page.permittedUserRole.includes(userRole)
      ) {
        shouldAddCategory = true;
        tempRoutesArr.push(page);
      }
    });

    if (shouldAddCategory) {
      flattenedRoutes.push(
        { category: routeCategory.category },
        ...tempRoutesArr,
      );
    }
  });

  return flattenedRoutes as FlattenedAppRoutes[];
};

export default filterRoutes;
