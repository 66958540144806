import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

let _clipPathSupported = null;

// Check if clip-path is supported. From http://stackoverflow.com/a/30041538.
const clipPathSupported = () => {
  if (_clipPathSupported != null) {
    return _clipPathSupported;
  }
  if (typeof document === "undefined") {
    _clipPathSupported = false;
    return false;
  }

  const base = "clipPath";
  const prefixes = ["Webkit"];
  const properties = [
    base,
    ...prefixes.map(
      (prefix) => prefix + base.charAt(0).toUpperCase() + base.slice(1),
    ),
  ];
  const testElement = document.createElement("testelement");
  const attribute = "polygon(50% 0%, 0% 100%, 100% 100%)";

  // Interate over the properties and see if they pass two tests.
  for (const property of properties) {
    // First, they need to even support clip-path (IE <= 11 does not)...
    if (testElement.style[property] === "") {
      // Second, we need to see what happens when we try to create a CSS shape...
      testElement.style[property] = attribute;
      if (
        testElement.style[property] !== "" &&
        testElement.style[property] !== "none"
      ) {
        _clipPathSupported = true;
        return true;
      }
    }
  }
  _clipPathSupported = false;
  return false;
};

const clipPath = (value) => ({
  WebkitClipPath: value,
  clipPath: value,
});

const iconStyle = {
  transition:
    "clip-path 550ms cubic-bezier(0.4, 0.0, 0.2, 1), -webkit-clip-path 550ms cubic-bezier(0.4, 0.0, 0.2, 1)",
  width: "100%",
  height: "100%",
  position: "absolute",
  left: 0,
  top: 0,
};

const ToggleIcon = ({ offIcon, onIcon, on, ...props }) => {
  return (
    <Box
      width={24}
      height={24}
      position="relative"
      display="inline-block"
      {...props}
    >
      {React.cloneElement(offIcon, {
        style: {
          ...iconStyle,
          ...clipPath(
            on
              ? "polygon(0% 0%, 0% 0%, 0% 0%)"
              : "polygon(0% 200%, 0% 0%, 200% 0%)",
          ),
          visibility: !on || clipPathSupported() ? "visible" : "hidden",
        },
      })}
      {React.cloneElement(onIcon, {
        style: {
          ...iconStyle,
          ...clipPath(
            on
              ? "polygon(100% -100%, 100% 100%, -100% 100%)"
              : "polygon(100% 100%, 100% 100%, 100% 100%)",
          ),
          visibility: on || clipPathSupported() ? "visible" : "hidden",
        },
      })}
    </Box>
  );
};

ToggleIcon.propTypes = {
  /**
   * The icon to be displayed if the `on` prop is set to `false`.
   */
  offIcon: PropTypes.element.isRequired,
  /**
   * The icon to be displayed if the `on` prop is set to `true`.
   */
  onIcon: PropTypes.element.isRequired,
  /**
   * Controls whether the `onIcon` or the `offIcon` is displayed.
   */
  on: PropTypes.bool.isRequired,
  /**
   * Any other props will be passed down to mui box component
   */
  props: PropTypes.any,
};

export default ToggleIcon;
