import React from "react";
import PropTypes from "prop-types";
import { Link, Tooltip, Typography } from "@mui/material";
import { Error, Report, Verified } from "@mui/icons-material";
import fetchUrl from "../../../lib/fetchUrl";

const DocumentCheck = ({ document }) => {
  const { document_id: documentId, document_name: documentName } = document;

  const valid = documentId?.length === 1;
  const notFound = !documentId || documentId?.length === 0;
  const moreThan1 = documentId?.length > 1;

  const urlDisplay = fetchUrl("documents/documents", {
    document_number: documentName.split("/")[0],
    document_revision: documentName.split("/")[1] || "",
  });

  const MatchedDocument = () => (
    <>
      <Tooltip title="Document exists">
        <Verified color="inherit" fontSize="small" sx={{ color: "#37b24b" }} />
      </Tooltip>
      <Link
        href={fetchUrl(`/documents/view-document/${documentId}`)}
        target="_blank"
        color="inherit"
      >
        {documentName}
      </Link>
    </>
  );

  const UnmatchedDocument = () => (
    <>
      <Tooltip title="Document does not exist">
        <Report color="error" fontSize="small" />
      </Tooltip>
      <Typography fontSize="inherit"> {documentName}</Typography>
    </>
  );

  const MoreThanOneDocument = () => (
    <>
      <Tooltip title="More than one document exists">
        <Error color="inherit" fontSize="small" sx={{ color: "#ffa500" }} />
      </Tooltip>
      <Link href={urlDisplay} target="_blank" color="inherit">
        {documentName}
      </Link>
    </>
  );

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: 5,
        fontSize: 14,
      }}
    >
      {valid && MatchedDocument()}
      {notFound && UnmatchedDocument()}
      {moreThan1 && MoreThanOneDocument()}
    </div>
  );
};

DocumentCheck.propTypes = {
  document: PropTypes.shape({
    document_name: PropTypes.string,
    document_id: PropTypes.array,
  }),
};

export default DocumentCheck;
