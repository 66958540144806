import { useState, useEffect, useCallback } from "react";
import {
  IconButton,
  TextField,
  Zoom,
  InputAdornment,
  TextFieldProps,
  IconProps,
  IconButtonProps,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import useDebounce from "../hooks/useDebounce";

export type CustomTypes = {
  debounceTimeout?: number;
  iconColor?: IconProps["color"];
  iconEdge?: IconButtonProps["edge"];
  iconTransitionDuration?: number;
  initialValue?: string;
  onFocusChange?: (boolean: boolean) => void;
  onSearchChange?: (value: string) => void;
  removeFocusOnBlur?: boolean;
  showSearchIcon?: boolean;
};

export type DebouncedSearchProps = CustomTypes & TextFieldProps;

const defaultTextFieldProps = {
  size: "medium",
  variant: "outlined",
};

const DebouncedSearch = ({
  debounceTimeout = 200,
  iconColor = "inherit",
  iconEdge = "end",
  iconTransitionDuration = 200,
  initialValue = "",
  onFocusChange,
  onSearchChange,
  removeFocusOnBlur = false,
  showSearchIcon = true,
  ...TextFieldProps
}: DebouncedSearchProps) => {
  const [value, setValue] = useState<string>(initialValue);
  const [searchFocus, setSearchFocus] = useState<boolean>(false);
  const debouncedValue = useDebounce(value, debounceTimeout) as string;

  const { variant, size } = TextFieldProps;
  const iconSize = variant === "standard" ? "small" : size;

  const handleSearchChange = (value: string) => setValue(value);

  useEffect(() => {
    onSearchChange?.(debouncedValue);
  }, [debouncedValue, onSearchChange]);

  const handleFocusChange = useCallback(() => {
    onFocusChange?.(searchFocus);
  }, [onFocusChange, searchFocus]);

  useEffect(() => {
    handleFocusChange();
  }, [handleFocusChange]);

  return (
    <TextField
      label="Search..."
      autoComplete="off"
      focused={searchFocus}
      {...TextFieldProps}
      value={value}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
        handleSearchChange(e.target.value)
      }
      onFocus={() => setSearchFocus(true)}
      onBlur={() => {
        removeFocusOnBlur && setSearchFocus(false);
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {showSearchIcon && !searchFocus && (
              <Zoom in={!searchFocus} timeout={iconTransitionDuration}>
                <IconButton
                  tabIndex={-1}
                  edge={iconEdge}
                  size={iconSize}
                  onMouseDown={() => setSearchFocus(true)}
                >
                  <SearchIcon color={iconColor} />
                </IconButton>
              </Zoom>
            )}
            {searchFocus && (
              <Zoom in={searchFocus} timeout={iconTransitionDuration}>
                <IconButton
                  tabIndex={-1}
                  edge={iconEdge}
                  size={iconSize}
                  onMouseDown={() => {
                    handleSearchChange("");
                    setSearchFocus(false);
                  }}
                >
                  <CloseIcon color={iconColor} />
                </IconButton>
              </Zoom>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

DebouncedSearch.defaultProps = defaultTextFieldProps;

export default DebouncedSearch;
