import authHeaders from "@/lib/authHeaders";
import QueueListData from "@/models/production/queue-list/QueueListData";

const loadQueueListData = async (filters: string): Promise<QueueListData> =>
  fetch(`${import.meta.env.VITE_VTS_API}/v1/production/queue_list?${filters}`, {
    headers: authHeaders(),
  }).then((response) => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error(`Error: ${response.status}.`);
    }
  });

export default loadQueueListData;
