import { useState, useEffect } from "react";
import {
  Box,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";
import CommentComponent from "../common/comments/CommentComponent";
import NcrField from "../common/NcrField";
import { temporaryActionRequired } from "./temporaryActionRequired";
import StatusChip from "../common/StatusChip";
import humanizeSnakeCase from "@/lib/humanizeSnakeCase";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import asyncOptionsAdornment from "../common/asyncOptionsAdornment";
import useFetch from "@/hooks/useFetch";
import { useTranslation } from "react-i18next";

const formRootName = "two_d";

const eventOptionsUrl = `${import.meta.env.VITE_VTS_API}/v1/ncr_event_options`;

const FormTwo = () => {
  const { t } = useTranslation();
  const { values, setFieldValue, status } = useFormikContext();
  const [tempActionDisabled, setTempActionDisabled] = useState(false);

  const selectedNcrTypeId = status?.ncrTypes?.find(
    (obj) => obj.key === values.ncr_type,
  )?.id;

  const eventLocationOptions = useFetch({
    url: `${eventOptionsUrl}/event_locations`,
  });

  const eventOptions1 = useFetch({
    url: selectedNcrTypeId
      ? `${eventOptionsUrl}/event_1?parent_id=${selectedNcrTypeId}`
      : undefined,
    fetchCondition: typeof selectedNcrTypeId !== "undefined",
  });

  const eventOptions2 = useFetch({
    url: values.two_d.event_reason
      ? `${eventOptionsUrl}/event_2?parent_id=${values.two_d.event_reason}`
      : undefined,
    fetchCondition: typeof values.two_d.event_reason !== "undefined",
  });

  const eventOptions3 = useFetch({
    url: values.two_d.event_reason2
      ? `${eventOptionsUrl}/event_3?parent_id=${values.two_d.event_reason2}`
      : undefined,
    fetchCondition: typeof values.two_d.event_reason2 !== "undefined",
  });

  const eventReasonIsDisabled =
    !Boolean(values.ncr_type) || eventOptions1?.length === 0;

  const eventReason2IsDisabled =
    !Boolean(values.two_d.event_reason) || eventOptions2?.length === 0;

  const eventReason3IsDisabled =
    !Boolean(values.two_d.event_reason2) || eventOptions3?.length === 0;

  const handleEvent1Change = (value) => {
    setFieldValue("two_d.event_reason", value);
    setFieldValue("two_d.event_reason2", undefined);
    setFieldValue("two_d.event_reason3", undefined);
  };

  const handleEvent2Change = (value) => {
    setFieldValue("two_d.event_reason2", value);
    setFieldValue("two_d.event_reason3", undefined);
  };

  useEffect(() => {
    temporaryActionRequired(
      values.ncr_type,
      values.level,
      setFieldValue,
      setTempActionDisabled,
    );
  }, [values.ncr_type, values.level, setFieldValue]);

  return (
    <>
      <Box mt={2}>
        <Typography>Production references</Typography>
      </Box>
      <Box display="flex">
        <Box flex={1}>
          <NcrField
            rootName={formRootName}
            name="machine_number"
            label="Machine number"
            fastField={false}
          />
          <NcrField
            rootName={formRootName}
            name="specification"
            label="Specification (value)"
            type="number"
          />
          <NcrField
            rootName={formRootName}
            name="measured_value"
            label="Measured (value)"
            type="number"
          />
        </Box>
        <Box mx={1} />
        <Box flex={1}>
          <NcrField
            rootName={formRootName}
            name="wps_number"
            label="WPS number"
          />

          <NcrField
            rootName={formRootName}
            name="tolerance"
            label="Tolerance (value)"
            type="number"
          />
        </Box>
      </Box>
      <Box mt={2} display="flex" alignItems="center">
        <Typography style={{ flex: 1 }}>Categorisation</Typography>
        {values?.ncr_type && (
          <StatusChip
            filled
            label={`Type: ${humanizeSnakeCase(values.ncr_type)}`}
            icon={<InfoOutlinedIcon style={{ color: "inherit" }} />}
          />
        )}
        <Box mr={1} />
        {values?.level && (
          <StatusChip
            filled
            label={`Level: ${humanizeSnakeCase(values.level)}`}
            icon={<InfoOutlinedIcon style={{ color: "inherit" }} />}
          />
        )}
      </Box>
      <Box display="flex">
        <Box flex={1}>
          <NcrField
            rootName={formRootName}
            name="severity"
            label="Severity"
            select
          >
            <MenuItem value="high">High</MenuItem>
            <MenuItem value="medium">Medium</MenuItem>
            <MenuItem value="low">Low</MenuItem>
          </NcrField>

          <FormControlLabel
            disabled={tempActionDisabled}
            checked={Boolean(values.two_d.temporary_action_required)}
            onChange={(e) =>
              setFieldValue(
                "two_d.temporary_action_required",
                !values.two_d.temporary_action_required,
              )
            }
            label="Temporary action required?"
            control={<Checkbox />}
          />

          <Box display="flex">
            <Box flex={1}>
              <NcrField
                rootName={formRootName}
                name="event_location"
                label="Event location"
                select
                fastField={false}
                InputProps={asyncOptionsAdornment(
                  eventLocationOptions?.data,
                  eventLocationOptions.loading,
                )}
              >
                {eventLocationOptions?.data?.length > 0 ? (
                  eventLocationOptions?.data?.map(({ id, description }, i) => (
                    <MenuItem key={id} value={id}>
                      {description?.[t("_LANGUAGE")] || description?.en}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled value={undefined}>
                    No options available
                  </MenuItem>
                )}
              </NcrField>

              <NcrField
                rootName={formRootName}
                name="event_reason2"
                label="Event reason 2"
                select
                fastField={false}
                disabled={eventReason2IsDisabled}
                InputProps={asyncOptionsAdornment(
                  eventOptions2?.data,
                  eventOptions2.loading,
                  eventReason2IsDisabled,
                )}
                onChange={(e) => handleEvent2Change(e.target.value)}
              >
                {eventOptions2?.data?.length > 0 ? (
                  eventOptions2?.data?.map(({ id, description }, i) => (
                    <MenuItem key={id} value={id}>
                      {description?.[t("_LANGUAGE")] || description?.en}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled value={undefined}>
                    No options available
                  </MenuItem>
                )}
              </NcrField>
            </Box>
            <Box mx={1} />
            <Box flex={1}>
              <NcrField
                rootName={formRootName}
                name="event_reason"
                label="Event reason"
                select
                fastField={false}
                disabled={eventReasonIsDisabled}
                InputProps={asyncOptionsAdornment(
                  eventOptions1?.data,
                  eventOptions1.loading,
                  eventReasonIsDisabled,
                )}
                onChange={(e) => handleEvent1Change(e.target.value)}
              >
                {eventOptions1?.data?.length > 0 ? (
                  eventOptions1?.data?.map(({ id, description }, i) => (
                    <MenuItem key={id} value={id}>
                      {description?.[t("_LANGUAGE")] || description?.en}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled value={undefined}>
                    No options available
                  </MenuItem>
                )}
              </NcrField>

              <NcrField
                rootName={formRootName}
                name="event_reason3"
                label="Event reason 3"
                select
                disabled={eventReason3IsDisabled}
                fastField={false}
                InputProps={asyncOptionsAdornment(
                  eventOptions3?.data,
                  eventOptions3.loading,
                  eventReason3IsDisabled,
                )}
              >
                {eventOptions3?.data?.length > 0 ? (
                  eventOptions3?.data?.map(({ id, description }, i) => (
                    <MenuItem key={id} value={id}>
                      {description?.[t("_LANGUAGE")] || description?.en}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled value={undefined}>
                    No options available
                  </MenuItem>
                )}
              </NcrField>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box mt={4}>
        <CommentComponent name="comments" disableInput />
      </Box>
    </>
  );
};

export default FormTwo;
