import { useState, useMemo } from "react";
import { useFormikContext } from "formik";
import { useSnackbar } from "notistack";
import {
  Card,
  Typography,
  Box,
  Button,
  useMediaQuery,
  useTheme,
  Radio,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import searchNewSelect from "@/lib/searchNewSelect";
import NewSelectAutocomplete from "@/components/NewSelectAutocomplete";
import authHeaders from "@/lib/authHeaders";

const emtpyOneDTemplate = {
  company: {},
  item: {},
  customer: {},
  vendor: {},
};

const AutoFillCard = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { enqueueSnackbar } = useSnackbar();
  const { setFieldValue, resetForm, values, initialValues } =
    useFormikContext();

  const [autofillValue, setAutofillValue] = useState(null);
  const [autofillType, setAutofillType] = useState("production_order_number");
  const [loading, setLoading] = useState(false);

  const formHasChanged = values.one_d !== initialValues.one_d;

  const handleAutofill = () => {
    setLoading(true);
    fetch(
      `${import.meta.env.VITE_VTS_API}/v1/autofill_ncr?${autofillType}=${
        autofillValue.value
      }`,
      { headers: authHeaders() },
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        } else {
          return res.json();
        }
      })
      .then((data) => {
        setFieldValue("one_d", { ...emtpyOneDTemplate, ...data });
        setFieldValue("two_d.machine_number", undefined);
        setAutofillValue(null);
      })
      .catch((error) => {
        error?.json().then((body) => {
          enqueueSnackbar(`${body.status}: Failed to get autofill data`, {
            variant: "error",
          });
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchProjects = useMemo(() => {
    return searchNewSelect(autofillType);
  }, [autofillType]);

  const handleChange = (event) => {
    setAutofillType(event.target.value);
    setAutofillValue(null);
  };

  return (
    <Card
      variant="outlined"
      sx={{
        px: 2,
        py: 1,
        width: isMobile ? "100%" : "90%",
        mx: "auto",
        borderRadius: 2,
      }}
    >
      <Typography variant="body2" color="primary">
        Autofill
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Start by selecting a type and filling in a number. Click "fill" to
        autofill the form
      </Typography>
      <Box mb={1}>
        <div>
          <FormControlLabel
            checked={Boolean(autofillType === "production_order_number")}
            onChange={handleChange}
            value="production_order_number"
            label="Production order"
            control={<Radio />}
          />
          <FormControlLabel
            checked={Boolean(autofillType === "purchase_order_number")}
            onChange={handleChange}
            value="purchase_order_number"
            label="Purchase order"
            control={<Radio />}
          />
        </div>
        <NewSelectAutocomplete
          label={
            autofillType === "purchase_order_number"
              ? "Purchase order"
              : "Production order"
          }
          optionsCallBack={fetchProjects}
          onChange={(value) => setAutofillValue(value)}
          value={autofillValue}
          fullWidth
          variant="outlined"
          required
          size="small"
        />
      </Box>
      <Box display="flex">
        <Box ml="auto">
          {formHasChanged && (
            <Button type="button" size="small" onClick={() => resetForm()}>
              Reset
            </Button>
          )}
          <Button
            type="button"
            size="small"
            onClick={() => handleAutofill()}
            color="primary"
            disabled={!autofillValue?.value}
          >
            {loading ? (
              <CircularProgress size={18} style={{ margin: 2 }} />
            ) : (
              "Fill"
            )}
          </Button>
        </Box>
      </Box>
    </Card>
  );
};

export default AutoFillCard;
