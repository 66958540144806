import { LinearProgress } from "@mui/material";
import { useAppSelector } from "@/redux/hooks";

const GlobalLoading = () => {
  //redux state
  const loadingData = useAppSelector(
    (state) => state["appCommon"].isLoadingData,
  );
  return loadingData && <LinearProgress color="primary" />;
};

export default GlobalLoading;
