import authHeaders from "./authHeaders";

export const fetchWithToken = (url: string, requestOptions?: RequestInit) =>
  fetch(url, {
    headers: authHeaders(),
    ...requestOptions,
  }).then((res) => {
    if (!res.ok) {
      throw Error(`${res.status}: ${res.statusText}`);
    } else return res.json();
  });
