export const temporaryActionRequired = (
  type,
  level,
  setFieldValue,
  setTempActionDisabled,
) => {
  if (typeof type !== "string") {
    throw new Error("type is not a string");
  }
  if (
    ![
      "hms",
      "deviation",
      "complaint",
      "deviation_permit",
      "technical_clarification",
    ].includes(type)
  ) {
    throw new Error(
      "type parameter only accepts values: hms, deviation, complaint, deviation_permit, technical_clarification",
    );
  }
  if (typeof level !== "string") {
    throw new Error("level is not a string");
  }
  if (!["registration", "action", "critical"].includes(level)) {
    throw new Error(
      "level only accepts the following values: registration",
      "action",
      "critical",
    );
  }
  if (typeof setFieldValue !== "function") {
    throw new Error("setFieldValue is not a function");
  }
  if (typeof setTempActionDisabled !== "function") {
    throw new Error("setTempActionDisabled is not a function");
  }

  const checkboxName = "two_d.temporary_action_required";

  const forceTrueDisabled = () => {
    setFieldValue(checkboxName, true);
    setTempActionDisabled(true);
  };

  const forceFalseDisabled = () => {
    setFieldValue(checkboxName, false);
    setTempActionDisabled(true);
  };

  const forceFalseEnabled = () => {
    setFieldValue(checkboxName, false);
    setTempActionDisabled(false);
  };

  const forceTrueEnabled = () => {
    setFieldValue(checkboxName, true);
    setTempActionDisabled(false);
  };
  //HMS
  if (type === "hms") {
    if (level === "registration" || level === "action") {
      forceTrueDisabled();
    }
    if (level === "critical") {
      forceFalseDisabled();
    }
  }

  // Deviation
  if (type === "deviation") {
    if (level === "registration") {
      forceFalseEnabled();
    }
    if (level === "action") {
      forceTrueDisabled();
    }
    if (level === "critical") {
      forceFalseDisabled();
    }
  }

  // Complaint
  if (type === "complaint") {
    if (level === "action") {
      forceTrueDisabled();
    }
    if (level === "critical") {
      forceFalseDisabled();
    }
  }

  // Deviation permit || Technical clarification
  if (type === "deviation_permit" || type === "technical_clarification") {
    if (level === "registration") {
      forceTrueEnabled();
    }
  }
};
