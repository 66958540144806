import React from "react";
import PropTypes from "prop-types";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import { TextField } from "@mui/material";
import getUserLang from "@/utils/getUserLang";
import moment from "moment";

const DateTimePickerField = ({
  form,
  field: { value, name },
  getShouldDisableDateError,
  inputProps,
  translations,
  ...other
}) => {
  const t = translations;
  const currentError = form.errors[name];
  const toShowError = Boolean(currentError);
  const userLang = getUserLang(true);

  return (
    <DateTimePicker
      value={value}
      inputFormat={userLang.inputFormat}
      mask={userLang.mask}
      onError={(reason, value) => {
        switch (reason) {
          case "invalidDate":
            form.setFieldError(name, t.invalidDateError);
            break;

          case "disablePast":
            form.setFieldError(name, t.disablePastError);
            break;

          case "disableFuture":
            form.setFieldError(name, t.disableFutureError);
            break;

          case "maxDate":
            form.setFieldError(
              name,
              `${t.maxDateError} ${moment(other.maxDate).format("LLL")}`,
            );
            break;

          case "minDate":
            form.setFieldError(
              name,
              `${t.minDateError} ${moment(other.minDate).format("LLL")}`,
            );
            break;

          case "shouldDisableDate":
            // shouldDisableDate returned true, render custom message according to the `shouldDisableDate` logic
            form.setFieldError(name, getShouldDisableDateError(value));
            break;

          default:
            form.setErrors({
              ...form.errors,
              [name]: undefined,
            });
        }
      }}
      // Make sure that your 3d param is set to `false` on order to not clear errors
      onChange={(date) => form.setFieldValue(name, date, false)}
      renderInput={(props) => (
        <TextField
          {...props}
          {...inputProps}
          name={name}
          error={toShowError}
          autoComplete="off"
          helperText={
            toShowError ? currentError ?? props.helperText : undefined
          }
          // Make sure that your 3d param is set to `false` on order to not clear errors
          onBlur={() => form.setFieldTouched(name, true, false)}
        />
      )}
      {...other}
    />
  );
};

DateTimePickerField.defaultProps = {
  translations: {
    invalidDateError: "Invalid date format",
    disablePastError: "Past dates are not allowed",
    disableFutureError: "Future dates are not allowed",
    maxDateError: "Date should not be before",
    minDateError: "Date should not be after",
  },
};

DateTimePickerField.propTypes = {
  form: PropTypes.shape({
    errors: PropTypes.any,
    setErrors: PropTypes.func,
    setFieldError: PropTypes.func,
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
    touched: PropTypes.any,
  }),
  field: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.object,
  }),
  getShouldDisableDateError: PropTypes.func,
  maxDate: PropTypes.instanceOf(moment),
  minDate: PropTypes.instanceOf(moment),
  inputProps: PropTypes.object,
  translations: PropTypes.shape({
    invalidDateError: PropTypes.string,
    disablePastError: PropTypes.string,
    disableFutureError: PropTypes.string,
    maxDateError: PropTypes.string,
    minDateError: PropTypes.string,
  }),
};

export default DateTimePickerField;
