import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PrintIcon from "@mui/icons-material/Print";
// @ts-ignore
import ResponsiveButtons from "@/components/ResponsiveButtons";

const BackButton = () => {
  const options = [
    {
      title: "Back",
      onClick: () => window.history.back(),
      icon: <ArrowBackIcon />,
    },
    {
      title: "Print",
      onClick: () => window.print(),
      icon: <PrintIcon />,
    },
  ];

  return <ResponsiveButtons options={options} />;
};

export default BackButton;
