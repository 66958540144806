import { PublicClientApplication } from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: import.meta.env.VITE_MS_KEY,
    redirectUri: "/auth",
    postLogoutRedirectUri: "",
  },
};

const msalInstance = new PublicClientApplication(msalConfig);

export default msalInstance;
