import authHeaders from "@/lib/authHeaders";
import WeldLogInfo from "@/models/WeldLogInfo";
import { FetchResponse } from "@/types";

const getWeldLogInfo = (id: number): Promise<WeldLogInfo | FetchResponse> =>
  fetch(`${import.meta.env.VITE_VTS_API}/v1/weld_logs/info/${id}`, {
    headers: authHeaders(),
  }).then((res) => {
    if (res.ok) {
      return res.json();
    } else throw res;
  });

export default getWeldLogInfo;
