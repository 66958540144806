import React from "react";
import App from "./pages/main/App";
import { BrowserRouter } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material/styles";
import AuthProvider from "./pages/main/AuthProvider";
import CssBaseline from "@mui/material/CssBaseline";
import { SnackbarProvider } from "notistack";
import ErrorPage from "./components/ErrorPage";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { LicenseInfo } from "@mui/x-license-pro";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "./config/i18n";
import "moment/locale/nb";
import momentLocale from "./utils/momentLocale";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import "./config/sentry";
import * as Sentry from "@sentry/react";
import ThemeProvider from "@/theme";
import "./config/clarity";
import { createRoot } from "react-dom/client";

// MUI X Pro
LicenseInfo.setLicenseKey(
  "05472703e0655d1c490b367fafea7df3T1JERVI6NDI5NzcsRVhQSVJZPTE2ODMxODQ3NDQwMDAsS0VZVkVSU0lPTj0x",
);

const queryClient = new QueryClient();

const FallbackComponent = () => (
  <ErrorPage
    onClick={() => {
      localStorage.clear();
      window.location.reload();
    }}
  />
);

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <LocalizationProvider
        dateAdapter={AdapterMoment}
        adapterLocale={momentLocale()}
      >
        <StyledEngineProvider injectFirst>
          <ThemeProvider>
            <Sentry.ErrorBoundary fallback={FallbackComponent}>
              <AuthProvider>
                <Provider store={store}>
                  <SnackbarProvider maxSnack={3} preventDuplicate>
                    <BrowserRouter>
                      <CssBaseline />
                      <App />
                    </BrowserRouter>
                  </SnackbarProvider>
                </Provider>
              </AuthProvider>
            </Sentry.ErrorBoundary>
          </ThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </QueryClientProvider>
  </React.StrictMode>,
);
