const validate = (values, t) => {
  let errors = {};
  if (!(values.work || values.address || values.machine_number || values.name))
    errors = {
      work: t("Please, select at least one these filters"),
      name: t("Please, select at least one these filters"),
      address: t("Please, select at least one these filters"),
      machine_number: t("Please, select at least one these filters"),
    };
  return Object.keys(errors).length > 0 ? errors : null;
};

export default validate;
