import { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import Ncr from "./Ncr";

const Message = (props) => {
  const { initialMinute = 0, initialSeconds = 0 } = props;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  return (
    <Box display="flex" alignItems="center">
      <Box flex={1} mr={2} pt={1}>
        <CircularProgress
          variant="determinate"
          value={(seconds * 100) / initialSeconds}
          size={24}
        />
      </Box>
      <Box>
        <div>{`Wating for NCR to synchronize with Navision...`}</div>
        <div>{`Page will reload in: ${seconds} seconds`}</div>
      </Box>
    </Box>
  );
};

const AddNcrFromIframe = () => {
  const { enqueueSnackbar } = useSnackbar();

  const timeout = 10000;
  return (
    <Ncr
      formOnly
      onSubmit={() => {
        enqueueSnackbar(<Message initialSeconds={timeout / 1000} />, {
          autoHideDuration: timeout,
        });
        setTimeout(() => {
          window.location.reload();
        }, timeout);
      }}
    />
  );
};

export default AddNcrFromIframe;
