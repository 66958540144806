import React from "react";
import PropTypes from "prop-types";
import { Chip } from "@mui/material";
import { LinkRounded } from "@mui/icons-material";

const LinkedItem = ({ items }) => {
  return (
    <>
      {items?.map((item) => {
        return (
          <Chip
            sx={{ ml: 0.5, mb: 0.5, p: 0 }}
            label={item}
            size="small"
            color={"success"}
            variant={"filled"}
            icon={<LinkRounded />}
          />
        );
      })}
    </>
  );
};

LinkedItem.propTypes = {
  items: PropTypes.array,
};
export default LinkedItem;
