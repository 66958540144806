const getRoutingStatusColor = (status, delayed = false) => {
  const statusColors = {
    hold: "#C9302C",
    late: "#000000",
    delayed: "#000000",
    done: "#337AB7",
    out_of_sync: "#F0AD4E",
    pre_planned: "#FFFFFF",
    planned: "#A9A9A9",
    in_progress: "#5CB85C",
    finished: "#337AB7",
  };

  const whiteColorHex = "#FFFFFF";
  const blackColorHex = "#000000";

  if (delayed)
    return {
      bg: statusColors["delayed"],
      border: statusColors["delayed"],
      font: whiteColorHex,
    };

  switch (status) {
    case "hold": // red
      return {
        bg: statusColors["hold"],
        border: statusColors["hold"],
        font: whiteColorHex,
      };
    case "late": // black
      return {
        bg: statusColors["late"],
        border: statusColors["late"],
        font: whiteColorHex,
      };
    case "done": // blue
      return {
        bg: statusColors["done"],
        border: statusColors["done"],
        font: whiteColorHex,
      };
    case "out_of_sync": // yellow
      return {
        bg: statusColors["out_of_sync"],
        border: statusColors["out_of_sync"],
        font: blackColorHex,
      };
    case "pre_planned": // white
      return {
        bg: statusColors["pre_planned"],
        border: "#CCCCCC",
        font: blackColorHex,
      };
    case "planned": // gray
      return {
        bg: statusColors["planned"],
        border: statusColors["planned"],
        font: whiteColorHex,
      };
    case "in_progress": //green
      return {
        bg: statusColors["in_progress"],
        border: statusColors["in_progress"],
        font: whiteColorHex,
      };
    case "finished": // blue
      return {
        bg: statusColors["finished"],
        border: statusColors["finished"],
        font: whiteColorHex,
      };

    default:
      return { bg: whiteColorHex, border: "#E0E0E0", font: blackColorHex };
  }
};

export default getRoutingStatusColor;
