import { alpha, Box } from "@mui/material";
import { format, parseISO } from "date-fns";
import ColoredTooltip from "../../../components/ColoredTooltip";

const DeliveryQuantityCell = ({ quantity, delivery_date }) => {
  const whiteColorHex = "#FFFFFF";
  const blackColorHex = "#000000";
  const redColorHex = "#C9302C";

  const color = quantity ? whiteColorHex : blackColorHex;
  const bg =
    quantity && delivery_date
      ? blackColorHex
      : quantity && !delivery_date
        ? redColorHex
        : whiteColorHex;
  const backgroundColor = alpha(bg, 0.9);
  const border = quantity || delivery_date ? bg : "#E0E0E0";

  const deliveryDate = delivery_date
    ? format(parseISO(delivery_date), "dd.MM.yy")
    : "";

  return (
    <ColoredTooltip
      title={`${deliveryDate}`}
      baseColor={backgroundColor}
      placement="left"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor,
          color,
          height: "100%",
          width: "100%",
          fontSize: "inherit",
          borderBottom: `1px solid ${border}`,
        }}
      >
        {quantity}
      </Box>
    </ColoredTooltip>
  );
};

export default DeliveryQuantityCell;
