import authHeaders from "@/lib/authHeaders";
import WeldLogInfo from "@/models/WeldLogInfo";
import { FetchResponse } from "@/types";

const disapproveWeldLog = (id: number): Promise<WeldLogInfo | FetchResponse> =>
  fetch(`${import.meta.env.VITE_VTS_API}/v1/weld_logs/disapprove/${id}`, {
    headers: authHeaders(),
    method: "POST",
  }).then((res) => {
    if (res.ok || res.status === 200) {
      return res.json();
    } else throw res;
  });

export default disapproveWeldLog;
