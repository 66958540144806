import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Skeleton,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router";
import Alert from "@mui/material/Alert";

const CardBaseContainer = ({ sx, children }) => {
  return (
    <Card sx={{ ...sx, minHeight: "234px" }} variant="outlined">
      {children}
    </Card>
  );
};

const CardBaseTitle = ({ title }) => {
  return title && <CardHeader title={<Typography>{title}</Typography>} />;
};

const CardBaseContent = ({ content }) => {
  return (
    <CardContent sx={{ verticalAlign: "top", pt: 1, px: 3, pb: 0.5 }}>
      {content}
    </CardContent>
  );
};

const CardBase = ({
  title,
  content,
  actionTitle,
  actionHref,
  loading,
  error,
}) => {
  const navigate = useNavigate();

  if (error) {
    return (
      <CardBaseContainer>
        <CardBaseTitle title={title} />
        <CardBaseContent
          content={<Alert severity="error">{error?.message}</Alert>}
        />
      </CardBaseContainer>
    );
  }

  if (loading) {
    return (
      <CardBaseContainer>
        <CardBaseTitle title={title} />
        <CardBaseContent
          content={
            <>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </>
          }
        />
      </CardBaseContainer>
    );
  }

  if (actionHref && actionHref.length > 0) {
    return (
      <CardBaseContainer sx={{ display: "flex", alignItems: "stretch" }}>
        <CardActionArea
          href={actionHref}
          title={actionTitle}
          disabled={actionHref && actionHref.length <= 0}
          onClick={(e) => {
            e.preventDefault();
            navigate(actionHref);
          }}
        >
          <CardBaseTitle title={title} />
          <CardBaseContent content={content} />
        </CardActionArea>
      </CardBaseContainer>
    );
  }

  return (
    <CardBaseContainer>
      <CardBaseTitle title={title} />
      <CardBaseContent content={content} />
    </CardBaseContainer>
  );
};

export default CardBase;
