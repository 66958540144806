import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import { FastField, Field } from "formik";

const NcrField = ({
  rootName,
  name,
  children,
  fastField,
  emptyValue,
  helperText,
  error,
  ...props
}) => {
  let Wrapper = FastField;

  if (!fastField) {
    Wrapper = Field;
  }

  return (
    <Wrapper name={`${rootName ? rootName + "." : ""}${name}`}>
      {({ field, form, meta }) => (
        <TextField
          {...field}
          variant="outlined"
          size="small"
          error={Boolean(meta.error) || error}
          helperText={meta.error || helperText}
          fullWidth
          autoComplete="off"
          margin="normal"
          value={field?.value || emptyValue}
          {...props}
        >
          {children}
        </TextField>
      )}
    </Wrapper>
  );
};

NcrField.propTypes = {
  children: PropTypes.node,
  // Defaults to true, if false regular formik field will be used.
  // Set to false if your field needs to re-render when the value of another field changes
  fastField: PropTypes.bool,
  emptyValue: PropTypes.any,
  rootName: PropTypes.string,
  name: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  error: PropTypes.bool,
  //any other props will be passed down to material ui TextField
};

NcrField.defaultProps = {
  rootName: undefined,
  fastField: true,
  emptyValue: "",
};

export default NcrField;
