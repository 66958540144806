import { useState, useEffect } from "react";
import { InteractionStatus } from "@azure/msal-browser";
import {
  MsalProvider,
  useIsAuthenticated,
  useMsalAuthentication,
  useMsal,
  useAccount,
} from "@azure/msal-react";
import msalInstance from "@/config/msLogin";
import vtsLogo from "@/images/vtsLogo.png";
import LoadingPage from "@/components/LoadingPage";

const getRailsUserInfo = (userInfo) =>
  fetch(`${import.meta.env.VITE_VTS_API}/v1/users/current`, {
    headers: { authorization: `Bearer ${userInfo.accessToken}` },
  })
    .then((r) => r.json())
    .then(({ id, role }) => ({
      ...userInfo,
      railsUserId: id,
      railsUserRole: role,
    }));

const LoginProgressComponent = ({ error, errorMessage }) => (
  <LoadingPage
    logo={vtsLogo}
    error={errorMessage ? { message: errorMessage } : null}
  />
);

/* This will initiate the MSAL auth provider */
const LoginProvider = ({ children }) => (
  <MsalProvider instance={msalInstance}>
    <Login>
      <GetToken>{children}</GetToken>
    </Login>
  </MsalProvider>
);

/* This will handle the login/redirect */
const Login = ({ children }) => {
  const userInfo = JSON.parse(localStorage.getItem("msToken"));
  const isAuthenticated = useIsAuthenticated();
  const { inProgress } = useMsal();
  const { login, error } = useMsalAuthentication("silent", {
    loginHint: userInfo?.account.username,
    scopes: userInfo?.scopes,
  });

  useEffect(() => {
    if (error && inProgress === InteractionStatus.None) {
      login("redirect").catch((err) => {
        console.error("Login error:", err);
      });
    }
  }, [error, inProgress, login]);

  return isAuthenticated ? (
    <AutoUpdateToken>{children}</AutoUpdateToken>
  ) : (
    <LoginProgressComponent />
  );
};

/* This will get the token */
const GetToken = ({ children }) => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [authenticated, setAuthenticated] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    if (account) {
      instance
        .acquireTokenSilent({
          scopes: ["User.Read"],
          account: account,
        })
        .then((response) => {
          if (!response) throw new Error("Empty response");
          return getRailsUserInfo(response);
        })
        .then((userInfo) => {
          localStorage.setItem("msToken", JSON.stringify(userInfo));
          setAuthenticated(true);
        })
        .catch((err) => {
          setError(true);
          setErrorMessage(err.toString());
        });
    }
  }, [account, instance]);

  return error || !authenticated ? (
    <LoginProgressComponent error={error} errorMessage={errorMessage} />
  ) : (
    children
  );
};

const AutoUpdateToken = ({ children }) => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  useEffect(() => {
    const interval = setInterval(
      () => {
        if (account)
          instance
            .acquireTokenSilent({
              scopes: ["User.Read"],
              account: account,
            })
            .then((r) => {
              localStorage.setItem(
                "msToken",
                JSON.stringify({
                  ...JSON.parse(localStorage.getItem("msToken")),
                  ...r,
                }),
              );
            });
      },
      30 * 60 * 1000,
    );
    return () => clearInterval(interval);
  }, [account, instance]);

  return children;
};

export default LoginProvider;
