import { useState, useCallback } from "react";
import {
  ColumnDefinition,
  FiltersDefinition,
  SortParamsDefinition,
  RowDefinition,
} from "@/types";
import { matchSorter } from "match-sorter";
import orderBy from "lodash/orderBy";

export type Options = {
  multiSort?: boolean;
  debug?: boolean;
  mode?: "client" | "server"; //default is server
};

export type Pagination = {
  page: number;
  rowsPerPage: number;
};

export type UseTableProps = {
  initialFilters?: FiltersDefinition;
  columns?: ColumnDefinition[];
  initialRows?: RowDefinition[];
  options?: Options;
  initialPagination?: Pagination;
  initialSort?: SortParamsDefinition;
  initialPage?: number;
  initialRowsPerPage?: number;
  initialSearch?: string;
};

const useTable = ({
  options,
  columns = [],
  initialRows = [],
  initialSort = {},
  initialFilters = {},
  initialPage = 0,
  initialRowsPerPage = 20,
  initialSearch = "",
}: UseTableProps) => {
  const currentOptions = {
    multiSort: true,
    debug: false,
    mode: "server",
    ...options,
  };

  const [refreshedAt, setRefreshed] = useState(new Date().getTime());
  const [filters, setFilters] = useState<FiltersDefinition>(initialFilters);
  const [rows, setRows] = useState<RowDefinition[]>(initialRows);
  const [error, setError] = useState(false);
  const [search, setSearch] = useState(initialSearch);
  const [totalRows, setTotalRows] = useState<number>(rows?.length);
  const [loading, setLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState<number>(initialRowsPerPage);
  const [sorting, setSorting] = useState<SortParamsDefinition>(initialSort);

  const loadedRows = rows?.length || 0;

  const calculateRows = () => {
    let newRows = [];
    // first we filter the rows
    const filteredRows = filterRows(filters, rows);

    // then we apply global search
    const searchedRows = fuzzySearch(
      filteredRows,
      columns.map((c) => c.key),
      search,
    );
    // then we sort the rows
    const sortedRows = sortRows(sorting, searchedRows);

    newRows = sortedRows;

    return newRows;
  };

  const handleApplyFilter = (filter: FiltersDefinition) => {
    if (filter && Object.keys(filter).length > 0) {
      setFilters((previousFilter) => ({
        ...previousFilter,
        ...filter,
      }));
    }
  };

  const handleRemoveFilter = (key: string) => {
    const newFilters = { ...filters } as FiltersDefinition;
    delete newFilters[key];
    setFilters(newFilters);
  };

  const handleClearFilters = (revertToInitial?: boolean) => {
    revertToInitial ? setFilters(initialFilters) : setFilters({});
  };

  const handleToggleSort = (key: string) => {
    const isSorted = Object.keys(sorting).includes(key);
    const isAsc = sorting[key] === "asc";
    const isDesc = sorting[key] === "desc";

    const appendSort = (direction: "asc" | "desc" | "") => {
      const newSorting = { [key]: direction } as SortParamsDefinition;
      setSorting((previousSorting) => ({
        ...previousSorting,
        ...newSorting,
      }));
    };

    const replaceSort = (direction: "asc" | "desc" | "") => {
      const newSorting = { [key]: direction } as SortParamsDefinition;
      setSorting(newSorting);
    };

    if (isSorted) {
      if (isAsc) {
        currentOptions.multiSort ? appendSort("desc") : replaceSort("desc");
      }

      if (isDesc) {
        const newSorting = { ...sorting };
        delete newSorting[key];
        setSorting(newSorting);
      }
    } else {
      currentOptions.multiSort ? appendSort("asc") : replaceSort("asc");
    }
  };

  const handleClearSort = (revertToInitial?: boolean) => {
    revertToInitial ? setSorting(initialSort) : setSorting({});
  };

  const handleAddRows = useCallback(
    (newRows: RowDefinition[], replace?: boolean) => {
      if (replace) {
        setRows(newRows);
      } else {
        setRows((previousRows) => [...previousRows, ...newRows]);
      }
    },
    [],
  );

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (newRowsPerPage: number) => {
    setRowsPerPage(newRowsPerPage);
  };

  const handleIncrementPage = () => {
    setPage((prev) => prev + 1);
  };

  const handleDecrementPage = () => {
    setPage((prev) => (prev !== 0 ? prev - 1 : initialPage));
  };

  const handleRefresh = () => {
    setRefreshed(new Date().getTime());
  };

  const handleSearch = (newValue: string) => {
    setSearch(newValue.trim());
  };

  const isFiltered = Object.keys(filters).length > 0 || Boolean(search);

  const calculatedRows = calculateRows();

  const getPaginatedRows = () => {
    if (currentOptions.mode === "client") {
      return paginateRows(calculatedRows, page, rowsPerPage, isFiltered);
    } else return paginateRows(rows, page, rowsPerPage, isFiltered);
  };

  const baseState = {
    getPaginatedRows,
    filters,
    isFiltered,
    error,
    search,
    columns,
    totalRows,
    loading,
    page,
    rowsPerPage,
    loadedRows,
    sorting,
    refreshedAt,
  };

  const clientState = {
    ...baseState,
    rows: calculatedRows,
    originalRows: rows,
  };

  const serverState = {
    ...baseState,
    rows,
  };

  const setters = {
    setFilters,
    setRows,
    setError,
    setSearch,
    setTotalRows,
    setLoading,
    setPage,
    setRowsPerPage,
    setSorting,
  };
  const handlers = {
    handleApplyFilter,
    handleRemoveFilter,
    handleToggleSort,
    handleAddRows,
    handleIncrementPage,
    handleDecrementPage,
    handlePageChange,
    handleRefresh,
    handleChangeRowsPerPage,
    handleSearch,
    handleClearSort,
    handleClearFilters,
  };

  currentOptions.debug &&
    console.log(
      "useTableState",
      currentOptions.mode === "client" ? clientState : serverState,
    );

  if (currentOptions.mode === "client") {
    return { state: clientState, setters, handlers };
  }
  return { state: serverState, setters, handlers };
};

export default useTable;

const filterRows = (filters: FiltersDefinition, array: RowDefinition[]) => {
  let result = array;

  for (const [key, value] of Object.entries(filters)) {
    // TODO: When filterType is "multipleSelect", the value is an array of objects.
    // Do we need to change the data shape of filters to array of objects? This should allow us to add filterMethod callback to the column definition as well
    /* @ts-expect-error: Gives error because it only handles simple filters */
    const match = matchSorter(result, value?.value || "", { keys: [key] });
    result = [...match];
  }

  return result;
};

const sortRows = (sorting: SortParamsDefinition, array: RowDefinition[]) => {
  const keysToSort = Object.keys(sorting);
  const sortDirections = Object.values(sorting) as Array<"asc" | "desc">;
  return orderBy(array, keysToSort, sortDirections);
};

const fuzzySearch = (
  rows: RowDefinition[], // array of data [{a: "a", b: "b"}, {a: "c", b: "d"}]
  keys: Array<string>, // array of keys ["a", "b"]
  filterValue: string, // potentially multi-word search string "two words"
) => {
  if (!filterValue || !filterValue.length) {
    return rows;
  }

  const terms = filterValue.split(" ");
  if (!terms) {
    return rows;
  }

  // reduceRight will mean sorting is done by score for the _first_ entered word.
  return terms.reduceRight(
    (results, term) => matchSorter(results, term, { keys }),
    rows,
  );
};

export const paginateRows = (
  array: RowDefinition[],
  page: number,
  rowsPerPage: number,
  isFiltered: boolean,
) => {
  const showAllRows = rowsPerPage === -1;
  if (isFiltered) return array;
  if (showAllRows) {
    return array;
  } else
    return array.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
};
