const authHeaders = ({ defaultContentType = false } = {}) => {
  const userAuthData = JSON.parse(localStorage.getItem("msToken"));
  if (defaultContentType) {
    return new Headers({
      Authorization: "Bearer " + userAuthData?.accessToken,
      Accept: "application/json",
    });
  } else
    return new Headers({
      Authorization: "Bearer " + userAuthData?.accessToken,
      Accept: "application/json",
      "Content-Type": "application/json",
    });
};
export default authHeaders;
