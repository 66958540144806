import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Autocomplete from "@mui/material/Autocomplete";
import { TextField, Chip } from "@mui/material";

import useDebounce from "@/hooks/useDebounce";

const AutocompleteMultiselect = ({
  label,
  onChange,
  value,
  chipKey,
  fetchOptions,
  options,
  debounceTimeout,
  valueKey,
  textKey,
  TextFieldProps,
  ...props
}) => {
  const [loadedOptions, setLoadedOptions] = useState(
    fetchOptions ? [] : options ?? [],
  );
  const [term, setTerm] = useState();
  const [internalValue, setInternalValue] = useState(value);
  const [loading, setLoading] = useState(false);
  const debouncedTerm = useDebounce(term, debounceTimeout);

  useEffect(() => {
    if (typeof fetchOptions === "function") {
      setLoadedOptions([]);
      setLoading(true);
      fetchOptions(debouncedTerm)
        .then((results) => {
          setLoadedOptions(results);
        })
        .catch((e) => {
          console.error(e);
          setLoadedOptions([{ [valueKey]: "", [textKey]: "Error" }]);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [debouncedTerm, fetchOptions, valueKey, textKey]);

  const isLoading = () => {
    if (fetchOptions && loading) return loading;
    else return false;
  };

  return (
    <Autocomplete
      fullWidth
      multiple
      closeOnSelect={false}
      autoHighlight
      getOptionLabel={(option) => option[textKey]}
      renderInput={(params) => (
        <TextField {...params} label={label} {...TextFieldProps} />
      )}
      isOptionEqualToValue={(option, selectedValue) =>
        option[valueKey] === selectedValue[valueKey]
      }
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={index}
            label={option[chipKey]}
            size="small"
            {...getTagProps({ index })}
          />
        ))
      }
      {...props}
      value={internalValue}
      options={loadedOptions}
      onBlur={() => setTerm()}
      onChange={(e, v) => {
        setInternalValue(v);
        onChange(v);
      }}
      onInputChange={(e, newInputValue) => {
        if (fetchOptions && newInputValue === e?.target?.value) {
          setTerm(newInputValue);
          setLoading(true);
        }
      }}
      loading={isLoading()}
      filterOptions={fetchOptions ? (x) => x : undefined}
    />
  );
};

AutocompleteMultiselect.defaultProps = {
  value: [],
  chipKey: "text",
  valueKey: "value",
  textKey: "text",
  debounceTimeout: 250,
};

AutocompleteMultiselect.propTypes = {
  /*  */
  fetchOptions: PropTypes.func,
  /* Required if fetchOptions is not used */
  options: PropTypes.array,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  debounceTimeout: PropTypes.number,
  value: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    ),
  ),
  chipKey: PropTypes.string,
  TextFieldProps: PropTypes.any,
};

export default AutocompleteMultiselect;
