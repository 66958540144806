import React, { ReactNode } from "react";
import {
  Box,
  BoxProps,
  CardHeader,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import MaterialFilterChip from "./MaterialFilterChip";
import DebouncedSearch from "../DebouncedSearch";

import {
  ColumnDefinition,
  FiltersDefinition,
  SearchEventCallback,
} from "@/types";

export interface MaterialToolbarProps extends BoxProps {
  title?: string;
  filters?: FiltersDefinition;
  onSearch?: SearchEventCallback;
  loading?: boolean;
  columns: ColumnDefinition[];
  extraToolbarComponent?: ReactNode;
  extraToolbarComponentAfter?: ReactNode;
  onRemoveFilter?: React.Dispatch<string>;
  loadedRows?: number;
  totalRows?: number;
  initialSearch?: string;
}

const MaterialToolbar = ({
  title,
  totalRows,
  loadedRows,
  filters,
  columns,
  loading,
  onSearch,
  extraToolbarComponent,
  extraToolbarComponentAfter,
  onRemoveFilter,
  initialSearch,
  ...props
}: MaterialToolbarProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const loadedCount =
    typeof Number.isFinite(loadedRows) && loadedRows !== 0 && loadedRows;
  const totalCount = Number.isFinite(totalRows) && totalRows !== 0 && totalRows;

  const handleSearch = (value: string) => {
    onSearch?.(value);
  };

  return (
    <div>
      <Box display="flex" alignItems="center" flexWrap="wrap" m={2} {...props}>
        <Box flex={1}>
          <CardHeader
            title={title}
            titleTypographyProps={{ variant: "subtitle1", noWrap: true }}
            subheader={
              loading ? (
                <>{"Loading..."}</>
              ) : (
                <div>
                  {loadedCount && <span>{`${loadedCount} / `}</span>}
                  {totalCount && <span>{`${totalCount}`}</span>}
                  {totalCount && <span>{` results`}</span>}
                </div>
              )
            }
            subheaderTypographyProps={{ variant: "subtitle2" }}
            sx={{ p: 0 }}
          />
        </Box>
        {filters && Object.keys(filters)?.length > 0 && (
          <Box flexBasis={isMobile ? "100%" : undefined} mr={2} my={1}>
            <MaterialFilterChip
              onRemoveFilter={onRemoveFilter}
              filters={filters}
              columns={columns}
            />
          </Box>
        )}
        {extraToolbarComponent && extraToolbarComponent}
        {onSearch && (
          <Box flex={isMobile ? 1 : undefined} minWidth={150}>
            <DebouncedSearch
              onSearchChange={handleSearch}
              size="small"
              style={{ width: "100%" }}
              initialValue={initialSearch}
            />
          </Box>
        )}
        {extraToolbarComponentAfter && extraToolbarComponentAfter}
      </Box>
    </div>
  );
};

MaterialToolbar.defaultProps = {};

export default MaterialToolbar;
