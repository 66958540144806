import authHeaders from "../../../lib/authHeaders";
import FiltersDataInfo from "../../../models/project/delivery-plan/FiltersDataInfo";

const loadFilterOptions = async (): Promise<FiltersDataInfo> => {
  return fetch(
    `${import.meta.env.VITE_VTS_API}/v1/project/delivery_plans/filter_options`,
    {
      headers: authHeaders(),
    },
  ).then((response) => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error(`Error ${response.status}. Failed to load the filters.`);
    }
  });
};

export default loadFilterOptions;
