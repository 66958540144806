import { useState } from "react";
import {
  Autocomplete,
  Box,
  Card,
  CircularProgress,
  Container,
  Fade,
  Stack,
  TextField,
} from "@mui/material";
import {
  DateRange,
  DateRangePicker,
} from "@mui/x-date-pickers-pro/DateRangePicker";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import moment, { Moment } from "moment";
import pickBy from "lodash/pickBy";
import { createFilterOptions } from "@mui/material/Autocomplete";
import { fetchWithToken } from "@/lib/fetchWithToken";
import ConditionalMessage from "@/components/ConditionalMessage";
import WeldDefectsTable from "@/pages/reports/weld-defects/WeldDefectsTable";
import WeldDefectChart from "@/pages/reports/weld-defects/WeldDefectsChart";

export type WeldDefect = {
  date: string;
  weld_length: string;
  weld_defect: string;
  rate: string;
};

export interface WeldDefectResponse {
  total_rate?: number;
  total_weld_length?: number;
  total_defect?: number;
  results: WeldDefect[];
}

interface SelectOption {
  value: string;
  text: string;
}

interface SelectQueryResponse {
  results: SelectOption[];
}

const projectFilterOptions = createFilterOptions({
  stringify: (option: SelectOption) => option.value + option.text,
});

const defaultSelectQueryOptions = { initialData: { results: [] } };

const fetchWeldDefects = (params: {}) => {
  const urlParams = new URLSearchParams(params).toString();
  return fetchWithToken(
    `${import.meta.env.VITE_VTS_API}/v1/weld/weld_defects?${urlParams}`,
  );
};

const WeldDefects = () => {
  const [dateRange, setDateRange] = useState<DateRange<Moment>>([
    moment().startOf("year"),
    moment().startOf("day"),
  ]);
  const [project, setProject] = useState<SelectOption | null>(null);
  const [machine, setMachine] = useState<SelectOption | null>(null);
  const [wps, setWps] = useState<SelectOption | null>(null);

  const [startDate, endDate] = dateRange;

  const filterParams = {
    start_date: startDate?.toISOString() ?? null,
    end_date: endDate?.toISOString() ?? null,
    project_number: project?.value,
    machine_number: machine?.value,
    wps_number: wps?.value,
  };

  const requiredFiltersAreFilled = Boolean(
    filterParams?.start_date && filterParams?.end_date,
  );

  const weldDefects: UseQueryResult<WeldDefectResponse, Error> = useQuery(
    ["weldDefects", filterParams],
    () => fetchWeldDefects(pickBy(filterParams)),
    {
      enabled: requiredFiltersAreFilled,
    },
  );

  const machineOptions: UseQueryResult<SelectQueryResponse, Error> = useQuery(
    ["machineOptions"],
    () =>
      fetchWithToken(`${import.meta.env.VITE_VTS_API}/v1/weld/machine_select`),
    defaultSelectQueryOptions,
  );

  const wpsOptions: UseQueryResult<SelectQueryResponse, Error> = useQuery(
    ["wpsOptions"],
    () => fetchWithToken(`${import.meta.env.VITE_VTS_API}/v1/weld/wps_select`),
    defaultSelectQueryOptions,
  );

  const projectOptions: UseQueryResult<SelectQueryResponse, Error> = useQuery(
    ["projectOptions"],
    () =>
      fetchWithToken(`${import.meta.env.VITE_VTS_API}/v1/weld/project_select`),
    defaultSelectQueryOptions,
  );

  const totalDefect = weldDefects?.data?.total_defect;
  const totalRate = weldDefects?.data?.total_rate;
  const totalWeldLength = weldDefects?.data?.total_weld_length;
  const chartData = weldDefects?.data?.results ?? [];

  return (
    <>
      <Stack
        direction={{ lg: "row", xs: "column" }}
        spacing={2}
        justifyContent="center"
      >
        <DateRangePicker
          onChange={(newValue) => {
            setDateRange(newValue);
          }}
          value={dateRange}
          renderInput={(startProps, endProps) => (
            <Stack
              direction={{ lg: "row", xs: "column" }}
              spacing={2}
              width="100%"
            >
              <TextField
                {...startProps}
                label="Start date"
                size="small"
                required
              />
              <TextField {...endProps} label="End date" size="small" required />
            </Stack>
          )}
        />

        <Autocomplete
          sx={{ width: { lg: 300, xs: "100%" } }}
          loading={projectOptions.isLoading}
          onChange={(event, val) => setProject(val)}
          value={project}
          options={projectOptions.data.results}
          getOptionLabel={(option) => option?.value ?? ""}
          isOptionEqualToValue={(option, value) =>
            option?.value === value?.value
          }
          filterOptions={projectFilterOptions}
          renderInput={(params) => (
            <TextField {...params} label="Project number" size="small" />
          )}
        />

        <Autocomplete
          sx={{ width: { lg: 300, xs: "100%" } }}
          loading={machineOptions.isLoading}
          options={machineOptions.data.results}
          onChange={(event, val) => setMachine(val)}
          value={machine}
          getOptionLabel={(option) => option?.value ?? ""}
          renderInput={(params) => (
            <TextField {...params} label="Machine number" size="small" />
          )}
        />

        <Autocomplete
          sx={{ width: { lg: 300, xs: "100%" } }}
          onChange={(event, val) => setWps(val)}
          value={wps}
          loading={wpsOptions.isLoading}
          options={wpsOptions.data.results}
          getOptionLabel={(option) => option?.value ?? ""}
          renderInput={(params) => (
            <TextField {...params} label="WPS Number" size="small" />
          )}
        />
      </Stack>

      <ConditionalMessage
        primary="An error occurred"
        secondary="Please try again"
        condition={weldDefects.isError}
      >
        <Container maxWidth="lg" sx={{ mt: 4 }}>
          {weldDefects.isLoading ? (
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              height={350}
            >
              <CircularProgress />
            </Box>
          ) : (
            <Fade in>
              <div>
                <WeldDefectChart
                  totalDefect={totalDefect}
                  totalWeldLength={totalWeldLength}
                  totalRate={totalRate}
                  data={chartData}
                  height={350}
                />

                <Box mt={4} mb={10}>
                  <Card variant="outlined" sx={{ borderRadius: 2 }}>
                    <WeldDefectsTable
                      rows={chartData}
                      key={weldDefects.dataUpdatedAt}
                    />
                  </Card>
                </Box>
              </div>
            </Fade>
          )}
        </Container>
      </ConditionalMessage>
    </>
  );
};

export default WeldDefects;
