import authHeaders from "../../../lib/authHeaders";

const loadDeliveryPlanData = async (filters) =>
  fetch(
    `${import.meta.env.VITE_VTS_API}/v1/project/delivery_plans?${filters}`,
    {
      headers: authHeaders(),
    },
  ).then((response) => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error(`Error: ${response.status}.`);
    }
  });

export default loadDeliveryPlanData;
