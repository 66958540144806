import { Button, Snackbar } from "@mui/material";
import { useState } from "react";

const EnvironmentMessage = () => {
  const [open, setOpen] = useState(true);

  const shouldHide =
    import.meta.env.VITE_RELEASE_ENV === undefined ||
    import.meta.env.VITE_RELEASE_ENV === "production";

  if (shouldHide) {
    return null;
  }

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      message={`You are on ${import.meta.env.VITE_RELEASE_ENV} environment`}
      action={
        <Button size="small" onClick={() => setOpen(false)}>
          Ok
        </Button>
      }
    />
  );
};
export default EnvironmentMessage;
