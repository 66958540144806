import React from "react";

const TreeDataIcon = (): JSX.Element => {
  return (
    <svg
      style={{
        position: "absolute",
        marginLeft: "0px",
        marginTop: "-8px",
        width: "20px",
        height: "25px",
      }}
    >
      <path
        d="M 4 3 V 10 Q 2 20 10 20 H 20"
        fill="transparent"
        stroke="#000000"
        strokeWidth="2px"
      ></path>
    </svg>
  );
};

export default TreeDataIcon;
