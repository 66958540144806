import PropTypes from "prop-types";
import React from "react";
import {
  List,
  ListItemText,
  ListItem,
  Divider,
  Fade,
  Skeleton,
} from "@mui/material";

const SelectPurchaseOrder = ({
  data,
  loading,
  onClick,
  keys,
  numberOfLoadingLines,
  notFoundPlaceHolder,
}) => {
  return (
    <List>
      {loading ? (
        <>
          <Divider />
          {[...Array(data?.length || numberOfLoadingLines)].map((_x, i) => (
            <Fade in key={i + "fade"}>
              <ListItem
                style={{
                  height: 69,
                }}
                divider
              >
                <ListItemText
                  primary={
                    <Skeleton
                      width="40%"
                      height={10}
                      style={{ transform: "none", marginBottom: 10 }}
                    />
                  }
                  secondary={
                    <Skeleton
                      width="20%"
                      height={10}
                      style={{ transform: "none" }}
                    />
                  }
                />
              </ListItem>
            </Fade>
          ))}
        </>
      ) : (
        <>
          <Divider />
          {data?.length > 0 ? (
            data.map((option, i) => (
              <ListItem
                key={i + "select_purchase_order"}
                button
                onClick={() => onClick && onClick(option)}
                divider
              >
                <ListItemText
                  primary={`${option[keys.orderNumber]} - ${
                    option[keys.lineNumber]
                  }`}
                  secondary={`Quantity: ${option[keys.quantity]}`}
                />
              </ListItem>
            ))
          ) : notFoundPlaceHolder ? (
            notFoundPlaceHolder
          ) : (
            <ListItemText
              style={{ textAlign: "center", marginTop: 20 }}
              primary="No results found"
              secondary="Try searching for something else"
            />
          )}
        </>
      )}
    </List>
  );
};

SelectPurchaseOrder.propTypes = {
  data: PropTypes.array,
  keys: PropTypes.shape({
    itemNumber: PropTypes.string,
    lineNumber: PropTypes.string,
    orderNumber: PropTypes.string,
    quantity: PropTypes.string,
  }),
  loading: PropTypes.any,
  numberOfLoadingLines: PropTypes.number,
  onClick: PropTypes.func,
};

SelectPurchaseOrder.defaultProps = {
  numberOfLoadingLines: 10,
  keys: {
    orderNumber: "document_no",
    quantity: "outstanding_quantity",
    lineNumber: "line_no",
    itemNumber: "item_no",
  },
};

export default SelectPurchaseOrder;
