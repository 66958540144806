import { Box, Container, Fade } from "@mui/material";

const PageLayout = ({
  componentType,
  fullPage,
  children,
  disableGutters,
  maxWidth,
  ...props
}) => (
  <Fade in={true} timeout={500}>
    <Box marginTop={fullPage ? 0 : 3} {...props}>
      <Container
        disableGutters={fullPage}
        maxWidth={maxWidth ? maxWidth : false}
      >
        {children}
      </Container>
    </Box>
  </Fade>
);

export default PageLayout;
