export const useDateTimeMask = () => {
  switch (navigator.language) {
    case "nb":
    case "no":
    case "nb-NO":
    case "no-NO":
    case "nn":
    case "nn-NO":
      return { inputFormat: "DD.MM.YYYY HH:mm", mask: "__.__.____ __:__" };
    default:
      return { inputFormat: "MM/DD/YYYY HH:mm", mask: "__/__/____ __:__" };
  }
};

export const useDateMask = () => {
  switch (navigator.language) {
    case "nb":
    case "no":
    case "nb-NO":
    case "no-NO":
    case "nn":
    case "nn-NO":
      return { inputFormat: "DD.MM.YYYY", mask: "__.__.____" };
    default:
      return { inputFormat: "MM/DD/YYYY", mask: "__/__/____" };
  }
};
